import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonTelephone } from "@/src/common/components/button/ButtonTelephone";
import { getHoursKeyInfoList } from "@/src/common/utility/hours/HoursKeyInfo";
import { getHoursMapValueAtKey } from "@/src/common/utility/hours/HoursMap";
import { HoursValue } from "@/src/common/utility/hours/HoursValue";
import { getPhoneNumberFormatted } from "@/src/common/utility/phone/PhoneNumber";
import { getHourStringRangeAsLocaleString, getHourStringRangeIsValid } from "@/src/common/utility/time/HourString";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import styled from "styled-components";
const Root = styled.div `
	& {
		${Typography.ParagraphSmallRegularStyle};
		color: ${ColorBasic.Dark80};
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
`;
const RootBlock = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`;
const RootLabel = styled.div `
	& {
		${Typography.ParagraphSmallBoldStyle};
		color: ${ColorBasic.Night50};
	}
`;
const RootAddress = styled.div `
	& {
	}
`;
const RootWeekdayRow = styled.div `
	& {
		display: flex;
		flex-direction: row;
		gap: 1ch;
		justify-content: space-between;
		//max-width: 240px;
	}
`;
const RootWeekday = styled.div `
	& {
	}
`;
const RootHours = styled.div `
	& {
	}
`;
export const HoursOperation = (p) => {
    const settingHoursChat = getSettingDetailsByType(SettingType.GroupHoursChat, p.settings);
    const settingHoursOperation = getSettingDetailsByType(SettingType.GroupHoursOperation, p.settings);
    const settingInfo = getSettingDetailsByType(SettingType.GroupPharmacyInfo, p.settings);
    if (!settingHoursOperation) {
        return null;
    }
    return (_jsxs(Root, { children: [_jsxs(RootBlock, { children: [_jsx(RootLabel, { children: settingInfo.name }, void 0), settingInfo.address && _jsx(RootAddress, { children: settingInfo.address }, void 0), _jsxs("div", { children: ["Tel: ", _jsx(ButtonTelephone, {}, void 0)] }, void 0), _jsxs("div", { children: ["Fax: ", getPhoneNumberFormatted(settingInfo.fax)] }, void 0)] }, void 0), _jsxs(RootBlock, { children: [_jsx(RootLabel, { children: "Pharmacy Hours" }, void 0), getHoursKeyInfoList().map((info) => {
                        const hrOperation = getHoursMapValueAtKey(settingHoursOperation.hours, info.Key);
                        const hrChat = getHoursMapValueAtKey(settingHoursChat === null || settingHoursChat === void 0 ? void 0 : settingHoursChat.hours, info.Key);
                        return (_jsxs(RootWeekdayRow, { children: [_jsx(RootWeekday, { children: info.Name }, void 0), _jsx(RootHours, { children: renderHours(hrOperation, hrChat) }, void 0)] }, info.Key));
                    })] }, void 0)] }, void 0));
};
const renderHours = (hrOperation, hrChat) => {
    // TODO: reidenzon - Render full range...
    const hrOp = hrOperation === null || hrOperation === void 0 ? void 0 : hrOperation[0];
    const hrCh = hrChat === null || hrChat === void 0 ? void 0 : hrChat[0];
    if (hrOp == HoursValue.Closed) {
        return "Closed";
    }
    if (getHourStringRangeIsValid(hrOp)) {
        return getHourStringRangeAsLocaleString(hrOp);
    }
    if (getHourStringRangeIsValid(hrCh)) {
        return "Chat only";
    }
    return "--";
};
