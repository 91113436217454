import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import SVGIcon from "@/src/public/app/images/svg/registration-success-email.svg";
import { Nav } from "@/src/features/Nav/Nav";
import { Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { routes } from "@/src/routes";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
/* --------------------------------- Styles --------------------------------- */
const RegisterCompletePageContainer = styled.div ``;
const RegisterCompleteContentContainer = styled.div `
	margin-top: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const Title = styled(Typography.Heading3Regular) `
	font-family: Cabin;
	font-style: Medium;
	font-size: 30px;
	line-height: 38px;
	text-align: center;
	vertical-align: top;
	letter-spacing: 1%;
	color: ${ColorBasic.Night50};
	margin: 0px auto 40px;
	width: 330px;
`;
const SVGRegisterCompleteContainer = styled.div `
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
`;
const RegisterCompleteContent = styled.div `
	font-family: Cabin;
	font-style: Regular;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	vertical-align: top;
	letter-spacing: 1%;
	color: ${ColorBasic.Night40};
	width: 330px;
	margin: 0px auto;
`;
export const RegisterCompletePage = () => {
    const { query } = useQuery();
    const email = (query === null || query === void 0 ? void 0 : query.get("email")) || "";
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Login",
                    linkTo: routes.Login.toPath(),
                } }, "Nav"), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsx(RegisterCompletePageContainer, { children: _jsxs(RegisterCompleteContentContainer, { children: [_jsx(Title, { children: "Check your email" }, void 0), _jsx(SVGRegisterCompleteContainer, { children: _jsx(SVGIcon, {}, void 0) }, void 0), _jsx(RegisterCompleteContent, { children: `We've sent an activation link to ${email}.` }, void 0)] }, void 0) }, void 0) }, void 0) }, void 0)] }, void 0));
};
