import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable-next-line camelcase */
import { Formik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { t } from "@/src/features/Localization";
import { Form, Typography } from "@/src/features/common";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikSelect } from "@/src/features/utils/Formik/FormikSelect";
import { ColorBasic } from "@/src/enums/colors";
import { FormikDatePicker } from "@/src/features/common/FormikDatePicker";
const Title = styled(Typography.Heading4Regular) `
	color: ${ColorBasic.Night50};
	margin-bottom: 12px;
`;
export const PatientSelectAddForm = ({ userRelationshipsLabelOptions, isLoadingUserRelationshipViewList, groupListData, isLoadingGroupList, handleAddCustomerRelationee, }) => {
    const legalText = `As legal guardian, representative, or custodian of this patient, I hereby confirm that all necessary permissions have been granted to allow me access to this patient's health records and provide them with medical care. 

Furthermore, I certify that they will be present during any consultation on their behalf with an Authorized power.`;
    function handleSubmit(formValues) {
        handleAddCustomerRelationee(formValues);
    }
    const ProfileAddDependentFormSchema = Yup.object().shape({
        dependentFirstName: Yup.string()
            .min(1, t("common_nameInput_error_invalidMinLength"))
            .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
            .required(t("common_nameInput_error_invalidMinLength")),
        dependentLastName: Yup.string()
            .min(1, t("common_nameInput_error_invalidMinLength"))
            .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
            .required(t("common_nameInput_error_invalidMinLength")),
        dependentRelationshipSelect: Yup.string().min(1, t("common_singleSelectInput_error_empty")).required(t("common_singleSelectInput_error_empty")),
        dependentBirthDate: Yup.date().typeError(t("common_dateInput_error_invalidFormat")).required(t("common_dateInput_error_invalidFormat")),
        dependentProvince: Yup.string().min(1, t("common_provinceInput_error_empty")).required(t("common_provinceInput_error_empty")),
        registerPrivacyPolicyCheckbox: Yup.boolean().oneOf([true], "Required"),
    });
    const formikInitialValues = {
        dependentFirstName: "",
        dependentLastName: "",
        dependentRelationshipSelect: "",
        dependentBirthDate: null,
        dependentProvince: "",
        registerPrivacyPolicyCheckbox: false,
    };
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: ProfileAddDependentFormSchema,
        onSubmit: handleSubmit,
    };
    /* ------------------------------- Render Component ------------------------------- */
    return (_jsx(_Fragment, { children: _jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: "ProfileAddDependentForm", style: { display: "block", width: "100%" } }, { children: [_jsx(Title, { children: t("patientProfiles_addPatientForm_header") }, void 0), _jsx(FormikTextField, { fullWidth: true, formikId: "dependentFirstName", label: t("patientProfiles_addPatientForm_inputLabel_firstName") }, void 0), _jsx(FormikTextField, { fullWidth: true, formikId: "dependentLastName", label: t("patientProfiles_addPatientForm_inputLabel_lastName") }, void 0), _jsx(FormikSelect, { isLoading: isLoadingUserRelationshipViewList, formikId: "dependentRelationshipSelect", label: t("patientProfiles_addPatientForm_inputLabel_relationship"), onChange: (e) => {
                            return null;
                        }, options: userRelationshipsLabelOptions && (userRelationshipsLabelOptions === null || userRelationshipsLabelOptions === void 0 ? void 0 : userRelationshipsLabelOptions.length) > 0
                            ? userRelationshipsLabelOptions === null || userRelationshipsLabelOptions === void 0 ? void 0 : userRelationshipsLabelOptions.map((relationship) => ({
                                value: relationship.value,
                                label: relationship.caption,
                            }))
                            : [] }, void 0), _jsx(FormikDatePicker, { formikId: "dependentBirthDate", label: t("patientProfiles_addPatientForm_inputLabel_DateBirth") }, void 0), _jsx(FormikSelect, { isLoading: isLoadingGroupList, formikId: "dependentProvince", label: t("patientProfiles_addPatientForm_inputLabel_province"), onChange: () => {
                            return null;
                        }, options: groupListData && (groupListData === null || groupListData === void 0 ? void 0 : groupListData.length) > 0
                            ? groupListData === null || groupListData === void 0 ? void 0 : groupListData.map((item) => ({
                                value: item.guid,
                                label: item.name,
                            }))
                            : [] }, void 0), _jsx("div", Object.assign({ "data-cy": "privacy-policy-checkbox", style: { marginTop: "20px" } }, { children: _jsx(FormikCheckbox, { formikId: "registerPrivacyPolicyCheckbox", label: legalText, isSecondary: true }, void 0) }), void 0)] }), void 0)) }), void 0) }, void 0));
};
