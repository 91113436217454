var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonTelephone } from "@/src/common/components/button/ButtonTelephone";
import { ColorBasic } from "@/src/enums/colors";
import { StreamContext } from "@/src/features/Chat/StreamProvider";
import { Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { useAuth } from "@/src/hooks/useAuth";
import { ChatHoursBoxList } from "@/src/modules/workflow/layout/chat/ChatHoursBoxList";
import { TopicList } from "@/src/modules/workflow/layout/chat/TopicList";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useContext, useEffect } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 32px;
		padding-bottom: 48px;
	}
`;
const RootTitle = styled(Typography.Heading4Heavy) `
	color: ${ColorBasic.Aqua80};
	margin-top: 64px;
	text-align: center;
	white-space: pre;
`;
const RootFooter = styled(Typography.ParagraphBaseMedium) `
	color: ${ColorBasic.Dark50};
	white-space: pre-wrap;
	text-align: center;
`;
export const ChatterTopicList = (p) => {
    var _a;
    const segment = useSegmentAnalytics();
    const auth = useAuth();
    const sc = useContext(StreamContext);
    // TODO: reidenzon - Move deeper?!
    const handleTopic = (t) => __awaiter(void 0, void 0, void 0, function* () {
        yield sc.addChat({ topic: t });
    });
    useEffect(() => {
        segment.segmentPageView("chat_topics");
    }, []);
    return (_jsxs(Root, Object.assign({ className: "ChatterTopicList" }, { children: [_jsx(RootTitle, { children: t("chat_topics_header", { firstName: (_a = auth.user) === null || _a === void 0 ? void 0 : _a.details.nameFirst }) }, void 0), _jsx(TopicList, { onAdd: handleTopic }, void 0), _jsx(ChatHoursBoxList, {}, void 0), _jsxs(RootFooter, { children: ["Prefer the phone?", " ", _jsx(ButtonTelephone, {}, void 0)] }, void 0)] }), void 0));
};
