import { jsx as _jsx } from "react/jsx-runtime";
import { getButtonStyleInfo } from "@/src/common/components/button/ButtonStyle";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const Root = styled.a `
	& {
	}

	${(p) => p.$info.Css};
`;
export const ButtonNavigate = (p) => {
    const history = useHistory();
    const styleInfo = getButtonStyleInfo(p.style);
    const handleClick = (e) => {
        const isNewTab = e.ctrlKey || p.isNewTab;
        if (!isNewTab) {
            e.preventDefault();
            history.push(p.pathTo);
        }
    };
    return (_jsx(Root
    //
    , Object.assign({ "$info": styleInfo, href: p.pathTo, onClick: handleClick, target: p.isNewTab ? "_blank" : undefined }, { children: p.children }), void 0));
};
