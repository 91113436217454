import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import styled from "styled-components";
const Root = styled.div ``;
const RootLink = styled.a `
	width: 325px;
	height: 18px;
	border-radius: 3px;
	background-color: ${ColorBasic.Aqua10};
	display: flex;
	padding: 15px 12px;
	gap: 212px;
	text-decoration: none;
`;
const RootIcon = styled.div ``;
const RootNotes = styled.div `
	padding: 15px 12px;
`;
const RootHeader = styled(Typography.ParagraphSmallHeavy) `
	color: ${ColorBasic.Night50};
`;
const RootContent = styled(Typography.ParagraphSmallRegular) `
	color: ${ColorBasic.Dark60};
`;
export const WorkflowTrackingInfo = (p) => {
    const workflowDetails = p.workflow.details;
    const hasTrackingUrl = Boolean(workflowDetails.trackingURL);
    const hasTrackingNotes = Boolean(workflowDetails.trackingNotes);
    if (!hasTrackingUrl && !hasTrackingNotes) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs(Root, { children: [hasTrackingUrl && (_jsxs(RootLink, Object.assign({ href: workflowDetails === null || workflowDetails === void 0 ? void 0 : workflowDetails.trackingURL, target: "_blank", rel: "noopener" }, { children: [_jsx(RootHeader, { children: t("rxFillDetails_button_trackPackage") }, void 0), _jsx(RootIcon, { children: _jsx(Icons.ChevronRight, { size: 18, color: ColorBasic.Dark50 }, void 0) }, void 0)] }), void 0)), hasTrackingNotes && (_jsxs(RootNotes, { children: [_jsx(RootHeader, { children: t("rxFillDetails_subheader_trackingNotes") }, void 0), _jsx(RootContent, { children: workflowDetails === null || workflowDetails === void 0 ? void 0 : workflowDetails.trackingNotes }, void 0)] }, void 0))] }, void 0));
};
