import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { DrawerConstants } from "@/src/features/common/Drawer/drawerConstants";
import { ZIndex } from "@/src/enums";
import { ColorBasic } from "@/src/enums/colors";
/* --------------------------------- Styles --------------------------------- */
export const DrawerContentFooterTopContainer = styled.div `
	margin-top: auto;
	position: relative;
	background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : ColorBasic.Blanc00)};
`;
export const DrawerContentFooterFixed = styled.div `
	position: relative;
	width: ${DrawerConstants.DrawerWidthPx};
	height: ${({ $height }) => $height !== null && $height !== void 0 ? $height : ""};
	box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
	align-items: center;
	z-index: ${ZIndex.DrawerContentFooter};
`;
const DrawerContentFooterContent = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: ${({ $height }) => $height !== null && $height !== void 0 ? $height : ""};
	padding: 0 25px;
`;
/* -------------------------------- Component ------------------------------- */
/**
 * NOTE: Currently className is passed to the <DrawerContentFooterContent> component
 * because that's where most of the styling would need to occur. But note that that
 * height should not be styled without understanding that <DrawerContentFooterSpacer>
 * would need to get this height as well.
 */
export const DrawerContentFooter = ({ className, children, height = DrawerConstants.DrawerContentFooterHeightPx, backgroundColor, }) => {
    return (_jsx(DrawerContentFooterTopContainer, Object.assign({ backgroundColor: backgroundColor }, { children: _jsx(DrawerContentFooterFixed, Object.assign({ "$height": height }, { children: _jsx(DrawerContentFooterContent, Object.assign({ className: className, "$height": height }, { children: children }), void 0) }), void 0) }), void 0));
};
export default DrawerContentFooter;
