import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { ChevronDownRound } from "@/src/features/common/Icons";
import Collapse from "@mui/material/Collapse";
import styled from "styled-components";
const TITLE_MIN_HEIGHT = 24;
const TITLE_MIN_HEIGHT_PX = `${TITLE_MIN_HEIGHT}px`;
const TITLE_PADDING_LEFT_AND_RIGHT = "12px";
const TITLE_PADDING_TOP_AND_BOTTOM = "12px";
const Root = styled.div `
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${ColorBasic.Night10};

	&:last-child {
		border: none;
	}
`;
const RootHeader = styled.button `
	display: flex;
	flex-direction: row;
	cursor: pointer;
	align-items: center;
	position: relative;
	min-height: ${TITLE_MIN_HEIGHT_PX};
	text-align: left;
	padding: ${TITLE_PADDING_TOP_AND_BOTTOM} ${TITLE_PADDING_LEFT_AND_RIGHT};

	& svg {
		// This is necessary to make sure there is no extra space below the icon
		// caused by NOT being "display: block"
		display: block;
	}
`;
const RootTitle = styled(Typography.ParagraphSmallBold) `
	color: ${({ $color = ColorBasic.Night50 }) => $color};
	flex-grow: 1;
`;
const Answer = styled(Typography.ParagraphSmallMedium) `
	color: ${ColorBasic.Dark60};
	white-space: pre-wrap;
	padding: 0 ${TITLE_PADDING_LEFT_AND_RIGHT} ${TITLE_PADDING_TOP_AND_BOTTOM} ${TITLE_PADDING_LEFT_AND_RIGHT};
`;
const IconContainer = styled.div `
	transition: all 400ms ease-out;
	${({ $isExpanded }) => `transform: rotate(${$isExpanded ? 180 : 0}deg)`}
`;
export const FAQListItem = (p) => {
    const item = p.item;
    const isExpanded = p.selectedKey === item.key;
    const color = isExpanded ? ColorBasic.Aqua50 : ColorBasic.Night50;
    const ChevronDownRoundColor = isExpanded ? ColorBasic.Aqua50 : ColorBasic.Dark50;
    return (_jsxs(Root, { children: [_jsxs(RootHeader, Object.assign({ onClick: () => p.onItemSelected(item.key) }, { children: [_jsx(RootTitle, Object.assign({ "$color": color }, { children: item.question }), void 0), _jsx(IconContainer, Object.assign({ "$isExpanded": isExpanded }, { children: _jsx(ChevronDownRound, { color: ChevronDownRoundColor, size: TITLE_MIN_HEIGHT }, void 0) }), void 0)] }), void 0), _jsx(Collapse, Object.assign({ in: isExpanded }, { children: _jsx(Answer, { children: item.answer }, void 0) }), void 0)] }, void 0));
};
