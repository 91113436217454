import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Icons } from "@/src/features/common";
import { EmptyStatePageContentContainer } from "@/src/features/common/EmptyStatePageContentContainer";
import { useCreateThenRedirectToNewWorkflowRx } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowRx";
import { routes } from "@/src/routes";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	& {
		margin-top: 64px;
	}
`;
const RootImage = styled.div `
	& {
		display: flex;
		justify-content: center;
	}
`;
export const TaxReportEmpty = (p) => {
    const history = useHistory();
    const segment = useSegmentAnalytics();
    const newWorkflowRxFill = useCreateThenRedirectToNewWorkflowRx();
    const handleNewRx = () => {
        newWorkflowRxFill.createThenRedirectToNewWorkflowRx(p.patientGuid);
    };
    const handleRxAlternatives = () => {
        history.push(routes.PrescriptionAlternatives.toPath({
            patientGuid: p.patientGuid,
        }));
    };
    return (_jsx(Root, { children: _jsx(EmptyStatePageContentContainer
        //
        , { 
            //
            header: "A prescription hasn't been submitted for this patient yet.", image: _jsx(RootImage, { children: _jsx(Icons.PrescriptionNotSubmitted, { style: { height: "125px", width: "270px" } }, void 0) }, void 0), content: "Once you fill a prescription, your tax receipt report will appear here.", footer: _jsxs(_Fragment, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, onClick: handleNewRx, content: "Fill New Prescription" }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, onClick: handleRxAlternatives, content: "I don't have a prescription" }, void 0)] }, void 0) }, void 0) }, void 0));
};
