var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { translate } from "@/src/common/utility/translation/Translate";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { Nav } from "@/src/features/Nav/Nav";
import { useAuth } from "@/src/hooks/useAuth";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { useLogTermsMutation } from "@/src/modules/log/redux/ApiCoreDelivery";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router";
import styled from "styled-components";
import { bool, object } from "yup";
const Root = styled.div `
	//width: 315px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 24px;
`;
const RootList = styled.ol `
	& {
		margin: 0;
		padding: 0;
		padding-inline-start: 2ch;
		list-style-position: outside;
	}
`;
const RootListItem = styled.li `
	& {
	}
`;
export const LayoutVirtualCare = () => {
    const auth = useAuth();
    const user = auth.user;
    const history = useHistory();
    const { query } = useQuery();
    const [apiTerms, apiTermsState] = useLogTermsMutation();
    const formik = useFormik({
        initialValues: {
            isAgree: false,
        },
        validationSchema: object().shape({
            // TODO: reidenzon - Fix FormikCheckbox such that validationSchema actually works!
            isAgree: bool().required(() => "Required."),
        }),
        onSubmit: (s) => __awaiter(void 0, void 0, void 0, function* () {
            if (s.isAgree) {
                apiTerms({
                    isMednowPrivacyPolicy: true,
                    isVirtualCareTermsService: true,
                });
                history.push(routes.VirtualCareReasonList.toPath({}));
            }
        }),
    });
    return (_jsxs(PageContainer, { children: [_jsx(Nav
            //
            , { 
                //
                dataLeft: { leftItemType: "Back", linkTo: routes.ServiceList.toPath() }, dataRight: { rightItemType: "PhoneStartChat" } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsx(Root, { children: _jsxs(FormikProvider, Object.assign({ value: formik }, { children: [_jsx(LayoutContentHeader
                                //
                                , { 
                                    //
                                    title: "Virtual Care", description: _jsxs(_Fragment, { children: [_jsx("div", { children: "Welcome to Mednow Virtual Care. We know how important it is to have timely access to prescription refills and medical care; we have a team of Canadian health care providers available to you. You are able to complete your visit asynchronously at a time that works for you. This is how it works:" }, void 0), _jsxs(RootList, { children: [_jsx(RootListItem, { children: "Select the reason for your visit" }, void 0), _jsx(RootListItem, { children: "Complete a comprehensive visit form online" }, void 0), _jsx(RootListItem, { children: "The questions are reviewed by your healthcare provider" }, void 0), _jsx(RootListItem, { children: "Your health care provider may reach out via the app or phone if more information is needed" }, void 0), _jsx(RootListItem, { children: "Within 36 hours Mednow pharmacy will have your prescription*" }, void 0)] }, void 0), _jsx("div", { children: "Your health care provider may recommend a different treatment plan based on your individual history and needs; this may or may not include a prescription." }, void 0), _jsx("div", { children: "Cost $35/visit (waived for Dexcom visits and reimbursed if we are not able to fulfill your request)." }, void 0)] }, void 0) }, void 0), _jsx(FormikCheckbox, { formikId: "isAgree", label: translate("By clicking the checkbox, you agree to {terms} and {privacy}", {
                                        terms: (_jsx(ButtonNavigate
                                        //
                                        , Object.assign({ 
                                            //
                                            isNewTab: true, pathTo: "https://mednow.ca/pages/virtualcare-terms-of-service", style: ButtonStyle.TextAnchor }, { children: "Virtual Care Terms of Service" }), void 0)),
                                        privacy: (_jsx(ButtonNavigate
                                        //
                                        , Object.assign({ 
                                            //
                                            isNewTab: true, pathTo: "https://mednow.ca/pages/privacy-policy", style: ButtonStyle.TextAnchor }, { children: "Privacy Policy" }), void 0)),
                                    }) }, void 0), _jsx("div", { children: _jsx(ButtonStandard
                                    //
                                    , { 
                                        //
                                        isDisabled: !formik.getFieldProps("isAgree").value, content: "Proceed", onClick: () => formik.handleSubmit(), style: ButtonStyle.SolidPrimary }, void 0) }, void 0)] }), void 0) }, void 0) }, void 0) }, void 0)] }, void 0));
};
