import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import styled from "styled-components";
const Root = styled.div `
	width: ${({ $isWide }) => ($isWide ? "339px" : "168px")};
	height: 56px;
	margin-top: 15px;
`;
const RootButton = styled.button `
	background: ${({ $primary }) => ($primary ? ColorBasic.Aqua60 : ColorBasic.Blanc00)};
	color: ${({ $primary }) => ($primary ? ColorBasic.Blanc00 : ColorBasic.Aqua60)};
	position: relative;
	display: flex;
	padding: 14px 18px;
	border: ${({ $primary }) => ($primary ? "0px" : "2px solid #1A7C94")};
	border-radius: 32px;
	overflow: hidden;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;
const CTAButtonText = styled(Typography.ButtonLarge) ``;
const CTAButtonContentContainer = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
`;
const IconContainer = styled.div `
	margin-top: 2px;
`;
const LoadingSpinnerContainer = styled.div `
	position: absolute;
	left: 40%;
	top: 12.5%;
`;
export const CustomCTAButton = (p) => {
    var _a;
    const segment = useSegmentAnalytics();
    const handleClick = () => {
        var _a;
        if (p.analyticsTrackEvent) {
            segment.segmentTrackEvent(p.analyticsTrackEvent.event, p.analyticsTrackEvent);
        }
        (_a = p.onClick) === null || _a === void 0 ? void 0 : _a.call(p);
    };
    let content;
    if (p.isLoading) {
        content = (_jsx(LoadingSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0));
    }
    else if (p.icon) {
        content = (_jsxs(CTAButtonContentContainer, { children: [_jsx(CTAButtonText, { children: p.text }, void 0), _jsx(IconContainer, { children: p.icon }, void 0)] }, void 0));
    }
    else {
        content = _jsx(CTAButtonText, { children: p.text }, void 0);
    }
    return (_jsx(Root, Object.assign({ "$isWide": p.isWide, className: p.className }, { children: _jsx(RootButton, Object.assign({ onClick: handleClick, "$primary": p.isPrimary, type: (_a = p.type) !== null && _a !== void 0 ? _a : "submit", form: p.form }, { children: content }), void 0) }), void 0));
};
