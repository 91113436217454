import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@/src/features/common";
import { TimelineStepState } from "@/src/modules/workflow/layout/workflow/progress/timeline/TimelineStepState";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 8px;
		margin-top: 4px;
		margin-bottom: 32px;
	}
`;
const RootHeader = styled.header `
	& {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
`;
const RootTitle = styled(Typography.ParagraphBaseHeavy) `
	& {
		color: ${(p) => p.stateInfo.ColorContentTitle};
	}
`;
const RootDescription = styled(Typography.ParagraphSmallRegular) `
	& {
		color: ${(p) => p.stateInfo.ColorContentDescription};
	}
`;
const RootContent = styled.div `
	& {
	}

	&:empty {
		display: none;
	}
`;
export const TimelineStepContent = (p) => {
    const stateInfo = p.state;
    const step = p.step;
    return (_jsxs(Root, { children: [_jsxs(RootHeader, { children: [_jsx(RootTitle, Object.assign({ stateInfo: stateInfo }, { children: step.stepTitle }), void 0), step.stepDescription && _jsx(RootDescription, Object.assign({ stateInfo: stateInfo }, { children: step.stepDescription }), void 0)] }, void 0), stateInfo.State != TimelineStepState.Complete && step.stepContent && _jsx(RootContent, { children: step.stepContent() }, void 0)] }, void 0));
};
