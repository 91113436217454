import { jsxs as _jsxs } from "react/jsx-runtime";
import { getButtonStyleInfo } from "@/src/common/components/button/ButtonStyle";
import styled from "styled-components";
const Root = styled.button `
	& {
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		gap: 1ch;
	}

	&.disabled {
		cursor: inherit;
		opacity: 0.5;
	}

	${(p) => p.$info.Css};
`;
export const ButtonStandard = (p) => {
    const styleInfo = getButtonStyleInfo(p.style);
    const isLoading = Boolean(p.isLoading);
    const isDisabled = Boolean(p.isDisabled) || isLoading;
    return (_jsxs(Root
    //
    , Object.assign({ 
        //
        className: `${isDisabled && "disabled"} ${isLoading && "loading"}`, "$info": styleInfo, onClick: p.onClick, type: "button" }, { children: [p.contentLeft, p.content, p.contentRight] }), void 0));
};
