var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from "react";
import { parse } from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { usePostWorkflowAddMutation, useWorkflowEditMutation } from "@/src/redux/apiServices/suiteApi";
import { useAuth } from "@/src/hooks/useAuth";
import { useCustomHistory } from "@/src/hooks/useCustomHistory";
import { routes } from "@/src/routes";
export const useCreateThenRedirectToNewWorkflow = (p) => {
    const auth = useAuth();
    const { search } = useLocation();
    const history = useHistory();
    const { historyReplace, historyPush } = useCustomHistory();
    const [workflowAdd, workflowAddState] = usePostWorkflowAddMutation();
    const [workflowEdit, workflowEditState] = useWorkflowEditMutation();
    const user = auth.user;
    const userGuid = user === null || user === void 0 ? void 0 : user.guid;
    const handleCreateNewWorkflow = useCallback((userFor = userGuid) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const query = parse(search);
        const isReplaceHistory = p.isReplaceBrowserHistory;
        // console.log(p.workflowType);
        const responseAdd = (yield workflowAdd({
            // All new workflows should now be created using the v2 workflow type
            // type: WorkflowType.RxFillDelivery,
            // type: WorkflowType.TransferV1,
            type: p.workflowType,
            // If no user is passed in, create workflow for the logged in user
            userFor,
        }).unwrap());
        const workflowGuid = (_b = (_a = responseAdd === null || responseAdd === void 0 ? void 0 : responseAdd.data) === null || _a === void 0 ? void 0 : _a.workflow) === null || _b === void 0 ? void 0 : _b.guid;
        const workflowStatus = (_d = (_c = responseAdd === null || responseAdd === void 0 ? void 0 : responseAdd.data) === null || _c === void 0 ? void 0 : _c.workflow) === null || _d === void 0 ? void 0 : _d.status;
        if (p.workflowDetails) {
            const responseEdit = (yield workflowEdit({
                guid: workflowGuid,
                status: workflowStatus,
                details: p.workflowDetails,
            }).unwrap());
        }
        const url = routes.WorkflowView.toPath({
            workflowGuid: workflowGuid,
            rxNumberList: p.rxNumberList,
        });
        if (isReplaceHistory) {
            historyReplace(url);
        }
        else {
            historyPush(url);
        }
    }), [userGuid]);
    return {
        createThenRedirectToNewWorkflow: handleCreateNewWorkflow,
        isLoading: workflowAddState.isLoading || workflowEditState.isLoading,
    };
};
