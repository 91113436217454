import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Button, Icons } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
const Root = styled(Button) `
	& {
		background: ${ColorBasic.Aqua60};
		color: ${ColorBasic.Blanc00};
		position: relative;

		display: block;

		border-radius: 32px;
		overflow: hidden;

		margin-top: 24px;

		width: 100%;
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		justify-content: center;
		gap: 8px;
	}
`;
const RootText = styled.div `
	& {
		font-family: Cabin;
		font-weight: 400;
		font-style: normal;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		letter-spacing: 1%;
	}
`;
const RootIcon = styled.div `
	& {
		display: flex;
		align-items: flex-end;
	}
`;
export const ButtonRegister = (p) => {
    return (_jsx(Root, Object.assign({ disabled: p.disabled, type: "submit" }, { children: _jsxs(RootContent, { children: [_jsx(RootText, { children: p.text }, void 0), _jsx(RootIcon, { children: _jsx(Icons.ArrowForward, { color: ColorBasic.Blanc00 }, void 0) }, void 0)] }, void 0) }), void 0));
};
