import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Icons } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { UserColor } from "@/src/modules/user/type/UserColor";
/**
 * @deprecated TODO: reidenzon - Use modules/user/type instead.
 */
const colorMap = {
    [UserColor.Blue]: {
        outterRingColor: `${ColorBasic.Aqua20}`,
        innerRingColor: `${ColorBasic.Aqua40}`,
        faceColor: `${ColorBasic.Aqua60}`,
    },
    [UserColor.Red]: {
        outterRingColor: `${ColorBasic.Roseo10}`,
        innerRingColor: `${ColorBasic.Roseo20}`,
        faceColor: `${ColorBasic.Roseo60}`,
    },
    [UserColor.Purple]: {
        outterRingColor: `${ColorBasic.Navy10}`,
        innerRingColor: `${ColorBasic.Navy30}`,
        faceColor: `${ColorBasic.Navy70}`,
    },
    [UserColor.Yellow]: {
        outterRingColor: `${ColorBasic.Corn10}`,
        innerRingColor: `${ColorBasic.Corn40}`,
        faceColor: `${ColorBasic.Caramel40}`,
    },
    [UserColor.Orange]: {
        outterRingColor: `${ColorBasic.Carrot10}`,
        innerRingColor: `${ColorBasic.Carrot30}`,
        faceColor: `${ColorBasic.Carrot80}`,
    },
    [UserColor.Grey]: {
        outterRingColor: `${ColorBasic.Dark20}`,
        innerRingColor: `${ColorBasic.Dark30}`,
        faceColor: `${ColorBasic.Dark50}`,
    },
};
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	& .avatar-patient_svg__outter-ring {
		fill: ${({ outterRingColor }) => outterRingColor};
	}

	& .avatar-patient_svg__inner-ring {
		fill: ${({ innerRingColor }) => innerRingColor};
	}

	& .avatar-patient_svg__face {
		fill: ${({ faceColor }) => faceColor};
	}
`;
function getIconColorsByFaceColor(color) {
    return colorMap[color || UserColor.Grey];
}
export const PatientAvatar = (p) => {
    const { outterRingColor, innerRingColor, faceColor } = getIconColorsByFaceColor(p.color);
    return (_jsx(Root, Object.assign({ outterRingColor: outterRingColor, innerRingColor: innerRingColor, faceColor: faceColor }, { children: _jsx(Icons.AvatarPatientProfile, { size: p.size || 42 }, void 0) }), void 0));
};
