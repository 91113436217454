import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Circle } from "@mui/icons-material";
import { ColorBasic } from "@/src/enums/colors";
const Root = styled.div `
	& {
		align-items: center;
		cursor: pointer;
		display: flex;
		justify-content: center;
		height: 24px;
		width: 18px;
	}
	& svg {
		display: block;
		font-size: 12px;
	}
`;
export const DotStep = (p) => {
    return (_jsxs(Root, Object.assign({ onClick: p.onClick }, { children: [!p.isActive && _jsx(Circle, { htmlColor: ColorBasic.Dark30 }, void 0), p.isActive && _jsx(Circle, { htmlColor: ColorBasic.Aqua50 }, void 0)] }), void 0));
};
