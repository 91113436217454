import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridRow } from "@/src/common/components/grid/GridRow";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { getUserRxResponseDispenseQuantity } from "@/src/modules/user/redux/contracts/UserRxViewList";
import { RxCardCount } from "@/src/modules/workflow/type/rx-refill-v3/components/draft/rx-list/RxCardCount";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex: 1;
		flex-direction: row;
		gap: 12px;
		position: relative;
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 4px;
	}

	${Root}.disabled & {
		opacity: 0.5;
	}
`;
const RootNumber = styled(Typography.ParagraphSmallMedium) `
	& {
		color: ${ColorBasic.Night40};
		display: inline;
	}
`;
const RootName = styled(Typography.ParagraphBaseRegular) `
	& {
		color: ${ColorBasic.Night50};
		display: inline;
	}
`;
const RootQuantity = styled(Typography.ParagraphSmallRegular) `
	& {
		color: ${ColorBasic.Night30};
		display: inline;
	}
`;
export const RxCard = (p) => {
    const rx = p.rx;
    const isDisabled = !rx.isRefill;
    const dispenseQuantity = getUserRxResponseDispenseQuantity(rx);
    return (_jsxs(Root, Object.assign({ className: isDisabled ? "disabled" : "" }, { children: [_jsxs(RootContent, { children: [_jsxs(GridRow, { children: [_jsx(RootNumber, { children: `Rx. ${rx.rxNumber}` }, void 0), _jsx(RootQuantity, { children: dispenseQuantity }, void 0)] }, void 0), _jsx(RootName, { children: rx.namePrimary }, void 0)] }, void 0), _jsx("div", { children: _jsx(RxCardCount, { rx: rx }, void 0) }, void 0)] }), void 0));
};
