var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button as MuiButton } from "@mui/material";
import styled from "styled-components";
import * as Typography from "@/src/features/common/Typography";
import { Colors, ColorBasic } from "@/src/enums/colors";
import { Link } from "react-router-dom";
const valueMap = {
    solid: {
        primary: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionPrimaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionPrimaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionPrimaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionPrimaryDisabled,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionPrimaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionPrimaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionPrimaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionPrimaryDisabled,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionPrimaryDefault,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionPrimaryHover,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionPrimaryActive,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionPrimaryDisabled,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
        },
        secondary: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionSecondaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionSecondaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionSecondaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionSecondaryDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionSecondaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionSecondaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionSecondaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionSecondaryDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionSecondaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionSecondaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionSecondaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionSecondaryDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
        },
        neutral: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionNeutralDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionNeutralHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionNeutralActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionNeutralDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionNeutralDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionNeutralHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionNeutralActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionNeutralDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionNeutralDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionNeutralHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionNeutralActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionNeutralDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
        },
    },
    round: {
        primary: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionPrimaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionPrimaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionPrimaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionPrimaryDisabled,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionPrimaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionPrimaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionPrimaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionPrimaryDisabled,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionPrimaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionPrimaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionPrimaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionPrimaryDisabled,
                        typographyColor: ColorBasic.Aqua60,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
        },
        secondary: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionSecondaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionSecondaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionSecondaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionSecondaryDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionSecondaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionSecondaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionSecondaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionSecondaryDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionSecondaryDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionSecondaryHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionSecondaryActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionSecondaryDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
        },
        neutral: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionNeutralDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionNeutralHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionNeutralActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionNeutralDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionNeutralDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionNeutralHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionNeutralActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionNeutralDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: Colors.ActionNeutralDefault,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    hover: {
                        backgroundColor: Colors.ActionNeutralHover,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    active: {
                        backgroundColor: Colors.ActionNeutralActive,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                    disabled: {
                        backgroundColor: Colors.ActionNeutralDisabled,
                        typographyColor: ColorBasic.Blanc00,
                        borderColor: ColorBasic.Blanc00,
                    },
                },
            },
        },
    },
    borderless: {
        primary: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "4px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryHover,
                        borderColor: Colors.ActionPrimaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryActive,
                        borderColor: Colors.ActionPrimaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "6px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryHover,
                        borderColor: Colors.ActionPrimaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryActive,
                        borderColor: Colors.ActionPrimaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "8px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryActive,
                        borderColor: Colors.ActionPrimaryActive,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryActive,
                        borderColor: Colors.ActionPrimaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
        },
        secondary: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "4px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryHover,
                        borderColor: Colors.ActionSecondaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryActive,
                        borderColor: Colors.ActionSecondaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "6px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryHover,
                        borderColor: Colors.ActionSecondaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryActive,
                        borderColor: Colors.ActionSecondaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "8px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryHover,
                        borderColor: Colors.ActionSecondaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryActive,
                        borderColor: Colors.ActionSecondaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
        },
        neutral: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "4px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralHover,
                        borderColor: Colors.ActionNeutralHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralActive,
                        borderColor: Colors.ActionNeutralActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "6px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralHover,
                        borderColor: Colors.ActionNeutralHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralActive,
                        borderColor: Colors.ActionNeutralActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "8px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDefault,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralHover,
                        borderColor: Colors.ActionNeutralHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralActive,
                        borderColor: Colors.ActionNeutralActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDisabled,
                        borderColor: ColorBasic.NeutralTransparent,
                    },
                },
            },
        },
    },
    ghostRounded: {
        primary: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDefault,
                        borderColor: Colors.ActionPrimaryDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryHover,
                        borderColor: Colors.ActionPrimaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryActive,
                        borderColor: Colors.ActionPrimaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDisabled,
                        borderColor: Colors.ActionPrimaryDisabled,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDefault,
                        borderColor: Colors.ActionPrimaryDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryHover,
                        borderColor: Colors.ActionPrimaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryActive,
                        borderColor: Colors.ActionPrimaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDisabled,
                        borderColor: Colors.ActionPrimaryDisabled,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDefault,
                        borderColor: Colors.ActionPrimaryDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryHover,
                        borderColor: Colors.ActionPrimaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryActive,
                        borderColor: Colors.ActionPrimaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionPrimaryDisabled,
                        borderColor: Colors.ActionPrimaryDisabled,
                    },
                },
            },
        },
        secondary: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDefault,
                        borderColor: Colors.ActionSecondaryDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryHover,
                        borderColor: Colors.ActionSecondaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryActive,
                        borderColor: Colors.ActionSecondaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDisabled,
                        borderColor: Colors.ActionSecondaryDisabled,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDefault,
                        borderColor: Colors.ActionSecondaryDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryHover,
                        borderColor: Colors.ActionSecondaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryActive,
                        borderColor: Colors.ActionSecondaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDisabled,
                        borderColor: Colors.ActionSecondaryDisabled,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDefault,
                        borderColor: Colors.ActionSecondaryDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryHover,
                        borderColor: Colors.ActionSecondaryHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryActive,
                        borderColor: Colors.ActionSecondaryActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionSecondaryDisabled,
                        borderColor: Colors.ActionSecondaryDisabled,
                    },
                },
            },
        },
        neutral: {
            small: {
                typographyStyle: Typography.ButtonSmallStyle,
                padding: "8px 20px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDefault,
                        borderColor: Colors.ActionNeutralDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralHover,
                        borderColor: Colors.ActionNeutralHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralActive,
                        borderColor: Colors.ActionNeutralActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDisabled,
                        borderColor: Colors.ActionNeutralDisabled,
                    },
                },
            },
            medium: {
                typographyStyle: Typography.ButtonMediumStyle,
                padding: "12px 16px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDefault,
                        borderColor: Colors.ActionNeutralDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralHover,
                        borderColor: Colors.ActionNeutralHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralActive,
                        borderColor: Colors.ActionNeutralActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDisabled,
                        borderColor: Colors.ActionNeutralDisabled,
                    },
                },
            },
            large: {
                typographyStyle: Typography.ButtonLargeStyle,
                padding: "14px 18px",
                borderRadius: "32px",
                states: {
                    default: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDefault,
                        borderColor: Colors.ActionNeutralDefault,
                    },
                    hover: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralHover,
                        borderColor: Colors.ActionNeutralHover,
                    },
                    active: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralActive,
                        borderColor: Colors.ActionNeutralActive,
                    },
                    disabled: {
                        backgroundColor: ColorBasic.NeutralTransparent,
                        typographyColor: Colors.ActionNeutralDisabled,
                        borderColor: Colors.ActionNeutralDisabled,
                    },
                },
            },
        },
    },
};
/* --------------------------------- Helpers --------------------------------- */
const getRootStyles = ({ $buttonVariant, $buttonColor, $buttonSize, $underlined }) => {
    const padding = valueMap[$buttonVariant][$buttonColor][$buttonSize].padding;
    const borderRadius = valueMap[$buttonVariant][$buttonColor][$buttonSize].borderRadius;
    const typographyStyle = valueMap[$buttonVariant][$buttonColor][$buttonSize].typographyStyle;
    const typographyColor = valueMap[$buttonVariant][$buttonColor][$buttonSize]["states"]["default"].typographyColor;
    const backgroundColor = valueMap[$buttonVariant][$buttonColor][$buttonSize]["states"]["default"].backgroundColor;
    const borderColor = valueMap[$buttonVariant][$buttonColor][$buttonSize]["states"]["default"].borderColor;
    return `
		text-transform: none;
		text-decoration: ${$underlined ? "underline" : "none"};
		padding: ${padding};
		border-radius: ${borderRadius};
		${typographyStyle} 
		color: ${typographyColor}; 
		background-color: ${backgroundColor};
		border: 2px solid ${borderColor};
		`;
};
const getStateStyles = ({ $buttonVariant, $buttonColor, $buttonSize, $underlined }, state) => {
    const typographyColor = valueMap[$buttonVariant][$buttonColor][$buttonSize]["states"][state].typographyColor;
    const backgroundColor = valueMap[$buttonVariant][$buttonColor][$buttonSize]["states"][state].backgroundColor;
    const borderColor = valueMap[$buttonVariant][$buttonColor][$buttonSize]["states"][state].borderColor;
    return `
			text-decoration: ${$underlined ? "underline" : "none"};
			color: ${typographyColor}; 
			background-color: ${backgroundColor};
			border: 2px solid ${borderColor};
			`;
};
/* --------------------------------- Styles --------------------------------- */
const StyledButton = styled(MuiButton) `
	&& {
		${getRootStyles}
	}

	&&:hover {
		${(props) => getStateStyles(Object.assign({}, props), "hover")}
	}

	&&:active {
		${(props) => getStateStyles(Object.assign({}, props), "active")}
	}

	&&:disabled {
		${(props) => getStateStyles(Object.assign({}, props), "disabled")}
	}
`;
export const Button = (_a) => {
    var { color = "primary", variant = "solid", size = "medium", underlined = false } = _a, rest = __rest(_a, ["color", "variant", "size", "underlined"]);
    return _jsx(StyledButton, Object.assign({}, rest, { "$buttonColor": color, "$buttonVariant": variant, "$buttonSize": size, "$underlined": underlined }), void 0);
};
export const ButtonLink = (_a) => {
    var { color = "primary", variant = "solid", size = "medium", underlined = false } = _a, rest = __rest(_a, ["color", "variant", "size", "underlined"]);
    return (_jsx(StyledButton, Object.assign({}, rest, { 
        // We are ignoring ts error for component prop, because it does in fact work.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: Link, "$buttonColor": color, "$buttonVariant": variant, "$buttonSize": size, "$underlined": underlined }), void 0));
};
export default Button;
