import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { NavMenuButton } from "@/src/features/Nav/NavLeftButtons/NavMenuButton";
import { useHistory } from "react-router";
import styled from "styled-components";
const LeftItemContainer = styled.button `
	display: flex;
	align-items: center;
	margin: auto 12px;
`;
const LeftItemHref = styled.a `
	display: flex;
	align-items: center;
	margin: auto 12px;
`;
const LeftNavButtonText = styled(Typography.ParagraphBaseMedium) `
	&& * {
		text-decoration: none;
	}

	color: ${ColorBasic.Blanc00};
`;
/**
 * `getLeftItem` will either take onClick or linkTo, but never both.
 * The NavLeftItemProps type will ensure this is the case.
 */
function getLeftItem(leftItemType) {
    switch (leftItemType) {
        case "Back":
            return (_jsxs(_Fragment, { children: [_jsx(Icons.Cancel, { color: ColorBasic.Blanc00 }, void 0), _jsx(LeftNavButtonText, { children: t("header_back") }, void 0)] }, void 0));
        case "Cancel":
            return (_jsxs(_Fragment, { children: [_jsx(Icons.Cancel, { color: ColorBasic.Blanc00 }, void 0), _jsx(LeftNavButtonText, { children: t("header_cancel") }, void 0)] }, void 0));
        case "Menu":
            return _jsx(NavMenuButton, {}, void 0);
        case "Login":
            return (_jsx(_Fragment, { children: _jsx(LeftNavButtonText, { children: t("header_login") }, void 0) }, void 0));
        default:
            return (_jsxs(_Fragment, { children: [_jsx(Icons.Cancel, { color: ColorBasic.Blanc00 }, void 0), _jsx(LeftNavButtonText, { children: t("header_home") }, void 0)] }, void 0));
    }
}
export const NavLeftItem = ({ onClick, dataCy, leftItemType, linkTo, href }) => {
    const history = useHistory();
    function onClickHandler() {
        const route = linkTo ? linkTo : "/";
        history.push(route);
    }
    if (href) {
        return (_jsx(LeftItemHref, Object.assign({ "data-cy": dataCy, href: href }, { children: getLeftItem(leftItemType) }), void 0));
    }
    return (_jsx(LeftItemContainer, Object.assign({ "data-cy": dataCy, onClick: onClick ? onClick : onClickHandler, type: "button" }, { children: getLeftItem(leftItemType) }), void 0));
};
