import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { OrderCard } from "@/src/features/Workflows/Orders/OrderCard";
const WorkflowCardsContainer = styled.div `
	margin-top: 12px;
	display: flex;
	flex-direction: column;
`;
export const OrderCards = ({ workflows, isPendingWorkflow, handleOnCardClick }) => {
    return (_jsx(WorkflowCardsContainer, { children: workflows.map((workflow) => {
            return _jsx(OrderCard, { workflow: workflow, isPendingWorkflow: isPendingWorkflow, handleOnCardClick: handleOnCardClick }, workflow.guid);
        }) }, void 0));
};
