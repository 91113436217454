import { jsx as _jsx } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.section `
	& {
		${Typography.ParagraphSmallRegularStyle};

		border: 1px solid ${ColorBasic.Night10};
		color: ${ColorBasic.Dark60};
		padding: 12px;
		white-space: pre-wrap;
	}

	&:first-child {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	& + & {
		border-top: none;
	}

	&:last-child {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
`;
export const SummaryItemSimple = (p) => {
    return _jsx(Root, Object.assign({ className: "SummaryItemSimple" }, { children: p.children }), void 0);
};
