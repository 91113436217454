import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { Drawer, DrawerAnchorEnum, DrawerContent } from "@/src/features/common/Drawer";
import { NavDrawerContent } from "@/src/features/Nav/NavDrawer/NavDrawerContent";
import { NavDrawerHeader } from "@/src/features/Nav/NavDrawer/NavDrawerHeader";
import { VersionAndConditionalBuildId } from "@/src/features/VersionNumber/VersionAndConditionalBuildId";
import { t } from "@/src/features/Localization";
const DrawerContentStyled = styled(DrawerContent) ``;
const NavDrawerFooter = styled(Typography.ParagraphXSmallRegular) `
	display: block;
	padding: 32px;

	&,
	& * {
		color: white !important;
	}
`;
export const NavDrawer = ({ isNavDrawerOpen, handleNavDrawerClose }) => {
    return (_jsx(Drawer, Object.assign({ anchor: DrawerAnchorEnum.Left, open: isNavDrawerOpen, onClose: handleNavDrawerClose, PaperProps: {
            sx: {
                backgroundColor: ColorBasic.Night50,
            },
        } }, { children: _jsx(DrawerContentStyled, Object.assign({ drawerHeader: _jsx(NavDrawerHeader, { handleNavDrawerClose: handleNavDrawerClose }, void 0), drawerFooter: _jsx(NavDrawerFooter, { children: t("main_drawer_version", {
                    version: _jsx(VersionAndConditionalBuildId, {}, void 0),
                }) }, void 0), useMaxHeight: true, isCenterItems: true }, { children: _jsx(NavDrawerContent, {}, void 0) }), void 0) }), void 0));
};
