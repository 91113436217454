import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { useAuth } from "@/src/hooks/useAuth";
import { GroupType } from "@/src/modules/group/type/GroupType";
import styled from "styled-components";
const DrawerHeaderContainer = styled.div `
	display: flex;
	height: 108px;
`;
const DrawerHeaderContent = styled.div `
	display: flex;
	width: 319px;
	background-color: ${ColorBasic.Blanc00};
	border-radius: 0px 0px 53.5px 0px;
`;
const DrawerHeaderContentLogo = styled.div `
	margin-left: 32px;
	margin-top: 35px;
	margin-bottom: 35px;
`;
const DrawerHeaderContentTextContainer = styled.div `
	margin-left: 17px;
	margin-top: 35px;
	margin-bottom: 35px;
`;
const DrawerHeaderContentDrawerHeaderContentTextName = styled(Typography.ParagraphBaseHeavy) `
	color: ${ColorBasic.Aqua90};
`;
const DrawerHeaderContentDrawerHeaderContentTextSupport = styled.div `
	font-family: Cabin;
	font-weight: 500;
	font-style: normal;
	font-size: 12px;
	line-height: 15px;
	color: ${ColorBasic.Night30};
`;
const DrawerHeaderCancel = styled.button `
	margin: 28px 20px;
`;
export const NavDrawerHeader = ({ handleNavDrawerClose }) => {
    var _a, _b, _c;
    const { user } = useAuth();
    const firstName = (_a = user === null || user === void 0 ? void 0 : user.details) === null || _a === void 0 ? void 0 : _a.nameFirst;
    const groupRoles = user === null || user === void 0 ? void 0 : user.groupRoles;
    const userGroupProvinces = groupRoles === null || groupRoles === void 0 ? void 0 : groupRoles.reduce((acc, cv) => {
        const groupAncestors = cv.groupAncestors;
        const groupProvinces = groupAncestors.filter((groupAncestor) => groupAncestor.type === GroupType.Province);
        return [...acc, ...groupProvinces];
    }, []);
    const provinceName = (_c = (_b = userGroupProvinces === null || userGroupProvinces === void 0 ? void 0 : userGroupProvinces[0]) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.name;
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsxs(DrawerHeaderContainer, { children: [_jsxs(DrawerHeaderContent, { children: [_jsx(DrawerHeaderContentLogo, { children: _jsx(Icons.MednowLogo, { size: 32 }, void 0) }, void 0), _jsxs(DrawerHeaderContentTextContainer, { children: [_jsx(DrawerHeaderContentDrawerHeaderContentTextName, { children: firstName }, void 0), _jsx(DrawerHeaderContentDrawerHeaderContentTextSupport, { children: provinceName }, void 0)] }, void 0)] }, void 0), _jsx("div", { children: _jsx(DrawerHeaderCancel, Object.assign({ onClick: handleNavDrawerClose }, { children: _jsx(Icons.CancelProfile, { size: 16, color: ColorBasic.Blanc00 }, void 0) }), void 0) }, void 0)] }, void 0));
};
