var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
export const TextButtonStyled = styled.button `
	background: transparent;
	border: 0;
	cursor: pointer;

	text-decoration: ${({ $isUnderlined }) => ($isUnderlined ? "underline" : "")};
	color: ${({ $color }) => $color !== null && $color !== void 0 ? $color : ""};

	&:hover {
		color: ${({ $colorHover }) => $colorHover !== null && $colorHover !== void 0 ? $colorHover : ""};
	}
`;
/* ---------------------------------- Enums --------------------------------- */
/**
 * To make things consistent with material ui, the values of these enums
 * are lowercase, not first letter capitalized. See:
 * 	https://mui.com/components/buttons/#sizes
 */
export var TextButtonSize;
(function (TextButtonSize) {
    TextButtonSize["Large"] = "large";
    TextButtonSize["Medium"] = "medium";
    TextButtonSize["Small"] = "small";
})(TextButtonSize || (TextButtonSize = {}));
export const TextButton = (_a) => {
    var { isUnderlined, color, colorHover, size, type = "button", children } = _a, rest = __rest(_a, ["isUnderlined", "color", "colorHover", "size", "type", "children"]);
    // This Typography should probably be Paragraph_____(something)
    // Since it is text and not a button, but will wait to update this in a need to use basis
    let TextComponent = Typography.ButtonMedium;
    if (size === TextButtonSize.Large) {
        // This Typography should probably be Paragraph_____(something)
        // Since it is text and not a button, but will wait to update this in a need to use basis
        TextComponent = Typography.ButtonLarge;
    }
    else if (size === TextButtonSize.Small) {
        // Updated this to be ParagraphSmallRegular since it is text.
        TextComponent = Typography.ParagraphSmallRegular;
    }
    return (_jsx(TextButtonStyled, Object.assign({ "$isUnderlined": isUnderlined, "$color": color, "$colorHover": colorHover, type: type }, rest, { children: _jsx(TextComponent, { children: children }, void 0) }), void 0));
};
