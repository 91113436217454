import styled from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { PRE_LOGGEDIN_PAGE_CONTENT_MARGIN_OR_PADDING_TOP } from "@/src/constants";
export const RegisterContainer = styled.div `
	max-width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: ${PRE_LOGGEDIN_PAGE_CONTENT_MARGIN_OR_PADDING_TOP}px;
	box-sizing: border-box;
`;
export const RegisterTitle = styled(Typography.Heading3Heavy) `
	color: ${ColorBasic.Aqua60};
	margin-bottom: 9px;
`;
export const RegisterSubTitle = styled(Typography.ParagraphBaseRegular) `
	color: ${ColorBasic.Night40};
	margin-bottom: 32px;
`;
