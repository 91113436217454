import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { Nav } from "@/src/features/Nav/Nav";
import { Icons, Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { ColorBasic } from "@/src/enums/colors";
import { routes } from "@/src/routes";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
/* --------------------------------- Styles --------------------------------- */
const ForgotPasswordSuccessPageContainer = styled.div ``;
const ForgotPasswordSuccessContentContainer = styled.div `
	margin-top: 150px;
`;
const Title = styled(Typography.Heading3Regular) `
	text-align: center;
	color: ${ColorBasic.Aqua60};
	margin-bottom: 30px;
`;
const SVGEmailSentSuccessContainer = styled.div `
	display: flex;
	justify-content: center;
	margin-bottom: 28px;
`;
const SubheaderContainer = styled.div `
	display: flex;
	justify-content: center;
`;
const Subheader = styled(Typography.ParagraphBaseRegular) `
	text-align: center;
	color: ${ColorBasic.Night40};
`;
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
export const ForgotPasswordSuccessPage = () => {
    const { query } = useQuery();
    const email = (query === null || query === void 0 ? void 0 : query.get("email")) || "";
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Login",
                    linkTo: routes.Login.toPath(),
                } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsx(ForgotPasswordSuccessPageContainer, { children: _jsxs(ForgotPasswordSuccessContentContainer, { children: [_jsx(Title, { children: t("resetPasswordSent_header") }, void 0), _jsx(SVGEmailSentSuccessContainer, { children: _jsx(Icons.EmailSentSuccess, { width: 187, height: 102 }, void 0) }, void 0), _jsx(SubheaderContainer, { children: _jsx(Subheader, { children: t("resetPasswordSent_content", { email }) }, void 0) }, void 0)] }, void 0) }, void 0) }, void 0) }, void 0)] }, void 0));
};
export default ForgotPasswordSuccessPage;
