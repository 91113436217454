import { jsx as _jsx } from "react/jsx-runtime";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
const BoxContainer = styled.div `
	border-bottom: 0.5px solid ${ColorBasic.Dark40};
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	align-self: center;
	align-items: center;
	width: 100vw;
	background: ${ColorBasic.Blanc00};
`;
const TabsStyled = styled(Tabs) `
	&& .MuiTabs-indicator {
		border-radius: 100px 100px 0 0;
		height: 4px;
	}
`;
export default function ScrollableTabs({ tabs = [], ariaLabel, selectedTabValue, handleTabValueChange }) {
    return (_jsx(BoxContainer, { children: _jsx(Box, Object.assign({ sx: {
                maxWidth: 400,
                bgcolor: "background.paper",
                width: "100%",
            } }, { children: _jsx(TabsStyled, Object.assign({ value: selectedTabValue, onChange: handleTabValueChange, variant: "scrollable", scrollButtons: "auto", "aria-label": ariaLabel, TabIndicatorProps: {
                    style: {
                        background: ColorBasic.Aqua60,
                    },
                }, sx: {
                    "& .MuiButtonBase-root.Mui-selected": {
                        color: ColorBasic.Aqua60,
                    },
                    backgroundColor: ColorBasic.Blanc00,
                    "& button": {
                        minWidth: `calc(100% / ${tabs.length})`,
                    },
                } }, { children: tabs === null || tabs === void 0 ? void 0 : tabs.map((tab, iterator) => {
                    return (_jsx(Tab, { label: tab.label, value: tab.value, disabled: tab.disabled, sx: {
                            fontFamily: "Cabin",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: ".01em",
                            textAlign: "left",
                            textTransform: "capitalize",
                            color: "#023047",
                            flex: 1,
                            padding: "16px 0px 24px",
                        } }, iterator));
                }) }), void 0) }), void 0) }, void 0));
}
