import { jsx as _jsx } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import styled from "styled-components";
const Root = styled.div `
	height: 84px;
	width: 100%;
	background: ${({ $primary }) => ($primary ? ColorBasic.Night50 : ColorBasic.Light)};
	border-top: ${({ $primary }) => ($primary ? "none" : "1px solid #A5ABB3")};
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	gap: 24px;
`;
export const Footer = (p) => {
    return (_jsx(Root, Object.assign({ "$primary": p.isPrimary, "data-cy": "footer-container" }, { children: p.children }), void 0));
};
