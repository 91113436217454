import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
const Root = styled.div `
	& {
		${Typography.ParagraphSmallRegularStyle}
		color: ${ColorBasic.StatusError};
	}
`;
export const FieldError = (p) => {
    if (!p.error) {
        return null;
    }
    return _jsx(Root, { children: p.error }, void 0);
};
