import { ColorBasic } from "@/src/enums/colors";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
export const TypographyV2 = {
    FieldLabel: styled(Typography.SmallCapsBold) `
		color: ${ColorBasic.Night50};
	`,
    FieldDescription: styled(Typography.ParagraphSmallRegular) `
		color: ${ColorBasic.Dark60};
	`,
    ModalTitle: styled(Typography.Heading6SemiBold) `
		color: ${ColorBasic.Night50};
	`,
    ModalDescription: styled(Typography.ParagraphBaseMedium) `
		color: ${ColorBasic.Night50};
	`,
    WorkflowEditTitle: styled(Typography.Heading4Regular) `
		color: ${ColorBasic.Night50};
	`,
    WorkflowEditParagraph: styled(Typography.ParagraphSmallRegular) `
		color: ${ColorBasic.Dark60};
	`,
};
