import styled from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
export const RootContent = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const RootImage = styled.img `
	& {
		border-radius: 8px;
		margin-bottom: 24px;
		max-width: 100%;
		width: 100%;
	}
`;
export const RootHeader = styled(Typography.Heading3Heavy) `
	& {
		color: ${ColorBasic.Night50};
		text-align: center;
	}
`;
export const RootText = styled(Typography.ParagraphBaseRegular) `
	& {
		color: ${ColorBasic.Dark60};
		text-align: center;
	}
`;
