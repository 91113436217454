import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
import { Drawer, DrawerAnchorEnum, DrawerContent } from "@/src/features/common/Drawer";
import PaymentMethodCard from "@/src/features/Payment/PaymentMethodCard";
import ProfileWalletCardDrawerHeader from "@/src/features/Payment/Wallet/ProfileWalletCardDrawerHeader";
import ProfileWalletCardDrawerContent from "@/src/features/Payment/Wallet/ProfileWalletCardDrawerContent";
import { useAuth } from "@/src/hooks/useAuth";
import { useUserPaymentMethodsQuery } from "@/src/redux/apiServices/suiteApi";
import { t } from "@/src/features/Localization";
import SVGWalletNoCard from "@/src/public/app/images/svg/wallet-no-card.svg";
import { ColorBasic } from "@/src/enums/colors";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
/* --------------------------------- Styles --------------------------------- */
const ProfileWalletCardsContentContainer = styled.div ``;
const ProfileWalletCardsAddCardContentContainer = styled.div `
	margin-top: 142px;
`;
const ProfileWalletCardsAddCardContentImageContainer = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ProfileWalletCardsAddCardContentButtonContainer = styled.div `
	margin-top: 50px;
`;
const ProfileWalletCardsAddCardContentButton = styled.button `
	border-radius: 300px;
	text-align: center;
	color: ${ColorBasic.Blanc00};
	background: ${ColorBasic.Aqua60};

	border-color: ${ColorBasic.Aqua50};
	border: 2px solid ${ColorBasic.Aqua50};
	overflow: hidden;
	z-index: 2;
	height: 52px;
	width: 100%;
	left: 24px;
	top: 486px;
	border-radius: 300px;
	padding: 12px 42px;

	&:focus {
		outline: 1px solid ${ColorBasic.Aqua70};
		border-color: ${ColorBasic.Aqua70};
	}
`;
const ProfileWalletCardsAddCardButtonText = styled(Typography.ButtonLarge) `
	color: ${ColorBasic.Blanc00};
`;
const ProfileWalletCardsDrawerContentContainer = styled.div `
	margin-left: 23px;
	margin-right: 23px;
`;
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
export const ProfileWalletCards = ({ pharmacyStripeKey }) => {
    var _a;
    /* --------------------------------- States --------------------------------- */
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(true);
    /* ---------------------------------- Hooks --------------------------------- */
    const { user, fetchCurrentUser } = useAuth();
    /* -------------------------------- API Hooks ------------------------------- */
    const { data: userPaymentMethodResponseData, isFetching: isFetchingUserPaymentMethodResponse, refetch: userPaymentMethodRefetch, } = useUserPaymentMethodsQuery({ userFor: user ? user.guid : "" }, {
        skip: !user,
    });
    /* -------------------------------- Variables ------------------------------- */
    const paymentMethods = (_a = userPaymentMethodResponseData === null || userPaymentMethodResponseData === void 0 ? void 0 : userPaymentMethodResponseData.data) === null || _a === void 0 ? void 0 : _a.paymentMethods;
    const hasPaymentMethods = paymentMethods && paymentMethods.length > 0;
    /* ---------------------------- Helper Functions ---------------------------- */
    function handleDrawerClose() {
        setIsDrawerOpen(false);
    }
    function handleDrawerOpen() {
        setIsDrawerOpen(true);
    }
    /* ------------------------------- useEffects ------------------------------- */
    useEffect(() => {
        if (isDrawerOpen) {
            setIsLoadingForm(true);
        }
    }, [isDrawerOpen]);
    // We want to set a 2 second delay every time we open/close the payment method form
    // as Stripe takes some time to render the input components
    useEffect(() => {
        let formLoadingTimer;
        if (isDrawerOpen) {
            formLoadingTimer = setTimeout(() => setIsLoadingForm(false), 2000);
        }
        return () => {
            clearTimeout(formLoadingTimer);
        };
    }, [isDrawerOpen]);
    /* ---------------------------- Render Component ---------------------------- */
    let RenderedComponent;
    if (isFetchingUserPaymentMethodResponse) {
        RenderedComponent = (_jsx(ProfileWalletCardsAddCardContentContainer, { children: _jsx(ProfileWalletCardsAddCardContentImageContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0) }, void 0));
    }
    else if (hasPaymentMethods) {
        RenderedComponent = (_jsx(PaymentMethodCard, { paymentMethods: paymentMethods, user: user, refetchCurrentUser: fetchCurrentUser, userPaymentMethodRefetch: userPaymentMethodRefetch, isProfile: true }, void 0));
    }
    else {
        RenderedComponent = (_jsxs(ProfileWalletCardsAddCardContentContainer, { children: [_jsx(ProfileWalletCardsAddCardContentImageContainer, { children: _jsx(SVGWalletNoCard, {}, void 0) }, void 0), _jsx(ProfileWalletCardsAddCardContentButtonContainer, { children: _jsx(ProfileWalletCardsAddCardContentButton, Object.assign({ onClick: handleDrawerOpen }, { children: _jsx(ProfileWalletCardsAddCardButtonText, { children: t("profile_tab_wallet_empty_button_addPaymentMethod") }, void 0) }), void 0) }, void 0)] }, void 0));
    }
    // This is the main component responsble for rendering the Card component and displaying
    // the drawer which user may use to update their payment method on file
    return (_jsx(_Fragment, { children: _jsxs(ProfileWalletCardsContentContainer, { children: [RenderedComponent, _jsx(Drawer, Object.assign({ anchor: DrawerAnchorEnum.Bottom, open: isDrawerOpen, onClose: handleDrawerClose }, { children: _jsx(ProfileWalletCardsDrawerContentContainer, { children: _jsxs(DrawerContent, Object.assign({ isCenterItems: true }, { children: [_jsx(ProfileWalletCardDrawerHeader, {}, void 0), _jsx(ProfileWalletCardDrawerContent, { pharmacyStripeKey: pharmacyStripeKey, handleDrawerClose: handleDrawerClose, refetchCurrentUser: fetchCurrentUser, userPaymentMethodRefetch: userPaymentMethodRefetch, user: user, isLoadingForm: isLoadingForm }, void 0)] }), void 0) }, void 0) }), void 0)] }, void 0) }, void 0));
};
export default ProfileWalletCards;
