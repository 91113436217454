import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { WalletVisa, WalletAmericanExpress, WalletDiscover, WalletMasterCard } from "@/src/features/common/Icons";
import { Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { t } from "@/src/features/Localization";
const ICON_STYLE = { width: 40, height: 28 };
const Container = styled.div `
	display: flex;
	align-items: center;
	gap: 12px;
`;
const Brands = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const Label = styled(Typography.ParagraphSmallMedium) `
	color: ${ColorBasic.Dark60};
`;
export const AcceptedCreditCards = () => (_jsxs(Container, { children: [_jsx(Label, { children: t("profile_tab_wallet_addPaymentMethod_bottomSheet_acceptedMethods") }, void 0), _jsxs(Brands, { children: [_jsx(WalletVisa, { style: ICON_STYLE }, void 0), _jsx(WalletAmericanExpress, { style: ICON_STYLE }, void 0), _jsx(WalletMasterCard, { style: ICON_STYLE }, void 0), _jsx(WalletDiscover, { style: ICON_STYLE }, void 0)] }, void 0)] }, void 0));
export default AcceptedCreditCards;
