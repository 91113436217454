import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
export const ChipSmallStyled = styled(Typography.ButtonSmall) `
	display: inline-block;
	width: ${({ $width }) => ($width ? `${$width}px` : "24px")};
	height: ${({ $height }) => ($height ? `${$height}px` : "24px")};
	border-radius: ${({ $borderRadius }) => ($borderRadius ? `${$borderRadius}px` : "50%")};
	padding: 0;
	background: ${({ $background }) => $background !== null && $background !== void 0 ? $background : ColorBasic.Roseo70};
	color: ${({ $color }) => $color !== null && $color !== void 0 ? $color : ColorBasic.Blanc00};
	position: ${({ $position }) => ($position ? $position : "absolute")};
	z-index: ${({ $zIndex }) => ($zIndex ? `${$zIndex}` : "2")};
	right: ${({ $right }) => ($right ? `${$right}px` : "auto")};
	left: ${({ $left }) => ($left ? `${$left}px` : "auto")};
	top: ${({ $top }) => ($top ? `${$top}px` : "auto")};
	bottom: ${({ $bottom }) => ($bottom ? `${$bottom}px` : "auto")};
	text-align: center;
`;
export const ChipSmall = (p) => {
    return (_jsx(ChipSmallStyled, Object.assign({ className: p.className, "$height": p.height, "$width": p.width, "$borderRadius": p.borderRadius, "$position": p.position, "$background": p.background, "$color": p.color, "$zIndex": p.zIndex, "$right": p.right, "$left": p.left, "$bottom": p.bottom, "$top": p.top }, { children: p.children }), void 0));
};
