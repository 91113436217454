import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from "styled-components";
const Root = styled.div `
	& {
		display: block;
	}

	& svg {
		display: block;
		${(p) => {
    if (p.stateInfo.ColorIconSecondary) {
        return css `
					& {
						border-radius: 50%;
						outline: 4px solid ${p.stateInfo.ColorIconSecondary};
					}
				`;
    }
    return "";
}}
	}
`;
export const TimelineStepIcon = (p) => {
    const stateInfo = p.state;
    const Icon = stateInfo.Icon;
    return (_jsx(Root, Object.assign({ stateInfo: stateInfo }, { children: _jsx(Icon, { size: 32 }, void 0) }), void 0));
};
