import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { StreamContext } from "@/src/features/Chat/StreamProvider";
import { Typography } from "@/src/features/common";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { Nav } from "@/src/features/Nav/Nav";
import { Chatter } from "@/src/modules/workflow/layout/chat/chatter/Chatter";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import "stream-chat-react/dist/css/index.css";
import styled from "styled-components";
export var LayoutChatKey;
(function (LayoutChatKey) {
    LayoutChatKey["IsHistory"] = "h";
    LayoutChatKey["HistoryGuid"] = "hg";
    LayoutChatKey["Topic"] = "topic";
    LayoutChatKey["VirtualCareReason"] = "virtualCareReason";
})(LayoutChatKey || (LayoutChatKey = {}));
const RootAssignee = styled(Typography.ParagraphBaseRegular) `
	color: ${ColorBasic.Blanc00};
`;
export const LayoutChat = () => {
    const sc = useContext(StreamContext);
    const w = sc.chatWorkflow;
    const history = useHistory();
    const { query } = useQuery();
    const historyGuid = query.get(LayoutChatKey.HistoryGuid);
    const topic = query.get(LayoutChatKey.Topic);
    const virtualCareReason = query.get(LayoutChatKey.VirtualCareReason);
    useEffect(() => {
        // console.log("LayoutChat", topic, sc.isLoading, sc.chatWorkflow);
        if (topic && !sc.isLoading && !sc.chatWorkflow) {
            sc.addChat({
                topic: topic,
                virtualCareReason: virtualCareReason,
            });
            history.replace(routes.Chat.toPath({}));
        }
    }, [topic, sc.isLoading, sc.chatWorkflow]);
    useEffect(() => {
        if (!sc.isLoading && historyGuid) {
            if (!sc.chatWorkflow || sc.chatWorkflow.guid != historyGuid) {
                history.replace(routes.Chat.toPath({ isHistory: true, historyGuid: historyGuid }));
            }
        }
    }, [sc.isLoading, sc.chatWorkflow, historyGuid]);
    return (_jsxs(PageContainer, { children: [_jsx(Nav
            //
            , { 
                //
                dataLeft: { leftItemType: "Back", linkTo: routes.Home.toPath() }, dataMiddle: { centerItem: (w === null || w === void 0 ? void 0 : w.assignee) ? _jsx(RootAssignee, { children: w.assignee.name }, void 0) : undefined } }, void 0), _jsx(PageOverflowContainer, Object.assign({ isFullHeight: true }, { children: _jsx(PageWrapper, Object.assign({ isDisableVerticalPadding: true, style: { display: "flex" } }, { children: _jsx(Chatter, {}, void 0) }), void 0) }), void 0)] }, void 0));
};
