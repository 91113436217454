import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Colors } from "@/src/enums/colors";
/**
 * PageContainer will automatically ensure the content is within
 * a wrapper with the default defined max width and centering this
 * wrapper on the page.
 */
export const PageContainerWithStyle = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
	background: ${({ $background }) => $background !== null && $background !== void 0 ? $background : Colors.PageContainer};
`;
export const PageContainer = ({ background, className, children }) => {
    return (_jsx(PageContainerWithStyle, Object.assign({ className: className, "$background": background }, { children: children }), void 0));
};
