import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getHoursMapAsScheduleShort } from "@/src/common/utility/hours/HoursMap";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		background-color: ${ColorBasic.Dark10};
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 12px;
	}
`;
const RootTitle = styled(Typography.ParagraphBaseHeavy) `
	& {
		color: ${ColorBasic.Aqua60};
		text-transform: uppercase;
	}
`;
const RootLine = styled(Typography.ParagraphBaseRegular) `
	& {
		color: ${ColorBasic.Dark50};
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 2px;
	}
`;
const RootDays = styled(Typography.ParagraphBaseMedium) `
	& {
		color: ${ColorBasic.Dark60};
	}
`;
const RootHourList = styled.div `
	& {
	}
`;
export const ChatHoursBox = (p) => {
    if (!p.hours) {
        return null;
    }
    return (_jsxs(Root, { children: [_jsx(RootTitle, { children: p.title }, void 0), getHoursMapAsScheduleShort(p.hours).map((s) => {
                return (_jsxs(RootLine, { children: [_jsx(RootDays, { children: s.days }, void 0), _jsx(RootHourList, { children: s.hoursList.map((h) => {
                                return _jsx("div", { children: h }, h);
                            }) }, void 0)] }, s.key));
            })] }, void 0));
};
