import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { t } from "@/src/features/Localization";
import { Form, Icons, Button } from "@/src/features/common";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { useResetUserPasswordRequestMutation } from "@/src/redux/apiServices/suiteApi";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { routes } from "@/src/routes";
import { ColorBasic } from "@/src/enums/colors";
/* --------------------------------- Styles --------------------------------- */
const ForgotPasswordContainer = styled.div `
	width: 500px;
	max-width: 100%;
	text-align: center;
`;
const TextfieldContainer = styled.div `
	margin-bottom: 40px;
`;
const EmailInstructionsButton = styled.div `
	width: 244px;
	margin-left: auto;
	margin-right: auto;
`;
const IconContainer = styled.div `
	padding-top: 8px;
`;
export const ForgotPassword = () => {
    /* ---------------------------------- Hooks --------------------------------- */
    const { snackbar } = useCustomSnackbar();
    const history = useHistory();
    const [resetUserPasswordRequest, { error, isSuccess: isSuccessResetUserPasswordRequest, isLoading: isLoadingResetUserPasswordRequest, data: resetUserPasswordResponseData },] = useResetUserPasswordRequestMutation();
    /* --------------------------------- States --------------------------------- */
    const [submittedEmail, setSubmittedEmail] = useState("");
    /* ------------------------------ Formik Setup ----------------------------- */
    const LoginValidationSchema = Yup.object().shape({
        email: Yup.string().email(t("common_emailInput_error_invalidFormat")).required(t("common_emailInput_error_invalidFormat")),
    });
    const formikInitialValues = {
        email: "",
    };
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: LoginValidationSchema,
        onSubmit: ({ email }) => {
            if (!isLoadingResetUserPasswordRequest) {
                setSubmittedEmail(email);
                resetUserPasswordRequest({ email });
            }
        },
    };
    /* ------------------------------- useEffects ------------------------------- */
    useEffect(() => {
        var _a;
        if (isSuccessResetUserPasswordRequest) {
            snackbar.handleApiMessages((_a = resetUserPasswordResponseData === null || resetUserPasswordResponseData === void 0 ? void 0 : resetUserPasswordResponseData.data) === null || _a === void 0 ? void 0 : _a.messages);
            history.push(routes.ForgotPasswordSuccess.toPath({ email: submittedEmail }));
            setSubmittedEmail("");
        }
    }, [isSuccessResetUserPasswordRequest]);
    useEffect(() => {
        var _a, _b;
        if (error) {
            if ((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.messages) {
                snackbar.handleApiMessages((_b = error === null || error === void 0 ? void 0 : error.data) === null || _b === void 0 ? void 0 : _b.messages);
            }
            else {
                snackbar.error("There was an error processing your request.");
            }
        }
    }, [error]);
    return (_jsx(ForgotPasswordContainer, { children: _jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(TextfieldContainer, { children: _jsx(FormikTextField, { fullWidth: true, formikId: "email", label: "Email Address", "data-cy": "forgot-password-email-input" }, void 0) }, void 0), _jsx(EmailInstructionsButton, { children: _jsx(Button, Object.assign({ type: "submit", size: "large", color: "primary", variant: "round", fullWidth: true, "data-cy": "forgot-password-button", endIcon: _jsx(IconContainer, { children: _jsx(Icons.SendEmail, { size: 18, color: ColorBasic.NeutralLight }, void 0) }, void 0) }, { children: t("resetPassword_button_emailInstructions") }), void 0) }, void 0)] }), void 0)) }), void 0) }, void 0));
};
