var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { TextField as TextFieldMUI } from "@mui/material";
import { getIsOnKeyDownEventEnter } from "@/src/features/utils/eventHelpers";
import { ColorBasic } from "@/src/enums/colors";
const TextFieldPostalCodeMUIStyled = styled(TextFieldMUI) `
	&& {
		margin-top: 0px;
		margin-bottom: 0px;
	}

	&& .MuiInputBase-root {
		border-radius: 8px;
		color: ${ColorBasic.Dark90};
		background-color: ${ColorBasic.Dark10};

		line-height: 48px;
		height: 48px;
		font-family: Helvetica Neue;
		font-size: 15px;
	}

	// Use gray background with grey border when:
	// The TextField is empty (i.e. the label has is NOT class .MuiFormLabel-filled)
	// AND the TextField is NOT focused (.Mui-focused)
	// AND the TextField is NOT in error state (.Mui-error)
	//
	&& .MuiInputBase-root:not(.Mui-focused) {
		background-color: ${ColorBasic.Dark10};
		line-height: 48px;
		height: 48px;
		font-family: Helvetica Neue;
		font-size: 15px;
		color: ${(props) => (props.error ? ColorBasic.StatusError : ColorBasic.Dark90)};

		border-color: ${ColorBasic.Dark10};

		& .MuiOutlinedInput-notchedOutline {
			color: ${ColorBasic.Dark90};
			border-color: ${ColorBasic.Dark10};
		}
	}

	&& .MuiInputBase-root.Mui-focused {
		& .MuiOutlinedInput-notchedOutline {
			line-height: 48px;
			height: 48px;
			font-family: Helvetica Neue;
			font-size: 15px;
			color: ${(props) => (props.error ? ColorBasic.StatusError : ColorBasic.Dark90)};
			border-color: ${ColorBasic.Dark10};
		}
	}

	&& .MuiInputBase-input {
		padding: 0px 16px;
		line-height: 48px;
		height: 48px;
		font-family: Helvetica Neue;
		font-size: 15px;
		color: ${(props) => (props.error ? ColorBasic.StatusError : ColorBasic.Dark90)};

		border-color: ${ColorBasic.Dark10};
	}

	&& .MuiInputLabel-root {
		line-height: 36px;
		font-family: Helvetica Neue;
		font-size: 15px;
		color: ${(props) => (props.error ? ColorBasic.StatusError : ColorBasic.Dark60)};
	}

	&& .MuiInputLabel-outlined {
		line-height: 36px;
		font-family: Helvetica Neue;
		font-size: 15px;
		transform: translate(16px, 8px) scale(1);
		color: ${(props) => (props.error ? ColorBasic.StatusError : ColorBasic.Dark60)};
	}

	&& .MuiInputLabel-outlined.Mui-focused {
		line-height: 36px;
		font-family: Helvetica Neue;
		font-size: 15px;
		color: ${(props) => (props.error ? ColorBasic.StatusError : ColorBasic.Dark60)};
	}

	&& .MuiInputLabel-outlined.MuiInputLabel-shrink {
		height: 0px;
		width: 0px;
		display: hidden;
		visibility: hidden;
	}

	&& .MuiFormHelperText-root {
		height: 0px;
		width: 0px;
		display: hidden;
		visibility: hidden;
	}

	/**
	 * This fixes a strange issue where the value within ...<legend><span>....</span></legend>... has a width of 0.01px 
	 * due to material ui, but because of this the text (although it's visibility: hidden), actually pushes the page down
	 */
	&& .MuiOutlinedInput-notchedOutline legend > span {
		height: 10px !important;
		overflow-y: hidden !important;
	}
`;
export const TextFieldPostalCode = (_a) => {
    var { isSubmitOnKeyPressEnter, onKeyPress, error } = _a, rest = __rest(_a, ["isSubmitOnKeyPressEnter", "onKeyPress", "error"]);
    // If the `isSubmitOnKeyPressEnter` is true then pressing "Enter" will NOT
    // block the default event behaviour of (i.e. it will NOT have event.preventDefault).
    const handleOnKeyPress = (event) => {
        if (!isSubmitOnKeyPressEnter && getIsOnKeyDownEventEnter(event)) {
            event.preventDefault();
        }
        onKeyPress === null || onKeyPress === void 0 ? void 0 : onKeyPress(event);
    };
    return _jsx(TextFieldPostalCodeMUIStyled, Object.assign({ onKeyPress: handleOnKeyPress, error: Boolean(error) }, rest), void 0);
};
