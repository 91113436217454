import styled, { css } from "styled-components";
export const Heading3Heavy = styled.div `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 26px;
	line-height: 32.5px;
`;
export const Heading3Regular = styled.div `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 30px;
	line-height: 38px;
`;
export const Heading4Heavy = styled.div `
	font-family: Cabin;
	font-weight: 700;
	font-style: normal;
	font-size: 23px;
	line-height: 125%;
	letter-spacing: 0.01em;
`;
export const Heading4Regular = styled.div `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 23px;
	line-height: 28.75px;
	letter-spacing: 0.01em;
`;
export const Heading4LightStyle = css `
	font-family: Cabin;
	font-weight: 300;
	font-style: normal;
	font-size: 28px;
	line-height: 28.75px;
	letter-spacing: 0.01em;
`;
export const Heading5MediumStyle = css `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 20px;
	line-height: 25px;
`;
export const Heading5Medium = styled.div `
	${Heading5MediumStyle};
`;
export const Heading6Bold = styled.div `
	font-family: Cabin;
	font-weight: 700;
	font-style: normal;
	font-size: 19px;
	line-height: 23.75px;
`;
export const Heading6SemiBold = styled.div `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 19px;
	line-height: 125%;
`;
export const Heading6Regular = styled.div `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 19px;
	line-height: 125%;
	letter-spacing: 0.01em;
`;
export const ParagraphLargeHeavy = styled.div `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 23px;
	line-height: 36px;
`;
export const ParagraphBaseHeavy = styled.div `
	font-family: Cabin;
	font-weight: 700;
	font-style: normal;
	font-size: 16px;
	line-height: 22.4px;
`;
export const ParagraphBaseSemiHeavy = styled.div `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
`;
export const ParagraphBaseMediumStyle = css `
	font-family: Cabin;
	font-weight: 500;
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
`;
export const ParagraphBaseMedium = styled.div `
	${ParagraphBaseMediumStyle}
`;
export const ParagraphBaseRegularStyle = css `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 140%;
`;
export const ParagraphBaseRegular = styled.div `
	${ParagraphBaseRegularStyle}
`;
export const ParagraphSmallBoldStyle = css `
	font-family: Cabin;
	font-weight: 700;
	font-style: normal;
	font-size: 14px;
	line-height: 17.5px;
	letter-spacing: 0.01em;
`;
export const ParagraphSmallBold = styled.div `
	${ParagraphSmallBoldStyle}
`;
export const ParagraphSmallHeavyStyle = css `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 14px;
	line-height: 17.5px;
	letter-spacing: 0.01em;
`;
export const ParagraphSmallHeavy = styled.div `
	${ParagraphSmallHeavyStyle}
`;
export const ParagraphSmallMediumStyle = css `
	font-family: Cabin;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.5px;
	letter-spacing: 0.01em;
`;
export const ParagraphSmallMedium = styled.div `
	${ParagraphSmallMediumStyle}
`;
export const ParagraphSmallRegularStyle = css `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	line-height: 17.5px;
	letter-spacing: 0.01em;
`;
export const ParagraphSmallRegular = styled.div `
	${ParagraphSmallRegularStyle}
`;
export const ParagraphXSmallHeavyStyle = css `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 11px;
	line-height: 16px;
`;
export const ParagraphXSmallHeavy = styled.div `
	${ParagraphXSmallHeavyStyle}
`;
export const ParagraphXSmallRegularStyle = css `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 12px;
	line-height: 125%;
`;
export const ParagraphXSmallRegular = styled.div `
	${ParagraphXSmallRegularStyle}
`;
/* -------------------------------------------------------------------------- */
/*                                 Small Caps                                 */
/* -------------------------------------------------------------------------- */
const SmallCapsBaseStyle = css `
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	line-height: 21px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
`;
export const SmallCapsHeavy = styled.div `
	${SmallCapsBaseStyle};
	font-weight: 700;
`;
export const SmallCapsBold = styled.div `
	${SmallCapsBaseStyle};
	font-weight: 700;
`;
export const SmallCapsMedium = styled.div `
	${SmallCapsBaseStyle};
	font-weight: 500;
`;
export const SmallCapsMediumStyle = css `
	${SmallCapsBaseStyle};
	font-weight: 500;
`;
/* -------------------------------------------------------------------------- */
/*                                   Button                                   */
/* -------------------------------------------------------------------------- */
export const ButtonLargeStyle = css `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 18px;
	line-height: 28px;
`;
export const ButtonLarge = styled.div `
	${ButtonLargeStyle}
`;
export const ButtonMediumStyle = css `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0;
`;
export const ButtonMedium = styled.div `
	${ButtonMediumStyle}
`;
export const ButtonSmallStyle = css `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
`;
export const ButtonSmall = styled.div `
	${ButtonSmallStyle}
`;
export const ButtonMediumUnderlineStyle = css `
	${ButtonMediumStyle};
	text-decoration: underline;
`;
export const ButtonMediumUnderline = styled.div `
	${ButtonMediumUnderlineStyle}
`;
export const LinkMediumStyle = css `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
`;
export const LinkSmallStyle = css `
	font-family: Cabin;
	font-weight: 600;
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
`;
export const LinkSmall = styled.div `
	${LinkSmallStyle}
`;
