import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Icons, Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { t } from "@/src/features/Localization";
const OrdersNoPendingorPastOrderContainer = styled.div ``;
const OrdersNoPendingorPastOrderIconContainer = styled.div `
	margin: 16px auto;
	display: block;
	width: 78px;
	height: 84px;
`;
const OrdersNoPendingorPastOrderTitle = styled(Typography.ParagraphSmallRegular) `
	color: ${ColorBasic.Night30};
	text-align: center;
`;
export const OrderCardNoOrders = () => {
    return (_jsxs(OrdersNoPendingorPastOrderContainer, { children: [_jsx(OrdersNoPendingorPastOrderIconContainer, { children: _jsx(Icons.OrdersNone, { width: 78, height: 84 }, void 0) }, void 0), _jsx(OrdersNoPendingorPastOrderTitle, { children: t("orders_noOrders_past") }, void 0)] }, void 0));
};
