import { ColorBasic } from "@/src/enums/colors";
import { css } from "styled-components";
export var ButtonStyle;
(function (ButtonStyle) {
    ButtonStyle["Blank"] = "blank";
    ButtonStyle["SelectDropdown"] = "select-dropdown";
    ButtonStyle["SelectItem"] = "select-item";
    ButtonStyle["SolidGhost"] = "solid-ghost";
    ButtonStyle["SolidPrimary"] = "solid-primary";
    ButtonStyle["TextAnchor"] = "text-anchor";
    ButtonStyle["TextSubtle"] = "text-subtle";
})(ButtonStyle || (ButtonStyle = {}));
const infoMap = new Map();
const infoList = [
    {
        Style: ButtonStyle.Blank,
        Css: css `
			&&,
			&&:hover {
				background: transparent;
				color: inherit;
				text-decoration: none;
			}
		`,
    },
    {
        Style: ButtonStyle.SelectDropdown,
        Css: css `
			&& {
			}

			&&:hover {
				background: ${ColorBasic.Aqua10};
			}
		`,
    },
    {
        Style: ButtonStyle.SelectItem,
        Css: css `
			&& {
			}

			&&:hover {
				background: ${ColorBasic.Aqua10};
			}
		`,
    },
    {
        Style: ButtonStyle.SolidGhost,
        Css: css `
			&& {
				border: 2px solid ${ColorBasic.Aqua60};
				border-radius: 100px;
				color: ${ColorBasic.Aqua60};
				font-family: Cabin, sans-serif;
				font-style: normal;
				font-size: 14px;
				font-weight: 600;
				padding: 12px 18px;
			}

			&&:hover {
				background: ${ColorBasic.Aqua10};
			}
		`,
    },
    {
        Style: ButtonStyle.SolidPrimary,
        Css: css `
			&& {
				background: ${ColorBasic.Aqua60};
				border-radius: 100px;
				color: #fff;
				font-family: Cabin, sans-serif;
				font-weight: 600;
				font-style: normal;
				font-size: 14px;
				line-height: 20px;
				padding: 12px 18px;
			}

			&&:hover {
				background: ${ColorBasic.Aqua50};
			}
		`,
    },
    {
        Style: ButtonStyle.TextAnchor,
        Css: css `
			&& {
				align-items: start;
				color: ${ColorBasic.Aqua60};
				display: inline;
				font-size: inherit;
				text-decoration: underline;
			}
		`,
    },
    {
        Style: ButtonStyle.TextSubtle,
        Css: css `
			&& {
				color: ${ColorBasic.Dark60};
				font-family: Cabin, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 125%;
			}
		`,
    },
];
infoList.forEach((info) => infoMap.set(info.Style, info));
export const getButtonStyleInfo = (s) => {
    return infoMap.get(s);
};
