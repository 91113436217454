var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { CardNumberElement, CardCvcElement, CardExpiryElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import styled, { css } from "styled-components";
import { useUserPaymentMethodCreateMutation } from "@/src/redux/apiServices/suiteApi";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { t } from "@/src/features/Localization";
import { ColorBasic } from "@/src/enums/colors";
import { Form, Typography } from "@/src/features/common";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import AcceptedCreditCards from "@/src/features/common/AcceptedCreditCards";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
const ProfileWalletCardDrawerContentContainer = styled.div `
	margin-top: 20px;
	margin-bottom: 20px;
	opacity: ${(props) => (props.isLoadingForm ? 0.5 : 1)};
	width: 330px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
const InputStyle = css `
	background-color: ${ColorBasic.Dark10};
	border-radius: 8px;
	padding: 0px 12px 0px 12px;
`;
const CardNumberContainer = styled.div `
	${InputStyle}
`;
const CardDetailsContainer = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
`;
const CardExpiryElementContainer = styled.div `
	width: 147px;
	${InputStyle}
`;
const CardCvcElementContainer = styled.div `
	width: 147px;
	${InputStyle}
`;
const CardPostalCodeContainer = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 24px;
`;
const SaveCardButton = styled.button `
	background: ${ColorBasic.Aqua60};
	color: ${ColorBasic.Blanc00};
	position: relative;

	display: block;

	padding: 12px;
	border-radius: 32px;
	overflow: hidden;

	width: 244px;
	height: 50px;
	margin-left: auto;
	margin-right: auto;
`;
const SaveCardButtonText = styled(Typography.ButtonLarge) `
	color: ${ColorBasic.Blanc00};
`;
const LoadingSpinnerContainer = styled.div `
	position: absolute;
	left: 40%;
	top: 12.5%;
`;
const LoaderSpinnerContainerMain = styled.div `
	position: absolute;
	top: 150px;
	left: 143px;
	z-index: 12;
`;
const PaymentCreateForm = ({ user, isLoadingForm, handleDrawerClose, refetchCurrentUser, userPaymentMethodRefetch }) => {
    /* --------------------------------- States --------------------------------- */
    const [paymentError, setPaymentError] = useState(false);
    /* ---------------------------------- Hooks --------------------------------- */
    const stripe = useStripe();
    const elements = useElements();
    const { snackbar } = useCustomSnackbar();
    /* -------------------------------- API Hooks ------------------------------- */
    const [createUserPaymentMethod, { isLoading: isLoadingCreateUserPaymentMethod, isSuccess: isSuccessCreateUserPaymentMethod }] = useUserPaymentMethodCreateMutation();
    /* ---------------------------- Helper Functions ---------------------------- */
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (elements === null || stripe === null) {
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);
        if (cardElement && user) {
            const { error, paymentMethod } = yield stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });
            const paymentMethodId = paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.id;
            if (error) {
                setPaymentError(true);
            }
            if (paymentMethodId) {
                createUserPaymentMethod({
                    userFor: user.guid,
                    paymentMethodId,
                });
            }
        }
    });
    /* -------------------------------- Variables ------------------------------- */
    const StripeFieldBaseStyle = {
        base: {
            color: `${ColorBasic.Dark90}`,
            iconColor: `${ColorBasic.Blanc00}`,
            lineHeight: "48px",
            height: "48px",
            fontSize: "15px",
            backgroundColor: `${ColorBasic.Dark10}`,
            "::placeholder": {
                color: `${ColorBasic.Dark60}`,
            },
        },
    };
    const CardNumberElementOptions = {
        showIcon: false,
        style: StripeFieldBaseStyle,
        placeholder: "Card number",
    };
    const CardExpiryElementOptions = {
        style: StripeFieldBaseStyle,
        placeholder: "MM / YY",
    };
    const CardCvcElementOptions = {
        style: StripeFieldBaseStyle,
        placeholder: "CVV",
    };
    /* ------------------------------ useEffect ----------------------------- */
    useEffect(() => {
        if (isSuccessCreateUserPaymentMethod) {
            refetchCurrentUser();
            userPaymentMethodRefetch();
            handleDrawerClose();
        }
    }, [isSuccessCreateUserPaymentMethod]);
    useEffect(() => {
        if (paymentError) {
            snackbar.error("Error setting payment method");
        }
    }, [paymentError]);
    /* ------------------------------ Formik Setup ----------------------------- */
    const ProfileWalletPaymentMethodValidationSchema = Yup.object().shape({
        paymentPostalCode: Yup.string().required("Please enter a valid postal code"),
    });
    const formikInitialValues = {
        paymentPostalCode: "",
    };
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: ProfileWalletPaymentMethodValidationSchema,
        onSubmit: handleSubmit,
    };
    /* ------------------------------ Render Component ----------------------------- */
    return (_jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsx(Form, Object.assign({ onSubmit: formik.handleSubmit, id: "wallet" }, { children: _jsxs(ProfileWalletCardDrawerContentContainer, Object.assign({ isLoadingForm: isLoadingForm }, { children: [_jsx(AcceptedCreditCards, {}, void 0), _jsx(CardNumberContainer, { children: _jsx(CardNumberElement, { options: CardNumberElementOptions }, void 0) }, void 0), _jsxs(CardDetailsContainer, { children: [_jsx(CardExpiryElementContainer, { children: _jsx(CardExpiryElement, { options: CardExpiryElementOptions }, void 0) }, void 0), _jsx(CardCvcElementContainer, { children: _jsx(CardCvcElement, { options: CardCvcElementOptions }, void 0) }, void 0)] }, void 0), _jsx(CardPostalCodeContainer, { children: _jsx(FormikTextField, { formikId: "paymentPostalCode", label: isLoadingForm ? "" : "Postal Code", fullWidth: true, type: FormikTextFieldTypes.PostalCode }, void 0) }, void 0), _jsx(SaveCardButton, Object.assign({ type: "submit", disabled: !stripe, form: "wallet" }, { children: isLoadingCreateUserPaymentMethod ? (_jsx(LoadingSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0)) : (_jsx(SaveCardButtonText, { children: t("profile_tab_wallet_addPaymentMethod_bottomSheet_button_savePaymentMethod") }, void 0)) }), void 0)] }), void 0) }), void 0)) }), void 0));
};
export const ProfileWalletCardDrawerContent = ({ pharmacyStripeKey, handleDrawerClose, refetchCurrentUser, user, isLoadingForm, userPaymentMethodRefetch, }) => {
    const stripePromise = loadStripe(pharmacyStripeKey);
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsxs(_Fragment, { children: [isLoadingForm && (_jsx(LoaderSpinnerContainerMain, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0)), _jsx(Elements, Object.assign({ stripe: stripePromise }, { children: _jsx(PaymentCreateForm, { isLoadingForm: isLoadingForm, user: user, refetchCurrentUser: refetchCurrentUser, handleDrawerClose: handleDrawerClose, userPaymentMethodRefetch: userPaymentMethodRefetch }, void 0) }), void 0)] }, void 0));
};
export default ProfileWalletCardDrawerContent;
