import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Icons } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
const Root = styled.button `
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	height: 24px;
	width: 24px;
	box-sizing: border-box;
	background-color: ${({ $checked }) => ($checked ? ColorBasic.Aqua50 : "transparent")};
	border: ${({ $checked }) => ($checked ? "0" : `0.5px solid ${ColorBasic.Night50}`)};
	padding: 0;

	&:disabled {
		background-color: ${ColorBasic.Dark20};
		border-color: ${ColorBasic.Dark20};
	}
`;
const IconStyled = styled(Icons.CheckRounded) `
	&&.MuiSvgIcon-root {
		fill: ${({ $checked }) => ($checked ? ColorBasic.Light70 : ColorBasic.Blanc90)};
	}
`;
export const Checkbox = (p) => {
    return (_jsx(Root, Object.assign({ type: "button", onClick: () => {
            if (p.onChange !== undefined && !p.isDisabled) {
                p.onChange(!p.checked);
            }
        }, disabled: p.isDisabled, "$checked": p.checked }, { children: p.checked ? _jsx(IconStyled, { color: "white", "$checked": p.checked }, void 0) : "" }), void 0));
};
