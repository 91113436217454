var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { t } from "@/src/features/Localization";
import { useUserInviteWithoutEmailActivateCustomerMutation } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { Form } from "@/src/features/common";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import { RegisterOrActivateTermsOfServiceCheckbox } from "@/src/features/RegisterOrPasswordOrActivation/RegisterOrActivateTermsOfServiceCheckbox";
import { FormId } from "@/src/enums/forms";
import { RegisterType } from "@/src/features/RegisterAccount/types";
import { UserPasswordYup } from "@/src/common/utility/password/UserPassword";
import { ButtonRegister } from "@/src/common/components/button/ButtonRegister";
import { PasswordStrengthIndicator } from "@/src/features/common/PasswordStrengthIndicator";
/* --------------------------------- Styles --------------------------------- */
const NewPasswordContainer = styled.div `
	max-width: 100%;
	// text-align: center;
`;
export const ActivateWithEmailPassword = (p) => {
    const history = useHistory();
    const [apiActivate, apiActivateState] = useUserInviteWithoutEmailActivateCustomerMutation();
    const formikInitialValues = {
        email: "",
        password: "",
        registerPrivacyPolicyCheckbox: false,
    };
    const handleSubmit = (v) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = (yield apiActivate({
                email: v.email,
                password: v.password,
                tokenRegister: p.tokenRegister,
            }).unwrap());
            if (response.code == 200) {
                history.push(routes.RegisterComplete.toPath({
                    email: v.email,
                }));
            }
        }
        catch (e) {
            // TODO: reidenzon - do something!
        }
    });
    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t("common_emailInput_error_invalidFormat")).required(t("common_emailInput_error_invalidFormat")),
        password: UserPasswordYup(),
        registerPrivacyPolicyCheckbox: Yup.boolean().oneOf([true], "Required"),
    });
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    };
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(NewPasswordContainer, { children: _jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.ResetPassword }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "email", label: "Email", inputProps: {
                            autoComplete: "username",
                        } }, void 0), _jsx(FormikTextField, { fullWidth: true, formikId: "password", type: FormikTextFieldTypes.Password, label: t("changePassword_inputLabel_newPassword") }, void 0), _jsx(PasswordStrengthIndicator, { currentPassword: formik.values.password }, void 0), _jsx(RegisterOrActivateTermsOfServiceCheckbox, { registerType: RegisterType.Regular, formikId: "registerPrivacyPolicyCheckbox" }, void 0), _jsx(ButtonRegister, { disabled: apiActivateState.isLoading, text: t("common_button_confirm") }, void 0)] }), void 0)) }), void 0) }, void 0));
};
