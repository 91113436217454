import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { TypographyV2 } from "@/src/common/style/StyleTypography";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: 12px;
	}
`;
export const ServiceListSection = (p) => {
    return (_jsxs(Root, { children: [_jsx(TypographyV2.FieldLabel, { children: p.title }, void 0), p.description && _jsx(TypographyV2.FieldDescription, { children: p.description }, void 0), _jsx(RootContent, { children: p.children }, void 0)] }, void 0));
};
