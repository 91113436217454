import styled from "styled-components";
import { withCssBackgroundImages } from "@/src/features/common/HOCs/withCssBackgroundImages/withCssBackgroundImages";
/**
 * This is used on pages that do not have a nav or footer
 * but will require content scrolling.
 */
export const PageFullOverflowContainer = withCssBackgroundImages(styled.div `
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
	padding: 0 16px;
	justify-content: center;
`);
