import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 12px;
		justify-content: space-between;
	}
`;
const RootTitle = styled.div `
	& {
		${Typography.ParagraphBaseSemiHeavy};
		color: ${ColorBasic.Night50};
	}
`;
const RootPrice = styled.div `
	& {
		${Typography.ParagraphBaseRegularStyle};
		color: ${ColorBasic.Aqua60};
	}
`;
export const DeliveryMethodSelectHeader = (p) => {
    return (_jsxs(Root, { children: [_jsx(RootTitle, { children: p.title }, void 0), _jsx(RootPrice, { children: p.price }, void 0)] }, void 0));
};
