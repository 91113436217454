import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Button, Icons } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { ButtonText } from "@/src/common/components/button/ButtonText";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
const Root = styled.footer `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;
export const SlideshowFooter = (p) => {
    const segment = useSegmentAnalytics();
    const handleClose = () => {
        p.onClose();
        segment.segmentTrackEvent("select_close", {
            entrypoint: SegmentEntryPoint.OnboardingSlideshow,
        });
    };
    const handleSkip = () => {
        p.onClose();
        segment.segmentTrackEvent("select_skip", {
            entrypoint: SegmentEntryPoint.OnboardingSlideshow,
        });
    };
    return (_jsxs(Root, { children: [p.isClose && _jsx(ButtonText, { onClick: handleClose, text: "Close" }, void 0), p.isSkip && _jsx(ButtonText, { onClick: handleSkip, text: "Skip Tour" }, void 0), p.isNext && (_jsx(Button
            //
            , Object.assign({ 
                //
                type: "button", onClick: p.onNext, endIcon: _jsx(Icons.ArrowForward, { color: ColorBasic.NeutralLight, size: 24 }, void 0), size: "small" }, { children: "Next" }), void 0))] }, void 0));
};
