import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import CssBaseline from "@mui/material/CssBaseline";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
const MaUTableStyled = styled(MaUTable) `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;

	background-color: ${ColorBasic.Blanc00};

	& * {
		color: ${ColorBasic.Dark};
	}

	& .MuiTableCell-head {
		font-family: Cabin;
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		line-height: 24px;
		color: ${ColorBasic.Dark60};
	}
`;
// We can disable no-explicit-any because we wan the table to accept any
// so that it can be used with varrying columns and data structures.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    });
    // Render the UI for your table
    return (_jsxs(MaUTableStyled, Object.assign({}, getTableProps(), { children: [_jsx(TableHead, { children: headerGroups.map((headerGroup) => (
                // The key is specified within `...headerGroup.getHeaderGroupProps()`
                // eslint-disable-next-line react/jsx-key
                _jsx(TableRow, Object.assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map((column) => (
                    // The key is specified within `...column.getHeaderProps()`
                    // eslint-disable-next-line react/jsx-key
                    _jsx(TableCell, Object.assign({}, column.getHeaderProps(), { children: column.render("Header") }), void 0))) }), void 0))) }, void 0), _jsx(TableBody, { children: rows.map((row) => {
                    prepareRow(row);
                    return (
                    // The key is specified within `...row.getRowProps()`
                    // eslint-disable-next-line react/jsx-key
                    _jsx(TableRow, Object.assign({}, row.getRowProps(), { children: row.cells.map((cell) => {
                            return (
                            // The key is specified within `...cell.getCellProps()`
                            // eslint-disable-next-line react/jsx-key
                            _jsx(TableCell, Object.assign({}, cell.getCellProps(), { children: cell.render("Cell") }), void 0));
                        }) }), void 0));
                }) }, void 0)] }), void 0));
}
export default Table;
const exampleData = [
    {
        firstName: "minister",
        lastName: "leader",
        age: 10,
        visits: 91,
        progress: 30,
        status: "single",
    },
    {
        firstName: "children",
        lastName: "interest",
        age: 29,
        visits: 84,
        progress: 99,
        status: "relationship",
    },
];
export function ExampleTable() {
    const columns = React.useMemo(() => [
        {
            Header: "First Name",
            accessor: "firstName",
        },
        {
            Header: "Last Name",
            accessor: "lastName",
        },
    ], []);
    return (_jsx("div", { children: _jsx(Table, { columns: columns, data: exampleData }, void 0) }, void 0));
}
