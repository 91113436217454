import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MUISelect from "@mui/material/Select";
import styled from "styled-components";
import { Checkbox } from "@/src/features/common/FormInputs/Checkbox";
import { Icons, Typography } from "@/src/features/common";
import { MarginPx } from "@/src/enums";
import { ColorBasic } from "@/src/enums/colors";
import { ListItemIcon } from "@mui/material";
import { Elevations } from "@/src/enums/elevations";
import { ErrorMessage } from "@/src/features/common/ErrorMessage";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
/* ------------------------- Constants and Variables ------------------------ */
const borderRadius = "8px";
/* --------------------------------- Styles --------------------------------- */
export const FormControlStyled = styled(FormControl) `
	width: 100%;

	&& {
		margin-top: ${MarginPx.FormElementMargin};
		margin-bottom: ${MarginPx.FormElementMargin};
	}

	&& .MuiInputBase-root {
		border-radius: ${borderRadius};
	}

	.MuiInputBase-input {
		padding: 12px 16px;
		height: 23px;
		border-radius: ${borderRadius};
	}

	&& .MuiSelect-select {
		flex: 1;
		${Typography.ParagraphBaseRegularStyle}
	}

	.MuiFormLabel-root {
		${Typography.ParagraphBaseRegularStyle}
		transform: translate(14px, 12px) scale(1);
		color: ${(props) => (props.$disabled ? ColorBasic.Dark50 : "rgba(0, 0, 0, 0.6)")};

		&.MuiInputLabel-shrink {
			${Typography.ParagraphSmallRegularStyle}
			padding: 2px 4px;
			border-radius: 8px;
			transform: translate(12px, -12px) scale(1);
		}
	}

	& .MuiOutlinedInput-notchedOutline legend {
		${Typography.ParagraphSmallRegularStyle}
	}

	.MuiListItemIcon-root {
		display: none;
	}

	// When Select is focused (selected)
	.Mui-focused {
		&.MuiInputLabel-shrink {
			color: ${ColorBasic.Aqua60};
		}

		& .MuiOutlinedInput-notchedOutline {
			border-color: ${ColorBasic.Aqua60};
		}
	}

	// If it is empty and not focused,
	// then we want a grey background and same
	// color border.
	${({ $isEmpty }) => $isEmpty
    ? `
			.MuiSelect-root:not(.Mui-focused) {
				background: ${ColorBasic.Dark10};

				&:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
					border-color: ${ColorBasic.Dark10};
				}
			}
			`
    : ""}

	&& .MuiTypography-root {
		${Typography.ParagraphBaseMediumStyle}
		color: ${ColorBasic.Night50};
	}
`;
const SelectStyled = styled(MUISelect) `
	&& .MuiPaper-root {
		box-shadow: ${Elevations.Tight1};
		border-radius: 7px;
	}
`;
const MenuItemStyled = styled(MenuItem) `
	&& .MuiListItemText-root {
		${Typography.ParagraphBaseMediumStyle}
		color: ${ColorBasic.Night50};
		margin: 0px;
		padding: 10px 12px;
	}

	&&.MuiMenuItem-root {
		${Typography.ParagraphBaseMediumStyle}
		color: ${ColorBasic.Night50};
		margin: 0px;
		padding: 10px 12px;
	}

	&&.MuiListItem-root {
		justify-content: space-between;
	}
`;
const ListItemIconStyled = styled(ListItemIcon) `
	&& {
		display: flex;
		opacity: 0;
		height: 0;
		min-width: 0px;
		margin-left: auto;
	}

	.MuiPopover-root && {
		height: auto;
		display: flex;
		opacity: 1;
	}
`;
const SelectCustomMenuItemWithCheckbox = ({ multipleValues, option }) => {
    return (_jsxs(MenuItemStyled, Object.assign({ value: option.value }, { children: [_jsx(Checkbox, { checked: Boolean(multipleValues && (multipleValues === null || multipleValues === void 0 ? void 0 : multipleValues.indexOf(option.value)) > -1) }, void 0), _jsx(ListItemText, { primary: option.label }, void 0)] }), option.value));
};
const SelectCustomMenuItem = ({ value, option }) => {
    return (_jsxs(MenuItemStyled, Object.assign({ disabled: option.disabled, value: option.value }, { children: [option.label, option.value === value && (_jsx(ListItemIconStyled, { children: _jsx(Icons.Check, { size: 24, color: ColorBasic.Aqua50 }, void 0) }, void 0))] }), option.value));
};
export const Select = (p) => {
    const label = p.label;
    const name = p.name;
    const value = p.value;
    const error = p.error;
    const multipleValues = p.multipleValues;
    const options = p.options || [];
    const isLoading = p.isLoading;
    const helperText = p.helperText;
    // const multiple = p.multiple;
    const disabled = p.disabled;
    const onChange = p.onChange;
    const className = p.className;
    const CustomOptionComponent = p.CustomOptionComponent;
    const CustomMultipleOptionComponent = p.CustomMultipleOptionComponent;
    const multiple = Boolean(multipleValues);
    let theSelect;
    // Ensure there are options that are available to be selected.
    const isForceDisabled = Boolean(!((options === null || options === void 0 ? void 0 : options.length) > 0)) || disabled;
    const hasMultipleValues = multipleValues && (multipleValues === null || multipleValues === void 0 ? void 0 : multipleValues.length) > 0;
    const hasValue = Boolean(value && value !== "");
    const isEmpty = !(hasValue || hasMultipleValues);
    if (isLoading) {
        theSelect = (_jsx(SelectStyled, Object.assign({ value: "isLoading", disabled: true, MenuProps: {
                disablePortal: true,
            }, label: label }, { children: _jsx(MenuItemStyled, Object.assign({ value: "isLoading" }, { children: _jsx(LoaderSpinner, { size: 24 }, void 0) }), "isLoadingMenuItem") }), void 0));
    }
    else if (multiple) {
        theSelect = (_jsx(SelectStyled, Object.assign({ value: multipleValues !== null && multipleValues !== void 0 ? multipleValues : [], name: name, error: error, disabled: disabled || isForceDisabled, onChange: onChange, label: label, MenuProps: {
                disablePortal: true,
            }, multiple: true, 
            // Render a comma separated list of the selected values' associated labels.
            renderValue: (selected) => {
                const selectedItems = selected;
                let renderedValue = "";
                selectedItems.forEach((selectedItem) => {
                    options.forEach((option) => {
                        if (option.value === selectedItem) {
                            renderedValue = renderedValue.concat(option.label, ", ");
                        }
                    });
                });
                if (renderedValue.length > 0) {
                    renderedValue = renderedValue.substring(0, renderedValue.length - 1);
                }
                return renderedValue.slice(0, -1);
            } }, { children: options.map((option) => CustomMultipleOptionComponent ? (_jsx(MenuItemStyled, Object.assign({ value: option.value }, { children: _jsx(CustomMultipleOptionComponent, { multipleValues: multipleValues, option: option }, option.value) }), option.value)) : (_jsx(SelectCustomMenuItemWithCheckbox, { option: option, multipleValues: multipleValues }, option.value))) }), void 0));
    }
    else {
        theSelect = (_jsx(SelectStyled, Object.assign({ value: value, name: name, error: error, disabled: disabled || isForceDisabled, MenuProps: {
                disablePortal: true,
            }, label: label, onChange: onChange, onOpen: p.onOpen }, { children: options.map((option) => CustomOptionComponent ? (_jsx(MenuItemStyled, Object.assign({ value: option.value }, { children: _jsx(CustomOptionComponent, { option: option, value: value }, option.value) }), option.value)) : (
            // TODO:Bhav figure out why this doesnt work
            // <SelectCustomMenuItem
            // 	key={option.value}
            // 	value={value}
            // 	option={option}
            // />
            _jsxs(MenuItemStyled, Object.assign({ disabled: option.disabled, value: option.value }, { children: [option.label, option.value === value && (_jsx(ListItemIconStyled, { children: _jsx(Icons.Check, { size: 24, color: ColorBasic.Aqua50 }, void 0) }, void 0))] }), option.value))) }), void 0));
    }
    return (_jsxs(FormControlStyled, Object.assign({ "$disabled": isForceDisabled, "$isEmpty": isEmpty, className: className }, { children: [label && _jsx(InputLabel, { children: label }, void 0), theSelect, helperText && _jsx(ErrorMessage, { error: helperText !== null && helperText !== void 0 ? helperText : "" }, void 0)] }), void 0));
};
export default Select;
