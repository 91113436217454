import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { ColorBasic } from "@/src/enums/colors";
const BoxStyled = styled(Box) `
	position: absolute;
	width: 327px;
	height: 240px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${ColorBasic.Light};
	border-radius: 7px;
`;
const TitleContainer = styled.div `
	margin-top: 54px;
	margin-left: 43px;
	margin-right: 43px;
	margin-bottom: 24px;
`;
const Title = styled(Typography.Heading6Bold) `
	color: ${ColorBasic.Roseo50};
	text-align: center;
`;
const InformationTextContainer = styled.div `
	margin-top: 24px;
	margin-left: 43px;
	margin-right: 43px;
	margin-bottom: 38px;
`;
const InformationText = styled(Typography.ParagraphBaseMedium) `
	color: ${ColorBasic.Dark};
	text-align: center;
`;
const ButtonContainer = styled.div `
	margin-top: 38;
	margin-left: 32px;
	margin-right: 32px;
	margin-bottom: 12px;
	display: flex;
	justify-content: center;
	gap: 24px;
`;
const ButtonTextNo = styled(Typography.ButtonMediumUnderline) `
	color: ${ColorBasic.Night50};
	cursor: pointer;
`;
const ButtonTextYes = styled(Typography.ButtonMediumUnderline) `
	color: ${ColorBasic.Roseo50};
	cursor: pointer;
`;
export default function MFBButtonCancelModal({ isOpen, handleClose, handleSubmit }) {
    return (_jsx(Modal, Object.assign({ open: isOpen, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsxs(BoxStyled, { children: [_jsx(TitleContainer, { children: _jsx(Title, { children: t("common_mfbInput_dialog_remove_header") }, void 0) }, void 0), _jsx(InformationTextContainer, { children: _jsx(InformationText, { children: t("common_mfbInput_dialog_remove_content") }, void 0) }, void 0), _jsxs(ButtonContainer, { children: [_jsx(ButtonTextNo, Object.assign({ onClick: handleClose }, { children: t("common_mfbInput_dialog_remove_button_no") }), void 0), _jsx(ButtonTextYes, Object.assign({ onClick: handleSubmit }, { children: t("common_mfbInput_dialog_remove_button_yes") }), void 0)] }, void 0)] }, void 0) }), void 0));
}
