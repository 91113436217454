import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV2 } from "@/src/common/style/StyleTypography";
import { translate } from "@/src/common/utility/translation/Translate";
import { ColorBasic } from "@/src/enums/colors";
import { Button, Typography } from "@/src/features/common";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { Nav } from "@/src/features/Nav/Nav";
import { useCreateThenRedirectToNewWorkflowServiceMinorAilment } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowServiceMinorAilment";
import { useAuth } from "@/src/hooks/useAuth";
import { useMinorAilmentViewSingleQuery } from "@/src/modules/minor-ailment/redux/ApiCoreMinorAilment";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	//width: 315px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 24px;
`;
const RootBookNow = styled(Typography.ParagraphLargeHeavy) `
	color: ${ColorBasic.Dark60};
`;
const RootSpacer = styled.div `
	& {
		//height: 24px;
	}
`;
const LoginButton = styled(Button) `
	background: ${ColorBasic.Aqua60};
	color: ${ColorBasic.Blanc00};
	position: relative;

	display: block;

	border-radius: 32px;
	overflow: hidden;
	padding: 12px 85px !important;

	//margin-left: auto;
	margin-right: auto;
`;
const LoginButtonContentContainer = styled.div `
	display: flex;
	justify-content: center;
	gap: 8px;
`;
const LoginButtonText = styled.div `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	//letter-spacing: 1%;
`;
export var LayoutMinorAilmentKey;
(function (LayoutMinorAilmentKey) {
    LayoutMinorAilmentKey["Ailment"] = "ailment";
})(LayoutMinorAilmentKey || (LayoutMinorAilmentKey = {}));
export const LayoutMinorAilment = () => {
    var _a;
    const auth = useAuth();
    const history = useHistory();
    const { query } = useQuery();
    const key = query === null || query === void 0 ? void 0 : query.get(LayoutMinorAilmentKey.Ailment);
    const apiView = useMinorAilmentViewSingleQuery({ key: key }, { skip: !key });
    const ailment = (_a = apiView.data) === null || _a === void 0 ? void 0 : _a.data.ailment;
    const newWorkflowServiceMinorAilment = useCreateThenRedirectToNewWorkflowServiceMinorAilment({
        workflowDetails: {
            requestAilment: key,
        },
    });
    const handleBookNow = () => {
        if (auth.isUserInRelationshipWithDependents) {
            history.push(routes.MinorAilmentFor.toPath({ ailment: key }));
        }
        else {
            newWorkflowServiceMinorAilment.createThenRedirect();
        }
    };
    return (_jsxs(PageContainer, { children: [_jsx(Nav
            //
            , { dataLeft: { leftItemType: "Back", linkTo: routes.ServiceList.toPath() }, dataRight: { rightItemType: "PhoneStartChat" } }, "Nav"), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(TypographyV2.WorkflowEditTitle, { children: translate("Are you or a loved one experiencing symptoms of {ailment}?", {
                                    ailment: ailment === null || ailment === void 0 ? void 0 : ailment.title,
                                }) }, void 0), _jsx(TypographyV2.WorkflowEditParagraph, { children: ailment === null || ailment === void 0 ? void 0 : ailment.description }, void 0), _jsx(RootBookNow, { children: "Book an assessment now." }, void 0), _jsx(RootSpacer, {}, void 0), _jsx(LoginButton, Object.assign({ type: "button", onClick: handleBookNow }, { children: _jsx(LoginButtonContentContainer, { children: _jsx(LoginButtonText, { children: "Book Now" }, void 0) }, void 0) }), void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
