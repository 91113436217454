import { createGlobalStyle } from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
export const CssGlobalStyle = createGlobalStyle `
	/**
	 * Many css variables will be placed here.
	 * In the future these values can be updated.
	 */
	:root {
		--nav-height: 56px;
		--footer-height: 100px;
		--color-text-primary: ${ColorBasic.Dark};

		// Snackbar styles
		--color-text-status-success: ${ColorBasic.StatusSuccess};
		--color-text-status-error: ${ColorBasic.StatusError};
		--color-text-status-warning: ${ColorBasic.StatusWarning};
		--color-text-status-info: ${ColorBasic.StatusInfo};
	}

	html {
		width: 100%;
		height: 100%;
		display: table;
	}

	body {
		width: 100%;
		display: table-cell;
		// Required as part of ensure no page scroll (to keep height 100%)
		position: relative;
	}

	html, body {
		margin: 0;
		padding: 0;
		// To prevent page scroll
		// overflow-x: hidden;
		width: 100%;
		height: 100%;
		margin: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	/** 
	 * NOTE: Any changes to the 'body' style should potentially 
	 * also be changed in index.ejs, so if the app crashes the 
	 * default body style is shown.
	 */
	body {
		color: var(--color-text-primary);

		font-family: 'Work Sans', Arial, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;

		word-break: break-word;

		margin: 0;
		/* background-position: top left;
		background-size: 2024px;
			background-image: url(/app/images/png/mednow-background.png);
		background-repeat: no-repeat; */
	}

	/* background: #eb01a5;
	// Image fallback
	background-image: url("IMAGE_URL");
	background-image: url("IMAGE_URL"), linear-gradient(#eb01a5, #d13531); */

	button {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		cursor: pointer;
		color: ${ColorBasic.Night50};
	}

	#root {
		min-height: 100%;
		height: 100%;
	}

	:focus-visible {
		outline: ${ColorBasic.Aqua60} auto 1px;
	}
`;
