import { jsx as _jsx } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { routes } from "@/src/routes";
import { useHistory } from "react-router";
import styled from "styled-components";
const NavChatHistoryButtonStyled = styled.button `
	justify-content: right;
	margin-right: 16px;
	color: ${ColorBasic.Aqua30};
	${Typography.ParagraphBaseMediumStyle}
`;
export const NavChatHistoryButton = () => {
    const history = useHistory();
    function handleChatHistoryClick() {
        history.push(routes.Chat.toPath({ isHistory: true }));
    }
    return (_jsx(NavChatHistoryButtonStyled, Object.assign({ onClick: handleChatHistoryClick, type: "button" }, { children: t("header_chatHistory") }), void 0));
};
