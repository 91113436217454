import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src//features/common";
import { ColorBasic } from "@/src/enums/colors";
const Root = styled.section `
	& {
		${Typography.ParagraphSmallRegularStyle};
		color: ${ColorBasic.Dark60};
		margin: 12px;
	}
`;
export const SummaryNote = (p) => {
    return _jsx(Root, { children: p.children }, void 0);
};
