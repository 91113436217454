import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
const Root = styled.div `
	& {
		color: ${ColorBasic.Dark60};
		cursor: pointer;
		display: block;
	}
	&:hover {
		text-decoration: underline;
	}
`;
/**
 * @deprecated TODO: reidenzon - Replace with ButtonStandard, ya?!
 */
export const ButtonText = (p) => {
    return _jsx(Root, Object.assign({ onClick: p.onClick }, { children: p.text }), void 0);
};
