import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Checkbox } from "@/src/features/common/FormInputs/Checkbox";
import { RxCard } from "@/src/modules/workflow/type/rx-refill-v3/components/draft/rx-list/RxCard";
import styled from "styled-components";
const Root = styled.div `
	& {
		border-bottom: 1px solid ${ColorBasic.Dark30};
		cursor: pointer;
		display: flex;
		flex-direction: row;
		gap: 12px;
		padding: 12px 0;
		text-align: left;
		width: 100%;
	}

	&:first-child {
		border-top: 1px solid ${ColorBasic.Dark30};
	}

	&.disabled {
		cursor: initial;
	}
`;
const RootIcon = styled.div `
	& {
		width: 24px;
	}
`;
export const FormikRxListItem = (p) => {
    const rx = p.rx;
    const isDisabled = !rx.isRefill;
    const isChecked = p.rxNumberList.includes(rx.rxNumber);
    const classList = [];
    if (isDisabled)
        classList.push("disabled");
    return (_jsxs(Root, Object.assign({ className: classList.join(" "), onClick: () => {
            if (!isDisabled) {
                p.onSelect(!isChecked);
            }
        } }, { children: [_jsx(RootIcon, { children: _jsx(Checkbox, { checked: isChecked, isDisabled: isDisabled }, void 0) }, void 0), _jsx(RxCard, { rx: rx }, void 0)] }), void 0));
};
