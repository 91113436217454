import { jsx as _jsx } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { InvoiceRow } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRow";
import styled from "styled-components";
const RootContent = styled.div `
	& {
		${Typography.ParagraphBaseRegularStyle};
		color: ${ColorBasic.Night50};
		text-transform: uppercase;
	}
`;
export const InvoiceRowTotal = (p) => {
    return (_jsx(InvoiceRow
    //
    , { "$background": ColorBasic.Aqua10, left: _jsx(RootContent, { children: "Order Total" }, void 0), right: _jsx(RootContent, { children: p.total }, void 0) }, void 0));
};
