import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from "formik";
import { resolveValue } from "@/src/utils/PathValue";
import styled from "styled-components";
import { Switch } from "@mui/material";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
/* --------------------------------- Styles --------------------------------- */
const NotificationSettingContainer = styled.div `
	display: ${(props) => (props.isFlex ? "flex" : "block")};
	align-items: ${(props) => (props.isFlex ? "center" : "stretch")};
	gap: ${(props) => (props.isFlex ? "22px" : "0px")};
`;
const SettingHeader = styled(Typography.Heading6Regular) `
	color: ${ColorBasic.Night50};
`;
const SettingHeaderContainer = styled.div `
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 4px;
`;
const SettingDescription = styled(Typography.ParagraphSmallRegular) `
	width: 271px;
	color: ${(props) => props.$color};
`;
// From Material UI docs
const IOSSwitch = styled((props) => _jsx(Switch, Object.assign({ focusVisibleClassName: ".Mui-focusVisible", disableRipple: true }, props), void 0))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "#1A7C94",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));
export const NotificationSetting = ({ isFlex, header, description, checked, onChange, color = ColorBasic.Night40 }) => {
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsxs(NotificationSettingContainer, Object.assign({ isFlex: isFlex }, { children: [_jsxs(SettingHeaderContainer, { children: [header && _jsx(SettingHeader, { children: header }, void 0), _jsx(IOSSwitch, { checked: checked, onChange: onChange }, void 0)] }, void 0), _jsx(SettingDescription, Object.assign({ "$color": color }, { children: description }), void 0)] }), void 0));
};
export const FormikNotificationSetting = ({ isFlex, header, description, formikId, color }) => {
    const { setFieldValue, values } = useFormikContext();
    function handleCheckboxChange(event) {
        const newValue = event.target.checked;
        setFieldValue(formikId, newValue);
    }
    return (_jsx(NotificationSetting, { checked: resolveValue(values, formikId), onChange: handleCheckboxChange, isFlex: isFlex, header: header, description: description, color: color }, void 0));
};
