import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV2 } from "@/src/common/style/StyleTypography";
import styled from "styled-components";
const Root = styled.header `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	&.margin {
		margin-bottom: 32px;
	}
`;
const RootDescription = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 1em;
	}
`;
/**
 * @deprecated TODO: reidenzon - Come up with a better name!
 */
export const LayoutContentHeader = (p) => {
    var _a;
    const isHeader = Boolean(p.title) || Boolean(p.description);
    const isMargin = (_a = p.isMargin) !== null && _a !== void 0 ? _a : true;
    if (!isHeader) {
        return null;
    }
    return (_jsxs(Root, Object.assign({ className: `${isMargin && "margin"}` }, { children: [p.title && _jsx(TypographyV2.WorkflowEditTitle, { children: p.title }, void 0), p.description && (_jsx(TypographyV2.WorkflowEditParagraph, { children: _jsx(RootDescription, { children: p.description }, void 0) }, void 0))] }), void 0));
};
