import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV2 } from "@/src/common/style/StyleTypography";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { AssistanceLink } from "@/src/features/common/AssistanceLink";
import { useRxReminderViewListQuery } from "@/src/modules/rx-reminder/ApiCoreRxReminder";
import { RefillReminder } from "@/src/modules/rx-reminder/layout/refill-reminders/RefillReminder";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const RefillReminderList = (p) => {
    var _a, _b;
    const patientGuid = p.patientGuid;
    const apiList = useRxReminderViewListQuery({ userForGuid: patientGuid });
    const reminderList1 = ((_b = (_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.list) !== null && _b !== void 0 ? _b : []);
    const reminderList2 = reminderList1.filter((item) => Boolean(item.reminder));
    const isEmpty = reminderList2.length == 0;
    return (_jsxs(Root, { children: [reminderList2.map((item) => {
                var _a, _b;
                return (_jsx(RefillReminder
                //
                , { item: item, onEdit: () => {
                        apiList.refetch();
                    } }, (_b = (_a = item.rx) === null || _a === void 0 ? void 0 : _a.rxNumber) !== null && _b !== void 0 ? _b : generateGuid()));
            }), isEmpty && (_jsxs(_Fragment, { children: [_jsx(TypographyV2.WorkflowEditParagraph, { children: "If you're missing a medication that you will need to be refilled, contact one of our pharmacists to add it to your profile." }, void 0), _jsx(AssistanceLink, {}, void 0)] }, void 0))] }, void 0));
};
