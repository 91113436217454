var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField as TextFieldMUI } from "@mui/material";
import styled from "styled-components";
import * as Typography from "@/src/features/common/Typography";
import { MarginPx } from "@/src/enums";
import { getIsOnKeyDownEventEnter } from "@/src/features/utils/eventHelpers";
import { ColorBasic } from "@/src/enums/colors";
const borderRadius = "8px";
const TextFieldMUIStyled = styled(TextFieldMUI) `
	&& {
		margin-top: ${MarginPx.FormElementMargin};
		margin-bottom: ${MarginPx.FormElementMargin};
	}

	&& .MuiInputBase-root {
		height: 48px;
		border-radius: ${borderRadius};
		background-color: ${ColorBasic.NeutralLight};
	}

	// Use gray background with grey border when:
	// The TextField is empty (i.e. the label has is NOT class .MuiFormLabel-filled)
	// AND the TextField is NOT focused (.Mui-focused)
	// AND the TextField is NOT in error state (.Mui-error)
	//
	&& :not(.MuiFormLabel-filled) + .MuiInputBase-root:not(.Mui-focused):not(.Mui-error) {
		background-color: ${ColorBasic.Dark10};
		color: ${ColorBasic.Dark60};

		&:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
			border-color: ${ColorBasic.Dark10};
		}
	}

	&& .MuiInputBase-root.Mui-focused:not(.Mui-error) {
		& .MuiOutlinedInput-notchedOutline {
			border-color: ${ColorBasic.Aqua60};
		}
	}

	&& .MuiInputBase-input {
		padding: 12px 16px;
		${Typography.ParagraphBaseRegularStyle}
		color: ${ColorBasic.Dark};
		border-radius: ${borderRadius};
	}

	&& .MuiInputLabel-root {
		${Typography.ParagraphBaseRegularStyle}
	}

	&& .MuiInputLabel-outlined {
		${Typography.ParagraphBaseRegularStyle}
		transform: translate(16px, 12px) scale(1);
	}

	&& .MuiInputLabel-outlined.Mui-focused:not(.Mui-error) {
		color: ${ColorBasic.Aqua60};
	}

	&& .MuiInputLabel-outlined.MuiInputLabel-shrink {
		${Typography.ParagraphSmallRegularStyle}
		padding: 2px 4px;
		background-color: ${ColorBasic.Light};
		border-radius: 8px;
		transform: translate(12px, -12px) scale(1);
	}

	&& .MuiFormHelperText-root {
		margin: 0 !important;
	}

	&& textarea {
		padding: 0 !important;
	}

	/**
	 * This fixes a strange issue where the value within ...<legend><span>....</span></legend>... has a width of 0.01px 
	 * due to material ui, but because of this the text (although it's visibility: hidden), actually pushes the page down
	 */
	&& .MuiOutlinedInput-notchedOutline legend > span {
		height: 10px;
		overflow-y: hidden;
	}
`;
export const TextField = (_a) => {
    var { isSubmitOnKeyPressEnter, onKeyPress } = _a, rest = __rest(_a, ["isSubmitOnKeyPressEnter", "onKeyPress"]);
    // If the `isSubmitOnKeyPressEnter` is true then pressing "Enter" will NOT
    // block the default event behaviour of (i.e. it will NOT have event.preventDefault).
    const handleOnKeyPress = (event) => {
        if (!isSubmitOnKeyPressEnter && getIsOnKeyDownEventEnter(event)) {
            event.preventDefault();
        }
        onKeyPress === null || onKeyPress === void 0 ? void 0 : onKeyPress(event);
    };
    return _jsx(TextFieldMUIStyled, Object.assign({ onKeyPress: handleOnKeyPress }, rest), void 0);
};
