import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import MFBButtonCancelModal from "@/src/features/MFB/MFBButtonCancelModal";
import { t } from "@/src/features/Localization";
const MFBGroupCardContainer = styled.div `
	background-color: ${ColorBasic.Aqua10};
	width: 327px;
	min-height: 79px;
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	border-radius: 7px;
	padding: 20px 24px;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	justify-content: center;
`;
const MFBGroupCardBody = styled.div `
	display: flex;
	align-items: center;
`;
const MFBGroupCardText = styled(Typography.ParagraphBaseMedium) `
	text-align: left;
	vertical-align: top;
	color: ${ColorBasic.Night40};
	margin-right: 24px;
	flex: 2;
`;
const RemoveLink = styled.button `
	${Typography.LinkSmallStyle};
	color: ${ColorBasic.Roseo70};
	text-decoration: underline;
	flex: 1;
`;
export const MFBGroupCard = ({ MFBGroup, handleRemoveGroup }) => {
    var _a;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const groupGuid = MFBGroup === null || MFBGroup === void 0 ? void 0 : MFBGroup.guid;
    const groupName = (_a = MFBGroup === null || MFBGroup === void 0 ? void 0 : MFBGroup.details) === null || _a === void 0 ? void 0 : _a.name;
    function handleModalOpen() {
        setIsModalOpen(true);
    }
    function handleModalClose() {
        setIsModalOpen(false);
    }
    function handleModalSubmit() {
        handleRemoveGroup(groupGuid);
        handleModalClose();
    }
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsxs(MFBGroupCardContainer, { children: [_jsxs(MFBGroupCardBody, { children: [_jsx(MFBGroupCardText, { children: groupName }, void 0), _jsx(RemoveLink, Object.assign({ onClick: handleModalOpen }, { children: t("profile_tab_insurance_mfb_card_remove") }), void 0)] }, void 0), _jsx(MFBButtonCancelModal, { isOpen: isModalOpen, handleSubmit: handleModalSubmit, handleClose: handleModalClose }, void 0)] }, void 0));
};
export default MFBGroupCard;
