import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { getUserDetailsNameFull } from "@/src/modules/user/type/UserDetails";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const TaxReportDownload = (p) => {
    const handleView = () => {
        window.open(p.url, "_blank");
    };
    return (_jsxs(Root, { children: [_jsx(LayoutContentHeader
            //
            , { 
                //
                title: `View the ${p.year} year-end tax receipt report for ${getUserDetailsNameFull(p.patientDetails)}` }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "View Report", onClick: handleView }, void 0)] }, void 0));
};
