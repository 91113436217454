import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
const borderRadius = "8px";
const StyledTextField = styled(TextField) `
	width: 100%;

	// In order for the DatePicker to be the same as Regular text fields
	// we will add margin-top and margin-bottom of 8px;
	margin-top: 8px;
	margin-bottom: 8px;

	&& .MuiInputBase-root {
		height: 48px;
		border-radius: ${borderRadius};
		background-color: ${ColorBasic.NeutralLight};
	}

	// Use gray background with grey border when:
	// The TextField is empty (i.e. the label has is NOT class .MuiFormLabel-filled)
	// AND the TextField is NOT focused (.Mui-focused)
	// AND the TextField is NOT in error state (.Mui-error)
	//
	&& :not(.MuiFormLabel-filled) + .MuiInputBase-root:not(.Mui-focused):not(.Mui-error) {
		background-color: ${ColorBasic.Dark10};
		color: ${ColorBasic.Dark60};

		&:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
			border-color: ${ColorBasic.Dark10};
		}
	}

	&& .MuiInputBase-root.Mui-focused:not(.Mui-error) {
		& .MuiOutlinedInput-notchedOutline {
			border-color: ${ColorBasic.Aqua60};
		}
	}

	&& .MuiInputBase-input {
		padding: 12px 16px;
		${Typography.ParagraphBaseRegularStyle}
		color: ${ColorBasic.Dark};
		border-radius: ${borderRadius};
	}

	&& .MuiInputLabel-root {
		${Typography.ParagraphBaseRegularStyle}
	}

	&& .MuiInputLabel-outlined {
		${Typography.ParagraphBaseRegularStyle}
		transform: translate(16px, 12px) scale(1);
	}

	&& .MuiInputLabel-outlined.Mui-focused:not(.Mui-error) {
		color: ${ColorBasic.Aqua60};
	}

	&& .MuiInputLabel-outlined.MuiInputLabel-shrink {
		${Typography.ParagraphSmallRegularStyle}
		padding: 2px 4px;
		background-color: ${ColorBasic.Light};
		border-radius: 8px;
		transform: translate(12px, -12px) scale(1);
	}

	&& .MuiFormHelperText-root {
		${Typography.ParagraphSmallRegularStyle}
	}

	// If there is an error with the date field selection
	&.Mui-error {
		& .MuiOutlinedInput-notchedOutline {
			border-color: ${ColorBasic.MUIFormFieldError};
		}

		& .MuiInputLabel-root {
			color: ${ColorBasic.MUIFormFieldError};
		}
	}
`;
function isValidDate(d) {
    return d && d instanceof Date && !isNaN(d === null || d === void 0 ? void 0 : d.getTime());
}
/**
 * Convert date string (e.g. "2022-01-30") to type Date
 */
export const convertDateStringToDate = (dateString) => {
    // If the value is not a valid date.
    if (!dateString || typeof dateString !== "string") {
        return null;
    }
    // The "YYYY-MM-DD" format does NOT work on Safari and iOS Chrome.
    // Use "YYYY/MM/DD" instead...
    // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
    const newDate = new Date(`${dateString.replace(/-/g, "/")} ${new Date().toTimeString()}`);
    if (newDate && isValidDate(newDate)) {
        return newDate;
    }
    return null;
};
/**
 * "date" argument can be a date string in the format YYYY-MM-DD (e.g. 2022-01-30)
 * or it can be a Date type, or undefined.
 *
 * And it will return a Date type or null.
 */
export function getFormatedSelectedDate(date) {
    // If the date is null or undefined, return that same value
    if (date === null || date === undefined) {
        return null;
    }
    // If the type is Date
    if (isValidDate(date)) {
        return date;
    }
    // If the type is "string", then attempt to format it.
    return convertDateStringToDate(date);
}
export const DatePicker = ({ setSelectedDate, selectedDate, label, className }) => {
    const handleChange = (newDate) => {
        setSelectedDate(newDate);
    };
    return (_jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDateFns }, { children: _jsx(Stack, Object.assign({ spacing: 3 }, { children: _jsx(DesktopDatePicker, { label: label, inputFormat: "yyyy-MM-dd", mask: "____-__-__", value: selectedDate, onChange: handleChange, renderInput: (params) => {
                    return _jsx(StyledTextField, Object.assign({}, params, { className: `${className || ""} ${(params === null || params === void 0 ? void 0 : params.className) || ""}` }), void 0);
                } }, void 0) }), void 0) }), void 0));
};
