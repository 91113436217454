import { jsx as _jsx } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import styled from "styled-components";
const DrawerHeaderContainer = styled.div `
	display: flex;
	justify-content: center;
`;
const Title = styled(Typography.Heading3Regular) `
	color: ${ColorBasic.Night50};
	vertical-align: top;
	letter-spacing: 1%;
	margin-top: 90px;
`;
export const MFBDrawerHeader = () => {
    return (_jsx(DrawerHeaderContainer, { children: _jsx(Title, { children: t("common_mfbInput_bottomSheet_header_add") }, void 0) }, void 0));
};
