import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
import { ChipSmall } from "@/src/features/common/Chip";
import { ColorBasic } from "@/src/enums/colors";
const Root = styled.div `
	& {
		align-items: center;
		color: ${ColorBasic.Blanc00};
		cursor: pointer;
		display: flex;
		//flex: 1;
		flex-direction: column;
		gap: 4px;
		height: 84px;
		position: relative;
		width: 84px;
	}
	&.active {
		color: #b4ecfa;
	}
`;
// We pass "$isCountGreaterThanNine" because if the number is
// greater than 9 it will consistently shoe "9+" therefore,
// that will require different margin-left styling.
const WorkflowCountText = styled(Typography.LinkSmall) `
	margin-top: 2px;
	margin-left: ${({ $isCountGreaterThanNine }) => ($isCountGreaterThanNine ? "2px" : "0px")};
	color: ${ColorBasic.Blanc00};
`;
const RootIcon = styled.div `
	& {
		height: 32px;
		margin-top: 12px;
		width: 32px;
	}
`;
const RootTitle = styled(Typography.ParagraphSmallHeavy) `
	& {
	}
	${Root}.active & {
	}
`;
const RootIndicator = styled.div `
	& {
		background-color: #b4ecfa;
		border-radius: 3px;
		height: 3px;
		width: 12px;
		opacity: 0;
	}
	${Root}.active & {
		opacity: 1;
	}
`;
export const FooterNavButton = (p) => {
    const classList = [];
    if (p.isActive) {
        classList.push("active");
    }
    const Icon = p.icon;
    const colorRest = "#fff";
    const colorActive = "#B4ECFA";
    return (_jsxs(Root, Object.assign({ className: classList.join(" "), onClick: p.onClick }, { children: [Boolean(p.count) && (_jsx(ChipSmall, Object.assign({ top: 8, right: 12 }, { children: _jsx(WorkflowCountText, Object.assign({ "$isCountGreaterThanNine": p.count > 9 }, { children: getCountFormatted(p.count) }), void 0) }), void 0)), _jsx(RootIcon, { children: _jsx(Icon, { size: 32, color: p.isActive ? colorActive : colorRest }, void 0) }, void 0), _jsx(RootTitle, { children: p.title }, void 0), p.isActive && _jsx(RootIndicator, {}, void 0)] }), void 0));
};
const getCountFormatted = (count) => {
    if (count) {
        if (count > 9) {
            return "9+";
        }
        return count.toString();
    }
    return;
};
