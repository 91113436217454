import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Check } from "@mui/icons-material";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: row;
		gap: 4px;
	}
`;
const RootIcon = styled.div `
	& {
	}
	& svg {
		color: ${ColorBasic.Aqua50};
		font-size: 18px;
	}
`;
const RootContent = styled.div `
	& {
	}
`;
const RootTitle = styled.div `
	& {
		${Typography.ParagraphSmallHeavyStyle};
		color: ${ColorBasic.Night50};
	}
`;
const RootDescription = styled(Typography.ParagraphSmallRegular) `
	& {
		color: ${ColorBasic.Dark70};
	}
`;
export const AdvisoryListItem = (p) => {
    const ins = p.instruction;
    return (_jsxs(Root, { children: [_jsx(RootIcon, { children: _jsx(Check, {}, void 0) }, void 0), _jsxs(RootContent, { children: [_jsx(RootTitle, { children: ins.title }, void 0), _jsx(RootDescription, { children: ins.description }, void 0)] }, void 0)] }, void 0));
};
