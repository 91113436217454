import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonIconLegacy } from "@/src/common/components/button/ButtonIconLegacy";
import { TypographyV2 } from "@/src/common/style/StyleTypography";
import { Icons } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.header `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
		text-align: center;
	}
`;
const RootToolbar = styled.div `
	& {
		display: flex;
		flex-direction: row;
		justify-content: end;
	}
`;
export const ModalHeader = (p) => {
    const isToolbar = Boolean(p.onClose);
    const isRender = Boolean(p.title) || isToolbar;
    if (!isRender) {
        return null;
    }
    return (_jsxs(Root, { children: [isToolbar && _jsx(RootToolbar, { children: p.onClose && _jsx(ButtonIconLegacy, { icon: _jsx(Icons.Clear, {}, void 0), onClick: p.onClose }, void 0) }, void 0), p.title && _jsx(TypographyV2.ModalTitle, { children: p.title }, void 0), p.description && _jsx(TypographyV2.ModalDescription, { children: p.description }, void 0)] }, void 0));
};
