import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonIconLegacy } from "@/src/common/components/button/ButtonIconLegacy";
import { HoursOperation } from "@/src/common/components/hours-operation/HoursOperation";
import { Spacer } from "@/src/common/components/layout/Spacer";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		background: #fff;
		border: 1px solid ${ColorBasic.Dark30};
		border-radius: 8px;
		padding: 12px 16px;
		margin: 12px 12px 0;
	}
`;
const RootHeader = styled.div `
	& {
		${Typography.ParagraphSmallMediumStyle};
		align-items: center;
		display: flex;
		gap: 8px;
		justify-content: space-between;
	}
`;
const RootHeaderLogo = styled.div `
	& {
		align-items: center;
		border: 1px solid ${ColorBasic.Aqua50};
		border-radius: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		height: 24px;
		width: 24px;
	}
`;
const RootHeaderContent = styled.div `
	& {
		color: ${ColorBasic.Dark80};
		display: flex;
		flex-wrap: wrap;
		gap: 1ch;
	}
`;
const RootHeaderToggle = styled.div `
	& {
	}

	& svg {
		color: ${ColorBasic.Aqua60};
	}
`;
const RootHeaderName = styled.span `
	& {
		color: ${ColorBasic.Aqua60};
	}
`;
const RootContent = styled.div `
	& {
		border-top: 1px solid ${ColorBasic.Dark30};
		display: none;
		flex-direction: column;
		gap: 16px;
		margin-top: 16px;
		padding-top: 16px;
	}

	${Root}.expanded & {
		display: flex;
	}
`;
export const HoursOperationDropdown = (p) => {
    var _a;
    const settingHours = getSettingDetailsByType(SettingType.GroupHoursOperation, p.settings);
    const [isExpanded, setIsExpanded] = useState((_a = p.isExpanded) !== null && _a !== void 0 ? _a : false);
    if (!settingHours) {
        return null;
    }
    const classList = [];
    if (isExpanded)
        classList.push("expanded");
    return (_jsx(PageWrapper, Object.assign({ isDisableVerticalPadding: true, style: { padding: 0 } }, { children: _jsxs(Root, Object.assign({ className: classList.join(" ") }, { children: [_jsxs(RootHeader, { children: [_jsx(RootHeaderLogo, { children: _jsx(Icons.MednowLogo, { size: 14 }, void 0) }, void 0), _jsxs(RootHeaderContent, { children: [_jsx("span", { children: "Pharmacy Location:" }, void 0), _jsx(RootHeaderName, { children: settingHours.name }, void 0)] }, void 0), _jsx(Spacer, {}, void 0), _jsxs(RootHeaderToggle, { children: [!isExpanded && _jsx(ButtonIconLegacy, { icon: _jsx(ExpandMore, {}, void 0), onClick: () => setIsExpanded(true) }, void 0), isExpanded && _jsx(ButtonIconLegacy, { icon: _jsx(ExpandLess, {}, void 0), onClick: () => setIsExpanded(false) }, void 0)] }, void 0)] }, void 0), _jsx(RootContent, { children: _jsx(HoursOperation, { settings: p.settings }, void 0) }, void 0)] }), void 0) }), void 0));
};
