import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from "@/src/features/common";
import styled from "styled-components";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { ColorBasic } from "@/src/enums/colors";
/* ----------------------------- Types and Enums ---------------------------- */
export var ListCTAItemLabelColorType;
(function (ListCTAItemLabelColorType) {
    ListCTAItemLabelColorType["Success"] = "Success";
    ListCTAItemLabelColorType["Warning"] = "Warning";
})(ListCTAItemLabelColorType || (ListCTAItemLabelColorType = {}));
/* ------------------------- Constants and Variables ------------------------ */
const colorsByLabelColorType = {
    [ListCTAItemLabelColorType.Success]: {
        color: ColorBasic.StatusSuccess,
        backgroundColor: ColorBasic.StatusBackgroundSuccess,
    },
    [ListCTAItemLabelColorType.Warning]: {
        color: ColorBasic.StatusWarning,
        backgroundColor: ColorBasic.StatusBackgroundWarning,
    },
};
/* ---------------------------- Helper Functions ---------------------------- */
function getColorStylesForLabelColorType({ $labelColorType }) {
    return colorsByLabelColorType[$labelColorType];
}
/* --------------------------------- Styles --------------------------------- */
const ListCTAItemLabelContainer = styled.div `
	${getColorStylesForLabelColorType}
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 8px;
	height: 22px;
	border-radius: 16px;
`;
const ListCTAItemLabelText = styled(Typography.ParagraphSmallMedium) ``;
export const ListCTAItemLabel = ({ text, labelColorType = ListCTAItemLabelColorType.Success, isLoading }) => {
    /* ---------------------------- Render Component ---------------------------- */
    if (!isLoading && text == undefined) {
        return null;
    }
    if (isLoading) {
        return _jsx(LoaderSpinner, { size: 22 }, void 0);
    }
    return (_jsx(ListCTAItemLabelContainer, Object.assign({ "$labelColorType": labelColorType }, { children: _jsx(ListCTAItemLabelText, { children: text }, void 0) }), void 0));
};
