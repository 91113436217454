var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { Button, Typography, Icons } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { t } from "@/src/features/Localization";
import { ROUTE_LOGIN_WITH_BASE_PATH } from "@/src/constants";
import { getVersionNumber } from "@/src/utils/BuildVersionHelpers";
import { sendErrorLogToSuiteApi } from "@/src/features/common/ErrorLogging/sendErrorLogToSuiteApi";
import { ErrorLevel } from "@/src/features/common/ErrorLogging/typesAndEnums";
import { Config } from "@/src/common/utility/config/Config";
const ErrorAppCrashPageContentContainer = styled.div `
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
`;
const HeaderText = styled(Typography.Heading3Regular) `
	color: ${ColorBasic.Night50};
	margin-top: 40px;
	margin-bottom: 40px;
`;
const FancyCircleErrorContainer = styled.div `
	width: 170px;
	height: 153px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;

	& svg {
		width: 100%;
		height: 100%;
	}
`;
const BodyText = styled(Typography.ParagraphBaseRegular) `
	color: ${ColorBasic.Dark80};
	white-space: pre-wrap;
	margin-bottom: 28px;
`;
function getNavigatorDetails() {
    return {
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        product: navigator.product,
        productSub: navigator.productSub,
        userAgent: navigator.userAgent,
        vendor: navigator.vendor,
        vendorSub: navigator.vendorSub,
    };
}
const ErrorAppCrashPageContentInitialPage = ({ handleSubmitReport, isSubmittingReport }) => {
    return (_jsxs(ErrorAppCrashPageContentContainer, { children: [_jsx(HeaderText, { children: t("appCrash_header1") }, void 0), _jsx(FancyCircleErrorContainer, { children: _jsx(Icons.FancyCircleError, {}, void 0) }, void 0), _jsx(BodyText, { children: t("appCrash_content1") }, void 0), _jsx(Button, Object.assign({ size: "large", variant: "round", onClick: handleSubmitReport, disabled: isSubmittingReport }, { children: t("appCrash_button_submitReport") }), void 0)] }, void 0));
};
const ErrorAppCrashPageContentSuccessPage = () => {
    return (_jsxs(ErrorAppCrashPageContentContainer, { children: [_jsx(HeaderText, { children: t("appCrash_header2") }, void 0), _jsx(FancyCircleErrorContainer, { children: _jsx(Icons.FancyCircleSuccess, {}, void 0) }, void 0), _jsx(BodyText, { children: t("appCrash_content2") }, void 0), _jsx(Button, Object.assign({ size: "large", variant: "round", onClick: () => {
                    location.href = ROUTE_LOGIN_WITH_BASE_PATH;
                } }, { children: t("appCrash_button_goToLogin") }), void 0)] }, void 0));
};
export const ErrorAppCrashPageContent = ({ 
// error,
// errorInfo,
errorDetails, }) => {
    const [isDidSuccessfullySubmitReport, setIsDidSuccessfullySubmitReport] = useState(false);
    const [isSubmittingReport, setIsSubmitting] = useState(false);
    function handleSubmitReport() {
        return __awaiter(this, void 0, void 0, function* () {
            const details = {
                // error,
                // errorInfo,
                errorDetails,
                navigatorDetails: getNavigatorDetails(),
                version: getVersionNumber(),
                buildId: Config.GitLabCommit,
                source: "web",
                locationHref: location.href,
            };
            setIsSubmitting(true);
            yield sendErrorLogToSuiteApi({
                level: ErrorLevel.Warning,
                details: JSON.stringify(details),
            });
            setIsDidSuccessfullySubmitReport(true);
            setIsSubmitting(false);
        });
    }
    if (isDidSuccessfullySubmitReport) {
        return _jsx(ErrorAppCrashPageContentSuccessPage, {}, void 0);
    }
    return _jsx(ErrorAppCrashPageContentInitialPage, { handleSubmitReport: handleSubmitReport, isSubmittingReport: isSubmittingReport }, void 0);
};
