import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { DotStepper } from "@/src/common/components/dot-stepper/DotStepper";
import { ButtonText } from "@/src/common/components/button/ButtonText";
const Root = styled.header `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;
export const SlideshowHeader = (p) => {
    return (_jsxs(Root, { children: [p.isBack && _jsx(ButtonText, { onClick: p.onBack, text: "Back" }, void 0), _jsx("div", {}, void 0), _jsx(DotStepper, { onStep: (s) => p.onStep(s), step: p.step, steps: p.steps }, void 0)] }, void 0));
};
