import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
import { t } from "@/src/features/Localization";
import { Typography } from "@/src/features/common";
const DrawerHeaderContainer = styled.div `
	margin-top: 47px;
	display: flex;
`;
const Title = styled(Typography.Heading4Regular) `
	color: ${ColorBasic.Night50};
	vertical-align: top;
	letter-spacing: 1%;
	text-align: left;
`;
export const ProfileWalletCardDrawerHeader = () => {
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(DrawerHeaderContainer, { children: _jsx(Title, { children: t("profile_tab_wallet_addPaymentMethod_bottomSheet_header") }, void 0) }, void 0));
};
export default ProfileWalletCardDrawerHeader;
