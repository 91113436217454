import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import styled from "styled-components";
import { PatientAvatar } from "@/src/features/Patient/PatientAvatar";
import { getUserDetailsNameFull } from "@/src/modules/user/type/UserDetails";
const Root = styled.div `
	align-items: center;
	display: flex;
	gap: 8px;
`;
const RootName = styled(Typography.ParagraphBaseMedium) `
	color: ${ColorBasic.Night50};
	flex: 1;
`;
const RootLabel = styled(Typography.ParagraphSmallMedium) `
	color: ${ColorBasic.Night50};
`;
export const FormikRxListHeader = (p) => {
    const color = p.patient.details.color;
    return (_jsxs(Root, { children: [_jsx(PatientAvatar, { color: color }, void 0), _jsx(RootName, { children: getUserDetailsNameFull(p.patient.details) }, void 0), _jsx(RootLabel, { children: t("common_refills_count_header") }, void 0)] }, void 0));
};
