import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldStyle } from "@/src/common/components/field/FieldStyle";
import { FormikField } from "@/src/common/components/formik/FormikField";
import { ColorBasic } from "@/src/enums/colors";
import { useFormikContext } from "formik";
import styled from "styled-components";
const RootContent = styled.div `
	& {
		align-items: stretch;
		display: flex;
		flex: 1;
		flex-direction: column;
		//gap: 4px;
	}
`;
const RootLabel = styled.label `
	& {
		align-items: center;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		gap: 4px;
	}

	${FieldStyle.Rest};

	& {
		border-color: transparent;
		color: ${ColorBasic.Night60};
		font-size: 0.8em;
	}

	&:focus,
	&:hover {
		${FieldStyle.Active};
		border-color: transparent;
	}

	&:focus,
	&:hover {
		border-color: transparent;
	}
`;
const RootInput = styled.input `
	& {
		width: 18px;
	}
`;
const RootSpan = styled.span `
	& {
		flex: 1;
	}
`;
/** @deprecated TODO: reidenzon - Add support for option.content as [ReactNode] type. */
export const FormikRadio = (p) => {
    const formik = useFormikContext();
    const meta = formik.getFieldMeta(p.name);
    return (_jsx(FormikField, Object.assign({ label: p.label, description: p.description, name: p.name }, { children: _jsx(RootContent, { children: p.options.map((o) => {
                // TODO: reidenzon - Do NOT use <select><option> tag, use <div> instead.
                return (_jsxs(RootLabel, { children: [_jsx(RootInput
                        //
                        , { 
                            //
                            checked: o.value == meta.value, disabled: p.isDisabled, name: p.name, onBlur: formik.handleBlur, onChange: formik.handleChange, type: "radio", value: o.value }, void 0), _jsx(RootSpan, { children: o.caption }, void 0)] }, o.value));
            }) }, void 0) }), void 0));
};
