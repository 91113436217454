import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Icons } from "@/src/features/common";
export const CenterItemContainer = styled.div `
	display: flex;
	align-items: center;
	margin: 12px;
	& svg {
		display: block;
	}
`;
export const NavCenterItem = ({ centerItem }) => {
    if (centerItem) {
        return _jsx(_Fragment, { children: centerItem }, void 0);
    }
    return (_jsx(CenterItemContainer, { children: _jsx(Icons.MednowLogo, { size: 32 }, void 0) }, void 0));
};
