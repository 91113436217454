import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ButtonLink, Icons, Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { t } from "@/src/features/Localization";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { routes } from "@/src/routes";
const HomeFooterContainer = styled.div `
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	margin-top: auto;
	padding: 24px 0;
`;
const ChatWithOurPharmacyButtonLink = styled(ButtonLink) `
	width: 100%;

	&:hover svg path {
		stroke: ${ColorBasic.ActionPrimaryHover};
	}
`;
const DispensingFeeFAQLink = styled(Link) `
	${Typography.LinkSmallStyle}
	color: ${ColorBasic.Dark60};
`;
export const HomeFooter = () => {
    const { segmentTrackEvent } = useSegmentAnalytics();
    function handleOnChatClick() {
        segmentTrackEvent("select_chat", {
            entrypoint: SegmentEntryPoint.HomePage,
        });
    }
    function handleOnFAQClick() {
        segmentTrackEvent("select_faq", {
            entrypoint: SegmentEntryPoint.HomePage,
        });
    }
    return (_jsxs(HomeFooterContainer, { children: [_jsxs(ChatWithOurPharmacyButtonLink, Object.assign({ onClick: handleOnChatClick, to: routes.Chat.toPath(), variant: "ghostRounded" }, { children: [_jsx(Icons.ChatSmall, { color: ColorBasic.ActionPrimaryActive, size: 16, style: { marginRight: "10px" } }, void 0), t("home_footer_button_chat")] }), void 0), _jsx(DispensingFeeFAQLink, Object.assign({ onClick: handleOnFAQClick, to: routes.Faq.toPath({ key: "dispensing-fee" }) }, { children: t("home_footer_dispense") }), void 0)] }, void 0));
};
