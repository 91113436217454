import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Elevations } from "@/src/enums/elevations";
import { Typography } from "@/src/features/common";
import { ErrorMessage } from "@/src/features/common/ErrorMessage";
import { TextField } from "@/src/features/common/TextField";
import MUIAutocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";
const borderRadius = "8px";
export const Root = styled(FormControl) `
	width: 100%;

	&& .MuiInputBase-root {
		border-radius: ${borderRadius};
	}

	.MuiInputBase-input {
		border-radius: ${borderRadius};
		height: 23px;
		margin-left: 12px;
		margin-right: 32px;
	}

	&& .MuiOutlinedInput-root {
		padding: 0;
	}

	&& .MuiSelect-select {
		flex: 1;
		${Typography.ParagraphBaseRegularStyle}
	}

	.MuiFormLabel-root {
		${Typography.ParagraphBaseRegularStyle}
		transform: translate(14px, 12px) scale(1);
		color: rgba(0, 0, 0, 0.6);

		&.MuiInputLabel-shrink {
			${Typography.ParagraphSmallRegularStyle}
			padding: 2px 4px;
			border-radius: 8px;
			transform: translate(12px, -12px) scale(1);
		}
	}

	& .MuiOutlinedInput-notchedOutline legend {
		${Typography.ParagraphSmallRegularStyle}
	}

	.MuiListItemIcon-root {
		display: none;
	}

	.Mui-focused {
		&.MuiInputLabel-shrink {
			color: ${ColorBasic.Aqua60};
		}

		& .MuiOutlinedInput-notchedOutline {
			border-color: ${ColorBasic.Aqua60};
		}
	}

	&& .MuiTypography-root {
		${Typography.ParagraphBaseMediumStyle}
		color: ${ColorBasic.Night50};
	}

	&& .MuiAutocomplete-option {
		${Typography.ParagraphBaseRegularStyle}
	}
`;
const RootInput = styled(MUIAutocomplete) `
	&& .MuiPaper-root {
		box-shadow: ${Elevations.Tight1};
		border-radius: 7px;
	}
`;
const TextFieldStyled = styled(TextField) ``;
export const Autocomplete = (p) => {
    const optionLabelKey = p.optionLabelKey;
    const optionValueKey = p.optionValueKey;
    const defaultValue = p.defaultValue;
    const helperText = p.helperText;
    function isOptionEqualToValue(option) {
        return option.id === defaultValue;
    }
    return (_jsx(Root, Object.assign({ className: p.className }, { children: _jsx(RootInput, { freeSolo: true, value: p.value || "", defaultValue: defaultValue, isOptionEqualToValue: isOptionEqualToValue, options: p.options, renderInput: (params) => (_jsx(TextFieldStyled, Object.assign({ placeholder: p.placeholder, helperText: helperText && _jsx(ErrorMessage, { error: helperText }, void 0) }, params, { label: p.label }), void 0)), disablePortal: true, onInputChange: p.onChange, onChange: p.onItemSelection, placeholder: p.placeholder, disableClearable: true, filterOptions: (options, state) => options, forcePopupIcon: true, getOptionLabel: (option) => { var _a; return (_a = option[optionLabelKey]) !== null && _a !== void 0 ? _a : option; }, renderOption: (props, option) => {
                return (_createElement("li", Object.assign({}, props, { key: option[optionValueKey] }), option[optionLabelKey]));
            }, ListboxProps: { "data-cy": "autocomplete-dropdown" } }, void 0) }), void 0));
};
