import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Form, Icons, Typography } from "@/src/features/common";
import { AssistanceLink } from "@/src/features/common/AssistanceLink";
import { TextField } from "@/src/features/common/TextField";
import { t } from "@/src/features/Localization";
import { useState } from "react";
import styled from "styled-components";
const DrawerContent = styled.div `
	display: flex;
	justify-content: center;
	margin-top: 72px;
`;
const TextfieldContainer = styled.div `
	margin-bottom: 40px;
`;
const MFBSubmitButton = styled.button `
	background: ${ColorBasic.Aqua60};
	color: ${ColorBasic.Blanc00};
	position: relative;

	display: block;

	padding: 12px;
	border-radius: 32px;
	overflow: hidden;

	width: 244px;
	margin-left: auto;
	margin-right: auto;
`;
const MFBSubmitButtonContentContainer = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;
const MFBSubmitButtonText = styled(Typography.ParagraphBaseRegular) `
	text-align: center;
`;
const IconContainer = styled.div `
	padding-top: 6px;
`;
export const MFBDrawerContent = ({ handleSubmit, getGroupInstitutionIsLoading }) => {
    const [code, setCode] = useState("");
    return (_jsx(DrawerContent, { children: _jsxs(Form, Object.assign({ onSubmit: handleSubmit }, { children: [_jsx(TextfieldContainer, { children: _jsx(TextField, { autoFocus: true, fullWidth: true, label: t("common_mfbInput_bottomSheet_inputLabel_groupCode"), value: code, onChange: (e) => setCode(e.target.value) }, void 0) }, void 0), _jsx(MFBSubmitButton, Object.assign({ disabled: getGroupInstitutionIsLoading, type: "submit" }, { children: _jsxs(MFBSubmitButtonContentContainer, { children: [_jsx(MFBSubmitButtonText, { children: t("common_mfbInput_bottomSheet_button_submit") }, void 0), _jsx(IconContainer, { children: _jsx(Icons.Business, { size: 18, fill: ColorBasic.Blanc00 }, void 0) }, void 0)] }, void 0) }), void 0), _jsx(AssistanceLink, {}, void 0)] }), void 0) }, void 0));
};
