import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as Typography from "@/src/features/common/Typography";
import { t } from "@/src/features/Localization";
import { ColorBasic } from "@/src/enums/colors";
const BoxStyled = styled(Box) `
	position: absolute;
	box-sizing: border-box;
	width: 327px;
	min-height: 240px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${ColorBasic.Light};
	border-radius: 7px;
	padding: 48px 40px 36px 40px;
`;
const TitleContainer = styled.div ``;
const Title = styled(Typography.Heading6SemiBold) `
	color: #ff7477;
	text-align: center;
`;
const InformationTextContainer = styled.div `
	margin-top: 24px;
`;
const InformationText = styled(Typography.ParagraphBaseMedium) `
	color: ${ColorBasic.Night50};
	text-align: center;
`;
const ButtonContainer = styled.div `
	display: flex;
	margin-top: 38px;
	justify-content: center;
	gap: 24px;
`;
const ButtonText = styled.button `
	${Typography.ButtonMediumStyle}
	color: ${(props) => (props.primary ? "#FF7477" : ColorBasic.Night50)};
	text-decoration: underline;
`;
/**
 * @deprecated TODO: reidenzon - Replace with useWorkflowModalCancel instead!
 */
export const ConfirmDialog = (p) => {
    var _a;
    return (_jsx("div", { children: _jsx(Modal, Object.assign({ open: p.isOpen, onClose: p.handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsxs(BoxStyled, { children: [_jsx(TitleContainer, { children: _jsx(Title, { children: p.titleText }, void 0) }, void 0), _jsx(InformationTextContainer, { children: _jsx(InformationText, { children: p.informationText }, void 0) }, void 0), _jsxs(ButtonContainer, { children: [_jsx(ButtonText, Object.assign({ type: "button", onClick: p.handleClose }, { children: (_a = p.buttonTextCancel) !== null && _a !== void 0 ? _a : t("signUp_dialog_cancel_button_no") }), void 0), _jsx(ButtonText, Object.assign({ type: "button", onClick: p.handleSubmit, primary: true }, { children: p.buttonTextConfirm }), void 0)] }, void 0)] }, void 0) }), void 0) }, void 0));
};
