import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Button, Typography } from "@/src/features/common";
import mednowAssistance from "@/src/public/app/images/png/mednow_assistance.png";
import { routes } from "@/src/routes";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 38px;
`;
const RootText = styled(Typography.ParagraphBaseRegular) `
	color: ${ColorBasic.Aqua70};
	margin-top: 10px;
	margin-bottom: 14px;
	padding: 0 32px;
	text-align: center;
`;
const RootImage = styled.img `
	width: 95px;
	height: 71px;
`;
export const AssistanceLink = (p) => {
    var _a;
    const history = useHistory();
    function handleChatWithUs() {
        history.push(routes.Chat.toPath());
    }
    return (_jsxs(Root, { children: [_jsx(RootImage, { src: mednowAssistance }, void 0), _jsx(RootText, { children: (_a = p.title) !== null && _a !== void 0 ? _a : "Need assistance?" }, void 0), _jsx(Button, Object.assign({ size: "small", variant: "round", onClick: handleChatWithUs }, { children: "Chat with Us" }), void 0)] }, void 0));
};
