/* eslint-disable max-len */
import styled, { css } from "styled-components";
import { ColorBasic } from "@/src/enums/colors";
/**
 * IMPORTANT NOTE:
 * If the contents of the svg contains a "mask" id, then we need to convert that to
 * react component, located in: "@/src/features/common/Media/SVGs/".
 * E.g.: @/src/features/common/Media/SVGs/SvgArrowBack
 *
 * The reason for this is if the svg is used more than once on a page, the Safari
 * browser has a bug where it loses the mask if one of those repeated svgs is removed.
 * And the svg then looks like a square (i.e. loses mask)
 *
 * E.g. see the id "mask0" in:
 *
 * <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
 *		<mask id="mask0" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-3" y="-3" width="24" height="24">
 *			<path d="M16 10H10V16H8V10H2V8H8V2H10V8H16V10Z" fill="white"/>
 *		</mask>
 *		<g mask="url(#mask0)">
 *			<rect width="18" height="18" fill="white"/>
 *		</g>
 *	</svg>
 *
 *
 * NOTE: When converting to svg you will need to change some attributes.
 * E.g. from kebab case to camel case:
 *  <path fill-rule="evenodd" ... > to <path fillRule="evenodd" ... >
 * Or
 * <mask ... style="mask-type:alpha" > to <mask ... style={{ maskType: "alpha" }} >
 *
 */
import SVGAccordian from "@/src/public/app/images/svg/accordian.svg";
import SVGActiveIndicator from "@/src/public/app/images/svg/active-indicator.svg";
// import SVGAdd from "@/src/public/app/images/svg/add.svg";
import SVGAdd from "@/src/features/common/Media/SVGs/SvgAdd";
import SVGAddProfile from "@/src/public/app/images/svg/add-profile.svg";
import SVGArrowBack from "@/src/features/common/Media/SVGs/SvgArrowBack";
import SVGArrowCircle from "@/src/features/common/Media/SVGs/SvgArrowCircle";
// import SVGArrowBack from "@/src/public/app/images/svg/arrow-back.svg";
import SVGArrowDropDown from "@/src/features/common/Media/SVGs/SvgArrowDropDown";
import SVGArrowForward from "@/src/public/app/images/svg/arrow-forward.svg";
import SVGArrowForwardRound from "@/src/public/app/images/svg/arrow-right-default.svg";
import SvgArrowPointingBottomLeft from "@/src/features/common/Media/SVGs/SvgArrowPointingBottomLeft";
import SVGArrowUpRight from "@/src/public/app/images/svg/arrow-up-right.svg";
import SVGArrowUpRightContainer from "@/src/public/app/images/svg/arrow-up-right-container.svg";
import SVGAvatarPatientProfile from "@/src/public/app/images/svg/avatar-patient.svg";
import SVGBag from "@/src/public/app/images/svg/bag.svg";
import SVGBusiness from "@/src/public/app/images/svg/business.svg";
import SVGButtonPower from "@/src/public/app/images/svg/button-power.svg";
import SVGCamera from "@/src/features/common/Media/SVGs/SvgCamera";
import SVGCancel from "@/src/public/app/images/svg/cancel.svg";
import SVGCancelProfile from "@/src/public/app/images/svg/cancel-profile.svg";
// import SVGCancelRounded from "@/src/public/app/images/svg/cancel-rounded.svg";
import SVGCancelRounded from "@/src/features/common/Media/SVGs/SvgCancelRounded";
import SVGChat from "@/src/public/app/images/svg/chat.svg";
import SVGChatActive from "@/src/public/app/images/svg/chat-active.svg";
import SVGChatActiveMessageIndicator from "@/src/public/app/images/svg/chat-active-message-indicator.svg";
import SVGChatBody from "@/src/public/app/images/svg/chat-body.svg";
import SVGChatHistoryUnavailable from "@/src/public/app/images/svg/chat-history-unavailable.svg";
import SVGChatPendingMessages from "@/src/public/app/images/svg/chat-pending-messages.svg";
import SVGChatSmall from "@/src/public/app/images/svg/chat-small.svg";
import SVGChatTriage from "@/src/public/app/images/svg/chat-triage.svg";
// import SVGCheck from "@/src/public/app/images/svg/check.svg";
import SVGCheck from "@/src/features/common/Media/SVGs/SvgCheck";
import SVGCheckRound from "@/src/features/common/Media/SVGs/SvgCheckRound";
import SVGChevron from "@/src/public/app/images/svg/chevron.svg";
// import SVGChevronRight from "@/src/public/app/images/svg/chevron-right.svg";
import SVGChevronDownRound from "@/src/features/common/Media/SVGs/SvgChevronDownRound";
import SVGChevronRight from "@/src/features/common/Media/SVGs/SvgChevronRight";
import SVGChevronRightAqua from "@/src/public/app/images/svg/chevron-right-aqua.svg";
// import SVGCheckRounded from "@/src/public/app/images/svg/check-rounded.svg";
import SVGCheckRounded from "@/src/features/common/Media/SVGs/SvgCheckRounded";
// import SVGClear from "@/src/public/app/images/svg/clear.svg";
import SVGClear from "@/src/features/common/Media/SVGs/SvgClear";
import SVGConnector from "@/src/public/app/images/svg/connector.svg";
import SVGDoctor from "@/src/public/app/images/svg/doctor.svg";
import SVGDownloadApple from "@/src/public/app/images/svg/download-apple.svg";
import SVGDownloadAppleButton from "@/src/public/app/images/svg/store-buttons/apple-store-button.svg";
import SVGDownloadGoogle from "@/src/public/app/images/svg/download-google.svg";
import SVGDownloadGoogleButton from "@/src/public/app/images/svg/store-buttons/google-play-button.svg";
// import SVGEdit from "@/src/public/app/images/svg/edit.svg";
import SVGEdit from "@/src/features/common/Media/SVGs/SvgEdit";
import SVGEmailSentSuccess from "@/src/public/app/images/svg/email-sent-success.svg";
import SVGExternalLink from "@/src/public/app/images/svg/external-link.svg";
import SVGFancyCircleError from "@/src/public/app/images/svg/fancy-circle-error.svg";
import SVGFancyCircleSuccess from "@/src/public/app/images/svg/fancy-circle-success.svg";
import SVGFooterHome from "@/src/public/app/images/svg/footer/home.svg";
import SVGFooterOrders from "@/src/public/app/images/svg/footer/orders.svg";
import SVGFooterProfile from "@/src/public/app/images/svg/footer/profile.svg";
import SVGFooterServices from "@/src/public/app/images/svg/footer/services.svg";
import SVGGlobe from "@/src/public/app/images/svg/globe.svg";
import SVGGoodbye from "@/src/public/app/images/svg/goodbye.svg";
import SVGHome from "@/src/public/app/images/svg/home.svg";
import SVGHomeActive from "@/src/public/app/images/svg/home-active.svg";
import SVGHyperlinkExpired from "@/src/public/app/images/svg/hyperlink-expired.svg";
import SVGImageUpload from "@/src/public/app/images/svg/image-upload.svg";
import SVGLockedOutline from "@/src/public/app/images/svg/lock-outline.svg";
import SVGMedicationHistoryUnavailable from "@/src/public/app/images/svg/medication-history-unavailable.svg";
import SVGMednowLogoWithName from "@/src/public/app/images/svg/mednow-logo-with-name.svg";
import SVGMednowLogoWithWhiteName from "@/src/public/app/images/svg/mednow-logo-with-white-name.svg";
import SVGMednowLogo from "@/src/public/app/images/svg/mednow-logo.svg";
// import SVGMoreVert from "@/src/public/app/images/svg/more-vert.svg";
import SVGMoreVert from "@/src/features/common/Media/SVGs/SvgMoreVert";
import SVGOrders from "@/src/public/app/images/svg/orders-home.svg";
import SVGOrdersActive from "@/src/public/app/images/svg/orders-home-active.svg";
import SVGOrdersNone from "@/src/public/app/images/svg/orders-none.svg";
import SVGPaymentSuccess from "@/src/public/app/images/svg/payment-success.svg";
import SVGPeople from "@/src/public/app/images/svg/people.svg";
import SVGPerson from "@/src/public/app/images/svg/person-icon.svg";
import SVGPhone from "@/src/public/app/images/svg/phone.svg";
import SVGPill from "@/src/public/app/images/svg/pill.svg";
import SvgPrescription from "@/src/features/common/Media/SVGs/SvgPrescription";
import SVGPrescriptionNotSubmitted from "@/src/public/app/images/svg/prescription-not-submitted.svg";
import SVGProfile from "@/src/public/app/images/svg/profile-icon.svg";
import SVGProfileHome from "@/src/public/app/images/svg/profile-home.svg";
import SVGProfileHomeActive from "@/src/public/app/images/svg/profile-home-active.svg";
import SVGRefillUnavailable from "@/src/public/app/images/svg/refill-unavailable.svg";
import SVGRefillOrders from "@/src/public/app/images/svg/refill-orders.svg";
import SVGRefillNotAvailable from "@/src/public/app/images/svg/refill-not-available.svg";
import SVGRefillPrescriptionsHome from "@/src/public/app/images/svg/refill-prescriptions-home.svg";
import SVGRefreshSmall from "@/src/public/app/images/svg/refresh-small.svg";
import SVGRxHome from "@/src/public/app/images/svg/rx-home.svg";
import SVGRxOrders from "@/src/public/app/images/svg/rx-orders.svg";
import SVGRxPrescriptionsHome from "@/src/public/app/images/svg/rx-prescriptions-home.svg";
// import SVGSave from "@/src/public/app/images/svg/save.svg";
import SVGSave from "@/src/features/common/Media/SVGs/SvgSave";
// import SVGSaveAlt from "@/src/public/app/images/svg/save-alt.svg";
import SVGSaveAlt from "@/src/features/common/Media/SVGs/SvgSaveAlt";
// import SVGSearch from "@/src/public/app/images/svg/search.svg";
import SVGSearch from "@/src/features/common/Media/SVGs/SvgSearch";
import SVGSendEmail from "@/src/public/app/images/svg/send-email.svg";
import SVGSSOLogoApple from "@/src/public/app/images/svg/sso-logo-apple.svg";
import SVGSSOLogoGoogle from "@/src/public/app/images/svg/sso-logo-google.svg";
import SVGStepperItemChecked from "@/src/public/app/images/svg/step-item-checked.svg";
import SVGStepperItemFilled from "@/src/public/app/images/svg/step-item-filled.svg";
import SVGStepperItemUnfilled from "@/src/public/app/images/svg/step-item-unfilled.svg";
// import SVGTrash from "@/src/public/app/images/svg/trash.svg";
import SVGTransferOrders from "@/src/public/app/images/svg/transfer-orders.svg";
import SVGTrash from "@/src/features/common/Media/SVGs/SvgTrash";
import SVGVisibility from "@/src/public/app/images/svg/visibility_black_24dp.svg";
import SVGVisibilityOff from "@/src/public/app/images/svg/visibility_off_black_24dp.svg";
import SVGVolumeOn from "@/src/public/app/images/svg/volume-on-2.svg";
import SVGVolumeOff from "@/src/public/app/images/svg/volume-off-2.svg";
import SVGWalletNoCard from "@/src/public/app/images/svg/wallet-no-card.svg";
import SVGWalletNoOrderPayment from "@/src/public/app/images/svg/wallet-no-order-payment.svg";
import SVGWalletAmericanExpress from "@/src/public/app/images/svg/american_express_card.svg";
import SVGWalletDinersClub from "@/src/public/app/images/svg/diners_club_card.svg";
import SVGWalletDiscover from "@/src/public/app/images/svg/discover_card.svg";
import SVGWalletJCB from "@/src/public/app/images/svg/jcb_card.svg";
import SVGWalletMasterCard from "@/src/public/app/images/svg/mastercard_card.svg";
import SVGWalletUnionPay from "@/src/public/app/images/svg/union_pay_card.svg";
import SVGWalletVisa from "@/src/public/app/images/svg/visa_card.svg";
import SVGWalletInterac from "@/src/public/app/images/svg/interac_card.svg";
// It will take "size" or "width" and "height"
const addSize = ({ size, width = 24, height = 24 }) => {
    const iconWidth = size !== null && size !== void 0 ? size : width;
    const iconHeight = size !== null && size !== void 0 ? size : height;
    return `
		width: ${iconWidth}px;
		height: ${iconHeight}px;
`;
};
const addColorFillToCircle = ({ color }) => color
    ? css `
				& circle {
					fill: ${color};
				}
		  `
    : "";
const addColorFillToPath = ({ color }) => color
    ? css `
				& path {
					fill: ${color};
				}
		  `
    : "";
const addColorFillToRect = ({ color }) => color
    ? css `
				& rect {
					fill: ${color};
				}
		  `
    : "";
const addColorStrokeToPath = ({ color }) => color
    ? css `
				& path {
					stroke: ${color};
				}
		  `
    : "";
export const Accordian = styled(SVGAccordian) `
	${addSize}
	${addColorFillToPath}
`;
export const ActiveIndicator = styled(SVGActiveIndicator) `
	${addSize}
	${addColorFillToPath}
`;
export const Add = styled(SVGAdd) `
	${addSize}
	${addColorFillToRect}
`;
export const AddProfile = styled(SVGAddProfile) `
	${addSize}
	${addColorFillToRect}
`;
export const ArrowBack = styled(SVGArrowBack) `
	${addSize}
	${addColorFillToRect}
`;
export const ArrowCircle = styled(SVGArrowCircle) `
	${addSize}
	${addColorFillToRect}
`;
export const ArrowDropDown = styled(SVGArrowDropDown) `
	${addSize}
	${addColorFillToCircle}
`;
export const ArrowForward = styled(SVGArrowForward) `
	${addSize}
	${addColorFillToPath}
`;
export const ArrowForwardRound = styled(SVGArrowForwardRound) `
	${addSize}
	${addColorStrokeToPath}
`;
export const ArrowPointingBottomLeft = styled(SvgArrowPointingBottomLeft) `
	${addSize}
	${addColorFillToRect}
`;
export const ArrowUpRight = styled(SVGArrowUpRight) `
	${addSize}
	${addColorFillToPath}
`;
export const ArrowUpRightContainer = styled(SVGArrowUpRightContainer) `
	${addSize}
	${addColorFillToPath}
`;
export const AvatarPatientProfile = styled(SVGAvatarPatientProfile) `
	${addSize}
	${addColorFillToCircle}
`;
export const Bag = styled(SVGBag) `
	${addSize}
	${addColorFillToPath}
`;
export const Business = styled(SVGBusiness) `
	${addSize}
	${addColorFillToPath}
`;
export const ButtonPower = styled(SVGButtonPower) `
	${addSize}
	${addColorFillToPath}
`;
export const Camera = styled(SVGCamera) `
	${addSize}
	${addColorFillToPath}
`;
export const Cancel = styled(SVGCancel) `
	${addSize}
	${addColorFillToPath}
`;
export const CancelProfile = styled(SVGCancelProfile) `
	${addSize}
	${addColorFillToPath}
`;
export const CancelRounded = styled(SVGCancelRounded) `
	${addSize}
	${addColorFillToRect}
`;
export const Chat = styled(SVGChat) `
	${addSize}
	${addColorStrokeToPath}
`;
export const ChatActive = styled(SVGChatActive) `
	${addSize}
	${addColorFillToPath}
`;
export const ChatActiveMessageIndicator = styled(SVGChatActiveMessageIndicator) `
	${addSize}
	${addColorFillToPath}
`;
export const ChatBody = styled(SVGChatBody) `
	${addSize}
	${addColorFillToPath}
`;
export const ChatHistoryUnavailable = styled(SVGChatHistoryUnavailable) `
	${addSize}
	${addColorFillToPath}
`;
export const ChatPendingMessages = styled(SVGChatPendingMessages) `
	${addSize}
	${addColorFillToPath}
`;
export const ChatSmall = styled(SVGChatSmall) `
	${addSize}
	${addColorStrokeToPath}
`;
export const ChatTriage = styled(SVGChatTriage) `
	${addSize}
	${addColorFillToPath}
`;
export const Check = styled(SVGCheck) `
	${addSize}
	${addColorFillToRect}
`;
export const CheckRound = styled(SVGCheckRound) `
	${addSize}
	${addColorFillToRect}
`;
export const Chevron = styled(SVGChevron) `
	${addSize}
	${addColorFillToPath}
`;
export const ChevronDownRound = styled(SVGChevronDownRound) `
	${addSize}
	${addColorFillToRect}
`;
export const ChevronRight = styled(SVGChevronRight) `
	${addSize}
	${addColorFillToRect}
`;
export const ChevronRightAqua = styled(SVGChevronRightAqua) `
	${addSize}
	${addColorFillToPath}
`;
export const CheckRounded = styled(SVGCheckRounded) `
	${addSize}
	${addColorFillToRect}
`;
export const Clear = styled(SVGClear) `
	${addSize}
	${addColorFillToPath}
`;
export const Connector = styled(SVGConnector) `
	${addSize}
	${addColorFillToRect}
`;
export const Doctor = styled(SVGDoctor) `
	${addSize}
	${addColorFillToPath}
`;
export const DownloadApple = styled(SVGDownloadApple) `
	${addColorFillToPath}
`;
export const DownloadAppleButton = styled(SVGDownloadAppleButton) `
	${addColorFillToPath}
`;
export const DownloadGoogle = styled(SVGDownloadGoogle) `
	${addColorFillToPath}
`;
export const DownloadGoogleButton = styled(SVGDownloadGoogleButton) `
	${addColorFillToPath}
`;
export const Edit = styled(SVGEdit) `
	${addSize}
`;
export const EmailSentSuccess = styled(SVGEmailSentSuccess) `
	${addSize}
	${addColorFillToPath}
`;
export const ExternalLink = styled(SVGExternalLink) `
	${addSize}
	${addColorFillToPath}
`;
export const FancyCircleError = styled(SVGFancyCircleError) `
	${addSize}
	${addColorFillToPath}
`;
export const FancyCircleSuccess = styled(SVGFancyCircleSuccess) `
	${addSize}
	${addColorFillToPath}
`;
export const FooterHome = styled(SVGFooterHome) `
	${addSize}
	${addColorFillToPath}
`;
export const FooterOrders = styled(SVGFooterOrders) `
	${addSize}
	${addColorFillToPath}
`;
export const FooterProfile = styled(SVGFooterProfile) `
	${addSize}
	${addColorFillToPath}
`;
export const FooterServices = styled(SVGFooterServices) `
	${addSize}
	${addColorFillToPath}
`;
export const Globe = styled(SVGGlobe) `
	${addSize}
	${addColorFillToPath}
`;
export const Goodbye = styled(SVGGoodbye) `
	${addSize}
	${addColorFillToPath}
`;
export const Home = styled(SVGHome) `
	${addSize}
	${addColorFillToPath}
`;
export const HomeActive = styled(SVGHomeActive) `
	${addSize}
	${addColorFillToPath}
`;
export const HyperlinkExpired = styled(SVGHyperlinkExpired) `
	${addSize}
	${addColorFillToPath}
`;
export const ImageUpload = styled(SVGImageUpload) `
	${addSize}
	${addColorFillToPath}
`;
export const LockedOutline = styled(SVGLockedOutline) `
	${addSize}
	${addColorFillToPath}
`;
export const MedicationHistoryUnavailable = styled(SVGMedicationHistoryUnavailable) `
	${addSize}
	${addColorFillToPath}
`;
export const MednowLogoWithName = styled(SVGMednowLogoWithName) `
	${addSize}
	${addColorFillToPath}
`;
export const MednowLogoWithWhiteName = styled(SVGMednowLogoWithWhiteName) `
	${addSize}
	${addColorFillToPath}
`;
export const MednowLogo = styled(SVGMednowLogo) `
	${addSize}
	${addColorFillToPath}
`;
export const MoreVert = styled(SVGMoreVert) `
	${addSize}
	${addColorFillToPath}
`;
export const Orders = styled(SVGOrders) `
	${addSize}
	${addColorFillToPath}
`;
export const OrdersActive = styled(SVGOrdersActive) `
	${addSize}
	${addColorFillToPath}
`;
export const OrdersNone = styled(SVGOrdersNone) `
	${addSize}
	${addColorFillToPath}
`;
export const PaymentSuccess = styled(SVGPaymentSuccess) `
	${addSize}
	${addColorFillToPath}
`;
export const People = styled(SVGPeople) `
	${addSize}
	${addColorFillToPath}
`;
export const Person = styled(SVGPerson) `
	${addSize}
	${addColorFillToPath}
`;
export const Phone = styled(SVGPhone) `
	${addSize}
	${addColorFillToPath}
`;
export const Pill = styled(SVGPill) `
	${addSize}
	${addColorFillToPath}
`;
export const Prescription = styled(SvgPrescription) `
	${addSize}
	${addColorFillToPath}
`;
export const PrescriptionNotSubmitted = styled(SVGPrescriptionNotSubmitted) `
	${addSize}
	${addColorFillToPath}
`;
export const Profile = styled(SVGProfile) `
	${addSize}
	${addColorFillToPath}
`;
export const ProfileHome = styled(SVGProfileHome) `
	${addSize}
	${addColorFillToPath}
`;
export const ProfileHomeActive = styled(SVGProfileHomeActive) `
	${addSize}
	${addColorFillToPath}
`;
export const RefillUnavailable = styled(SVGRefillUnavailable) `
	${addSize}
	${addColorFillToPath}
`;
export const RefillOrders = styled(SVGRefillOrders) `
	${addSize}
	${addColorFillToPath}
`;
export const RefillNotAvailable = styled(SVGRefillNotAvailable) `
	${addSize}
	${addColorFillToPath}
`;
export const RefillPrescriptionsHome = styled(SVGRefillPrescriptionsHome) `
	${addSize}
	${addColorFillToPath}
`;
export const RefreshSmall = styled(SVGRefreshSmall) `
	${addSize}
	${addColorFillToPath}
`;
export const RxHome = styled(SVGRxHome) `
	${addSize}
	${addColorFillToPath}
`;
export const RxOrders = styled(SVGRxOrders) `
	${addSize}
	${addColorFillToPath}
`;
export const RxPrescriptionsHome = styled(SVGRxPrescriptionsHome) `
	${addSize}
	${addColorFillToPath}
`;
export const Save = styled(SVGSave) `
	${addSize}
	${addColorFillToPath}
`;
export const SaveAlt = styled(SVGSaveAlt) `
	${addSize}
	${addColorFillToPath}
`;
export const Search = styled(SVGSearch) `
	${addSize}
	${addColorFillToPath}
`;
export const SendEmail = styled(SVGSendEmail) `
	${addSize}
	${addColorFillToPath}
`;
export const SSOLogoApple = styled(SVGSSOLogoApple) `
	${addSize}
	${addColorFillToPath}
`;
export const SSOLogoGoogle = styled(SVGSSOLogoGoogle) `
	${addSize}
	${addColorFillToPath}
`;
export const StepItemCheck = styled(SVGStepperItemChecked) `
	${addSize}
	${addColorFillToPath}
`;
export const StepItemFilled = styled(SVGStepperItemFilled) `
	${addSize}
	${addColorFillToPath}
`;
export const StepItemFilledDark = styled(SVGStepperItemFilled) `
	${addSize}
	${addColorFillToPath}
	& rect:first-child {
		fill: ${ColorBasic.Aqua60};
	}
	& circle {
		fill: ${ColorBasic.NeutralLight};
	}
`;
export const StepItemUnFilled = styled(SVGStepperItemUnfilled) `
	${addSize}
	${addColorFillToPath}
`;
export const TransferOrders = styled(SVGTransferOrders) `
	${addSize}
	${addColorFillToRect}
`;
export const Trash = styled(SVGTrash) `
	${addSize}
	${addColorFillToRect}
`;
export const Visibility = styled(SVGVisibility) `
	${addSize}
	${addColorFillToPath}
`;
export const VisibilityOff = styled(SVGVisibilityOff) `
	${addSize}
	${addColorFillToPath}
`;
export const VolumeOff = styled(SVGVolumeOff) `
	${addSize}
	${addColorFillToPath}
`;
export const VolumeOn = styled(SVGVolumeOn) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletNoCard = styled(SVGWalletNoCard) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletNoOrderPayment = styled(SVGWalletNoOrderPayment) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletAmericanExpress = styled(SVGWalletAmericanExpress) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletDinersClub = styled(SVGWalletDinersClub) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletDiscover = styled(SVGWalletDiscover) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletJCB = styled(SVGWalletJCB) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletMasterCard = styled(SVGWalletMasterCard) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletUnionPay = styled(SVGWalletUnionPay) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletVisa = styled(SVGWalletVisa) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletInterac = styled(SVGWalletInterac) `
	${addSize}
	${addColorFillToPath}
`;
