import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Nav } from "@/src/features/Nav/Nav";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { Button, Icons, Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { ColorBasic } from "@/src/enums/colors";
import { useHyperlinkResolveSingleSendAgainMutation, useHyperlinkViewSingleQuery } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
const ResendHyperlinknContentContainer = styled.div `
	margin-top: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const Title = styled(Typography.Heading3Regular) `
	text-align: center;
	color: ${ColorBasic.Night30};
	margin-bottom: 30px;
`;
const SVGEmailExpiredContainer = styled.div `
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
`;
export const ResendHyperlinkPage = () => {
    var _a;
    const history = useHistory();
    const { query } = useQuery();
    const hyperlinkToken = (query === null || query === void 0 ? void 0 : query.get("token")) || "";
    const apiView = useHyperlinkViewSingleQuery(hyperlinkToken, { skip: !hyperlinkToken });
    const hyperlink = (_a = apiView.data) === null || _a === void 0 ? void 0 : _a.data.hyperlink;
    const [apiSendAgain, apiSendAgainState] = useHyperlinkResolveSingleSendAgainMutation();
    const handleClick = () => {
        apiSendAgain({
            token: hyperlinkToken,
        }).then(() => {
            history.push(routes.ResendHyperlinkSuccess.toPath({
                type: hyperlink === null || hyperlink === void 0 ? void 0 : hyperlink.type,
            }));
        });
    };
    // TODO - There may be cases where resend link throws an error
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Login",
                    linkTo: routes.Login.toPath(),
                } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(ResendHyperlinknContentContainer, { children: [_jsx(Title, { children: t("linkExpired_header") }, void 0), _jsx(SVGEmailExpiredContainer, { children: _jsx(Icons.HyperlinkExpired, { width: 187, height: 102 }, void 0) }, void 0), _jsx(Button, Object.assign({ disabled: apiSendAgainState.isLoading, endIcon: _jsx(Icons.RefreshSmall, { color: ColorBasic.Blanc00, size: 12 }, void 0), onClick: handleClick, size: "medium", variant: "round" }, { children: t("linkExpired_button_resendLink") }), void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
