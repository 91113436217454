import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AssistanceLink } from "@/src/features/common/AssistanceLink";
import { LoaderSpinnerCentered } from "@/src/features/common/LoaderSpinnerCentered";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { FAQList } from "@/src/features/Faq/FaqList";
import { t } from "@/src/features/Localization";
import { Nav } from "@/src/features/Nav/Nav";
import { useFaqViewListQuery } from "@/src/modules/faq/redux/ApiCoreFaq";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const FAQPageContainer = styled.div `
	width: 315px;
	display: flex;
	flex-direction: column;
	margin: 25px auto 0;
`;
const FAQListContainer = styled.div ``;
export const FAQ = () => {
    var _a;
    // TODO: reidenzon - Finish me!
    const apiList = useFaqViewListQuery({});
    const { query } = useQuery();
    const history = useHistory();
    const FAQSelectedItem = (query === null || query === void 0 ? void 0 : query.get("key")) || "";
    const list = (((_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.list) || []);
    function onItemSelected(k) {
        const url = k === FAQSelectedItem ? "/faq" : `/faq?key=${k}`;
        history.replace(url);
    }
    function handleGoBack() {
        history.push(routes.Home.toPath());
    }
    const faqList = apiList.isFetching ? (_jsx(LoaderSpinnerCentered, {}, void 0)) : (_jsxs(_Fragment, { children: [_jsx(LayoutContentHeader, { title: t("faq_header") }, void 0), list.length > 0 && (_jsx(FAQListContainer, { children: _jsx(FAQList, { items: list, onItemSelected: onItemSelected, selectedItem: FAQSelectedItem }, void 0) }, void 0)), _jsx(AssistanceLink, {}, void 0)] }, void 0));
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    onClick: handleGoBack,
                    leftItemType: "Back",
                }, dataRight: {
                    rightItemType: "PhoneStartChat",
                } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, Object.assign({ isLargeVerticalPadding: true }, { children: _jsx(FAQPageContainer, { children: faqList }, void 0) }), void 0) }, void 0)] }, void 0));
};
