import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable prefer-const */
import { usePhoneValidator } from "@/src/common/utility/phone/usePhoneValidator";
import { ColorBasic, Colors } from "@/src/enums/colors";
import { FormId } from "@/src/enums/forms";
import { Form, Icons, Typography } from "@/src/features/common";
import { FormikTextFieldMaskedPhoneNumber } from "@/src/features/common/FormikTextFieldMaskedPhoneNumber";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { t } from "@/src/features/Localization";
import FormikErrorFocus from "@/src/features/utils/Formik/FormikErrorFocus";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
/* --------------------------------- Styles --------------------------------- */
const RegisterContainer = styled.div `
	width: 500px;
	max-width: 100%;
`;
const RegisterPersonalInfoButton = styled.button `
	background: ${ColorBasic.Aqua60};
	color: ${ColorBasic.Blanc00};
	position: relative;

	display: block;

	padding: 12px 85px;
	border-radius: 32px;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	margin-top: 33px;
`;
const RegisterPersonalInfoButtonText = styled(Typography.ButtonLarge) ``;
const RegisterPersonalInfoButtonContentContainer = styled.div `
	display: flex;
	justify-content: center;
	gap: 8px;
	align-items: center;
`;
const StyledLinks = styled.a `
	color: ${Colors.ActionSecondaryDefault};
	text-decoration: underline;
`;
const FormikTextFieldMaskStyled = styled(FormikTextFieldMaskedPhoneNumber) `
	width: 100%;
`;
export const RegisterPersonalInfoForm = (p) => {
    const phoneValidator = usePhoneValidator({});
    const validationSchema = Yup.object().shape({
        nameFirst: Yup.string()
            .min(1, t("common_nameInput_error_invalidMinLength"))
            .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
            .required(t("common_nameInput_error_invalidMinLength")),
        nameLast: Yup.string()
            .min(1, t("common_nameInput_error_invalidMinLength"))
            .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
            .required(t("common_nameInput_error_invalidMinLength")),
        phone: phoneValidator.schema,
        isCheckbox: Yup.boolean().oneOf([true], "Required"),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: p.state,
        validateOnBlur: true,
        validationSchema: validationSchema,
        onSubmit: (s) => {
            p.onSubmit(s);
        },
    });
    // /* -------------------------------- Variables ------------------------------- */
    const privacyPolicy = (_jsx(StyledLinks, Object.assign({ href: "https://mednow.ca/pages/privacy-policy", target: "_blank", rel: "noreferrer" }, { children: t("signUp_email_step2_agreementOne_privacyPolicy") }), void 0));
    const termsOfService = (_jsx(StyledLinks, Object.assign({ href: "https://mednow.ca/pages/terms-of-use", target: "_blank", rel: "noreferrer" }, { children: t("signUp_email_step2_agreementOne_termsOfService") }), void 0));
    const legalText = t("signUp_email_step2_agreementOne", {
        privacyPolicy,
        termsOfService,
    });
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(RegisterContainer, { children: _jsx(FormikProvider, Object.assign({ value: formik }, { children: _jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.RegisterPersonalInfo }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "nameFirst", label: t("signUp_email_step2_inputLabel_firstName") }, void 0), _jsx(FormikTextField, { fullWidth: true, formikId: "nameLast", label: t("signUp_email_step2_inputLabel_lastName") }, void 0), _jsx("div", Object.assign({ "data-cy": "register-phone-number" }, { children: _jsx(FormikTextFieldMaskStyled, { formikId: "phone", label: t("signUp_email_step2_inputLabel_phone") }, void 0) }), void 0), _jsx("div", Object.assign({ "data-cy": "privacy-policy-checkbox", style: { marginTop: "20px" } }, { children: _jsx(FormikCheckbox, { formikId: "isCheckbox", label: legalText, isSecondary: true }, void 0) }), void 0), _jsx(RegisterPersonalInfoButton, Object.assign({ type: "submit", form: FormId.RegisterPersonalInfo }, { children: _jsxs(RegisterPersonalInfoButtonContentContainer, { children: [_jsx(RegisterPersonalInfoButtonText, { children: t("common_button_signUp") }, void 0), _jsx(Icons.Person, { color: ColorBasic.Blanc00 }, void 0)] }, void 0) }), void 0), _jsx(FormikErrorFocus, {}, void 0)] }), void 0) }), void 0) }, void 0));
};
