import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { ListCTAItemLabel, ListCTAItemLabelColorType } from "@/src/features/common/ListCTA/ListCTAItemLabel";
import { t } from "@/src/features/Localization";
import { PatientAvatar } from "@/src/features/Patient/PatientAvatar";
import { doesSettingHaveKrollIntegration } from "@/src/features/User/userHelpers";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { useUserRxCountRefillQuery, useUserRxReviewQuery } from "@/src/redux/apiServices/suiteApi";
import styled from "styled-components";
const ProfileDependentCardButton = styled.button `
	& {
		background: #fff;
		border: 1px solid ${ColorBasic.Night10};
		border-radius: 3px;
		cursor: pointer;
		min-height: 64px;
		padding: 12px;
		width: 350px;
	}

	&.disabled {
		cursor: initial;
		opacity: 0.5;
	}
`;
const ProfileDependentCardButtonAdd = styled.button `
	& {
		border: 1px solid ${ColorBasic.Night10};
		border-radius: 3px;
		cursor: pointer;
		min-height: 64px;
		padding: 12px;
		width: 350px;
	}

	&.disabled {
		cursor: initial;
		opacity: 0.5;
	}
`;
const ProfileDependentCardContentContainer = styled.div `
	align-items: center;
	display: flex;
	gap: 12px;
`;
const ProfileIconContainer = styled.div ``;
const CopyContainer = styled.div `
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	width: 210px;
	text-align: left;
`;
const Name = styled(Typography.ParagraphSmallHeavy) `
	color: ${ColorBasic.Night50};
`;
const RelationshipLabel = styled(Typography.ParagraphSmallMedium) `
	color: ${ColorBasic.Dark60};
`;
const RightIconContainer = styled.div `
	padding-top: 6px;
`;
export const PatientSelectCard = (p) => {
    var _a, _b;
    const isUserKroll = doesSettingHaveKrollIntegration(p.userSettings);
    const isGroupServiceClinical = getSettingDetailsByType(SettingType.GroupServiceClinical, p.userSettings).isEnabled;
    const isGroupMedicationReview = getSettingDetailsByType(SettingType.GroupMedicationReview, p.userSettings).isEnabled;
    const isGroupMedVisit = getSettingDetailsByType(SettingType.GroupMedVisit, p.userSettings).isEnabled;
    const isMedVisit = p.isMedVisit && isGroupMedVisit;
    const isRxCount = p.isRxCount && isUserKroll;
    const isRxReview = p.isRxReview && isGroupMedicationReview;
    const isServiceClinical = p.isServiceClinical && isGroupServiceClinical;
    const apiRxCount = useUserRxCountRefillQuery({
        userForGuid: p.userGuid,
        isAddDependents: false,
    }, { skip: !isRxCount });
    const apiRxReview = useUserRxReviewQuery({
        userForGuid: p.userGuid,
        isAddDependents: false,
    }, { skip: !isRxReview });
    const rxCount = ((_a = apiRxCount.data) === null || _a === void 0 ? void 0 : _a.data.count) || 0;
    const rxReview = Boolean((_b = apiRxReview.data) === null || _b === void 0 ? void 0 : _b.data.isAvailable);
    let isEnabled = true;
    let className = "";
    let isDisabled = false;
    if ((p.isRxReview && !rxReview) || (p.isServiceClinical && !isServiceClinical) || (p.isMedVisit && !isMedVisit)) {
        isDisabled = true;
    }
    if (isDisabled) {
        isEnabled = false;
        className = "disabled";
    }
    return (_jsx(ProfileDependentCardButton, Object.assign({ className: className, onClick: () => isEnabled &&
            p.handleClick({
                userGuid: p.userGuid,
                userDetails: p.userDetails,
                userSettings: p.userSettings,
            }) }, { children: _jsxs(ProfileDependentCardContentContainer, { children: [_jsx(ProfileIconContainer, { children: _jsx(PatientAvatar, { color: p.userDetails.color }, void 0) }, void 0), _jsxs(CopyContainer, { children: [_jsx(Name, { children: `${p.userDetails.nameFirst} ${p.userDetails.nameLast}` }, void 0), _jsx(RelationshipLabel, { children: p.label }, void 0)] }, void 0), isRxCount && (_jsx(ListCTAItemLabel, { text: rxCount.toString(), labelColorType: rxCount > 0 ? ListCTAItemLabelColorType.Success : ListCTAItemLabelColorType.Warning, isLoading: apiRxCount.isLoading }, void 0)), _jsx(RightIconContainer, { children: _jsx(Icons.ChevronRight, { color: ColorBasic.Dark50, size: 18 }, void 0) }, void 0)] }, void 0) }), void 0));
};
export const PatientSelectListCardAdd = ({ handleClick }) => {
    return (_jsx(ProfileDependentCardButtonAdd, Object.assign({ onClick: handleClick }, { children: _jsxs(ProfileDependentCardContentContainer, { children: [_jsx(ProfileIconContainer, { children: _jsx(Icons.AddProfile, { size: 42 }, void 0) }, void 0), _jsx(CopyContainer, { children: _jsx(Name, { children: t("patientProfiles_addPatient_button") }, void 0) }, void 0), _jsx(RightIconContainer, { children: _jsx(Icons.Add, { color: ColorBasic.Dark50, size: 18 }, void 0) }, void 0)] }, void 0) }), void 0));
};
