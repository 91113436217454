import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useHistory } from "react-router";
import SVGHyperlinkResolved from "@/src/public/app/images/svg/hyperlink-resolved.svg";
import { Nav } from "@/src/features/Nav/Nav";
import { Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { ColorBasic } from "@/src/enums/colors";
import { routes } from "@/src/routes";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
const LinkResolvedPageContainer = styled.div `
	margin-top: 117px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const Title = styled(Typography.Heading3Regular) `
	text-align: center;
	vertical-align: top;
	letter-spacing: 1%;
	color: ${ColorBasic.Night50};
	margin-bottom: 40px;
	width: 235px;
`;
const SVGEmailExpiredContainer = styled.div `
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
`;
const LinkResolvedContentText = styled(Typography.ParagraphBaseRegular) `
	text-align: center;
	letter-spacing: 1%;
	color: ${ColorBasic.Dark80};
`;
const GoHomeButton = styled.button `
	background: ${ColorBasic.Aqua60};
	color: ${ColorBasic.Blanc00};
	position: relative;

	display: block;

	border: 2px solid #5a7887;
	border-radius: 32px;
	overflow: hidden;

	height: 52px;
	width: 170px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 48px;
`;
const GoHomeButtonContentContainer = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
`;
const GoHomeButtonText = styled(Typography.ButtonLarge) `
	text-align: center;
	letter-spacing: 1%;
`;
export const LinkResolvedPage = () => {
    const history = useHistory();
    /* ------------------------------ Action Events ----------------------------- */
    const handleClick = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        history.push(routes.Login.toPath());
    };
    /* ---------------------------- Render Component ---------------------------- */
    // TODO - There may be cases where resend link throws an error
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Login",
                    linkTo: routes.Login.toPath(),
                } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(LinkResolvedPageContainer, { children: [_jsx(Title, { children: t("linkResolved_header") }, void 0), _jsx(SVGEmailExpiredContainer, { children: _jsx(SVGHyperlinkResolved, {}, void 0) }, void 0), _jsx(LinkResolvedContentText, { children: t("linkResolved_content") }, void 0), _jsx(GoHomeButton, Object.assign({ type: "submit", onClick: handleClick }, { children: _jsx(GoHomeButtonContentContainer, { children: _jsx(GoHomeButtonText, { children: t("linkResolved_button_goToLogin") }, void 0) }, void 0) }), void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
