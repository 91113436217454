import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { useAuth } from "@/src/hooks/useAuth";
import { FeatureCard } from "@/src/features/FeatureCards/FeatureCard";
import { HomeFeatureCardTransfer } from "@/src/features/Home/HomeFeatureCards/HomeFeatureCardTransfer";
import { HomeFeatureCardRefill } from "@/src/features/Home/HomeFeatureCards/HomeFeatureCardRefill";
import { HomeFeatureCardNoRx } from "@/src/features/Home/HomeFeatureCards/HomeFeatureCardNoRx";
import { HomeFeatureCardMedicationReview } from "@/src/features/Home/HomeFeatureCards/HomeFeatureCardMedicationReview";
const HomeFeatureCardsContainer = styled.div `
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
export const HomeFeatureCards = (p) => {
    var _a, _b;
    const { user } = useAuth();
    const isCardTransfer = Boolean((_a = user === null || user === void 0 ? void 0 : user.metaFlags) === null || _a === void 0 ? void 0 : _a.isCardTransfer);
    const isCardMedicationReview = Boolean((_b = user === null || user === void 0 ? void 0 : user.metaFlags) === null || _b === void 0 ? void 0 : _b.isCardMedicationReview);
    let card;
    if (isCardMedicationReview && p.rxReviewIsFetching) {
        card = _jsx(FeatureCard, { isLoading: true }, void 0);
    }
    else if (isCardMedicationReview && p.rxReviewIsHas) {
        card = _jsx(HomeFeatureCardMedicationReview, {}, void 0);
    }
    else if (isCardTransfer) {
        card = _jsx(HomeFeatureCardTransfer, {}, void 0);
    }
    else if (p.rxCountRefillIsFetching) {
        card = _jsx(FeatureCard, { isLoading: true }, void 0);
    }
    else if (p.rxCountRefillIsHas) {
        card = _jsx(HomeFeatureCardRefill, {}, void 0);
    }
    else {
        card = _jsx(HomeFeatureCardNoRx, {}, void 0);
    }
    return _jsx(HomeFeatureCardsContainer, { children: card }, void 0);
};
