import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { getNotificationCategoryInfo, NotificationCategory } from "@/src/modules/notification/type/NotificationCategory";
import { NotificationMethod } from "@/src/modules/notification/type/NotificationMethod";
import { IOSSwitch } from "@/src/common/components/switch/Switch";
import { useUserEditMutation } from "@/src/modules/user/redux/ApiCoreUser";
import { useAuth } from "@/src/hooks/useAuth";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		gap: 4px;
	}
`;
export const NotificationToggle = (p) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const auth = useAuth();
    const user = auth.user;
    const nGlobal = (_a = p.notifications[NotificationCategory.Global]) !== null && _a !== void 0 ? _a : {};
    const nCategory = (_b = p.notifications[p.category]) !== null && _b !== void 0 ? _b : {};
    const infoGlobal = getNotificationCategoryInfo(NotificationCategory.Global);
    const infoCategory = getNotificationCategoryInfo(p.category);
    const [apiEdit, apiEditState] = useUserEditMutation();
    const isGlobalRelevant = infoGlobal.MethodList.includes(p.method) && p.category != NotificationCategory.Global;
    const isMethodRelevant = infoCategory.MethodList.includes(p.method);
    if (!isMethodRelevant) {
        return null;
    }
    let isGlobalMethod = false;
    let isCategoryMethod = false;
    if (p.method == NotificationMethod.Email) {
        isGlobalMethod = (_c = nGlobal.isEmail) !== null && _c !== void 0 ? _c : false;
        isCategoryMethod = (_d = nCategory.isEmail) !== null && _d !== void 0 ? _d : false;
    }
    if (p.method == NotificationMethod.Push) {
        isGlobalMethod = (_e = nGlobal === null || nGlobal === void 0 ? void 0 : nGlobal.isPush) !== null && _e !== void 0 ? _e : false;
        isCategoryMethod = (_f = nCategory.isPush) !== null && _f !== void 0 ? _f : false;
    }
    if (p.method == NotificationMethod.Sms) {
        isGlobalMethod = (_g = nGlobal.isSms) !== null && _g !== void 0 ? _g : false;
        isCategoryMethod = (_h = nCategory.isSms) !== null && _h !== void 0 ? _h : false;
    }
    const isDisabled = isGlobalRelevant && !isGlobalMethod;
    let isMethod = isCategoryMethod;
    if (isDisabled) {
        isMethod = false;
    }
    const handleChange = (e) => {
        const isChecked = e.target.checked;
        const notification = {};
        if (p.method == NotificationMethod.Email) {
            notification.isEmail = isChecked;
        }
        if (p.method == NotificationMethod.Push) {
            notification.isPush = isChecked;
        }
        if (p.method == NotificationMethod.Sms) {
            notification.isSms = isChecked;
        }
        apiEdit({
            guid: user.guid,
            preferences: {
                notifications: {
                    [p.category]: notification,
                },
            },
        })
            .unwrap()
            .then(() => {
            auth.fetchCurrentUser();
        });
    };
    return (_jsxs(Root, { children: [_jsx("small", { children: p.method }, void 0), _jsx(IOSSwitch
            //
            , { 
                //
                checked: isMethod, disabled: isDisabled, onChange: handleChange }, void 0)] }, void 0));
};
