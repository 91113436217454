import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { AutoLogoutWarning } from "@/src/features/AutoLogout/AutoLogoutWarning";
import { OnboardingSlideshow } from "@/src/modules/onboarding/components/OnboardingSlideshow";
const MainLayoutContainer = styled.div `
	width: 100%;
	height: 100%;
	background-position: top center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	margin: 0 auto;
`;
const PageContent = styled.div `
	position: relative;
	height: 100%;
`;
export const MainLayout = ({ children }) => {
    const { pathname } = useLocation();
    return (_jsxs(MainLayoutContainer, Object.assign({ id: pathname, "$isBlueBackground": true }, { children: [_jsx(AutoLogoutWarning, {}, void 0), _jsx(OnboardingSlideshow, {}, void 0), _jsx(PageContent, Object.assign({ className: "PageContent" }, { children: children }), void 0)] }), void 0));
};
