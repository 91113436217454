import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Icons, Typography } from "@/src/features/common";
import { Colors, ColorBasic } from "@/src/enums/colors";
import { useLazyGetUserReportViewMedicalHistoryQuery } from "@/src/redux/apiServices/suiteApi";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { t } from "@/src/features/Localization";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
const MedicationHistoryDownloadContainer = styled.div ``;
const MedicationHistoryDownloadContent = styled.div `
	margin-top: 33px;
`;
const Title = styled(Typography.Heading4Regular) `
	color: ${ColorBasic.Night50};
	font-family: Cabin;
	font-size: 23px;
	line-height: 30px;
	text-align: Center;
`;
const ButtonContainer = styled.div `
	width: 172px;
	height: 56px;
	margin: 96px auto 0;
`;
const CTAButton = styled.button `
	background: ${(props) => (props.primary ? ColorBasic.Night50 : Colors.ActionPrimaryDefault)};
	color: ${(props) => (props.primary ? ColorBasic.Light : ColorBasic.Night50)};
	position: relative;
	display: block;
	padding: 14px 18px;
	border-radius: 32px;
	overflow: hidden;
	width: 100%;
	height: 100%;
	align-text: center;
`;
const GenerateReportButtonText = styled(Typography.ButtonLarge) `
	color: ${ColorBasic.Blanc00};
`;
const ViewReportButtonContainer = styled.div `
	display: flex;
	justify-content: center;
`;
const ViewReportButtonText = styled(Typography.ButtonLarge) `
	color: ${ColorBasic.Blanc00};
`;
const ReportReadyText = styled(Typography.ParagraphBaseMedium) `
	color: ${Colors.StatusSuccess};
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 32px;
`;
const ViewReportIconContainer = styled.div `
	margin-top: 6px;
	margin-left: 6px;
`;
const LoadingSpinnerContainer = styled.div `
	position: absolute;
	left: 40%;
	top: 12.5%;
`;
export const MedicationHistoryDownload = (p) => {
    const [reportUrl, setReportUrl] = useState("");
    const { snackbar } = useCustomSnackbar();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const userFor = p.userForGuid;
    const [getUserReportViewMedicalHistory, { isLoading: getUserReportIsLoading, isSuccess: getUserReportIsSuccess, isError: getUserReportIsError, data: getUserReportRequestData },] = useLazyGetUserReportViewMedicalHistoryQuery();
    function handleGenerateReport() {
        if (userFor) {
            getUserReportViewMedicalHistory({ guid: userFor });
        }
        segmentTrackEvent("generate_med_report");
    }
    function handleViewReport() {
        segmentTrackEvent("view_med_report");
        window.open(reportUrl);
    }
    useEffect(() => {
        var _a, _b;
        if (getUserReportIsSuccess) {
            const reportUrlData = (_b = (_a = getUserReportRequestData === null || getUserReportRequestData === void 0 ? void 0 : getUserReportRequestData.data) === null || _a === void 0 ? void 0 : _a.report) === null || _b === void 0 ? void 0 : _b.url;
            setReportUrl(reportUrlData);
        }
    }, [getUserReportIsSuccess, getUserReportRequestData]);
    useEffect(() => {
        if (getUserReportIsError) {
            const errorMessage = "Report unavailable";
            snackbar.error(errorMessage);
        }
    }, [getUserReportIsError]);
    return (_jsx(MedicationHistoryDownloadContainer, { children: _jsxs(MedicationHistoryDownloadContent, { children: [_jsx(Title, { children: `Generate a medication history report for ${p.userForName}` }, void 0), _jsx(ButtonContainer, { children: reportUrl ? (_jsx(CTAButton, Object.assign({ onClick: handleViewReport, type: "submit" }, { children: _jsxs(ViewReportButtonContainer, { children: [_jsx(ViewReportButtonText, { children: t("medicationHistory_available_button_viewReport") }, void 0), _jsx(ViewReportIconContainer, { children: _jsx(Icons.ArrowUpRightContainer, { addColorFillToPath: "white" }, void 0) }, void 0)] }, void 0) }), void 0)) : (_jsx(CTAButton, Object.assign({ onClick: handleGenerateReport, primary: true, type: "submit" }, { children: getUserReportIsLoading ? (_jsx(LoadingSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0)) : (_jsx(GenerateReportButtonText, { children: t("medicationHistory_available_button_generateReport") }, void 0)) }), void 0)) }, void 0), reportUrl && _jsx(ReportReadyText, { children: t("medicationHistory_available_content") }, void 0)] }, void 0) }, void 0));
};
