import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
import { ChevronRightAqua, ExternalLink } from "@/src/features/common/Icons";
import { ColorBasic } from "@/src/enums/colors";
const OrderDetailsContentCardHeader = styled(Typography.ParagraphSmallHeavy).attrs({ className: "details-content-card-title" }) `
	color: ${ColorBasic.Night50};
`;
const OrderDetailsContentCardBody = styled(Typography.ParagraphSmallRegular) `
	color: ${ColorBasic.Dark60};
	padding-right: 10px;
`;
const OrderDetailsContentCard = styled.div `
	width: 316px;
	min-height: 40px;
	border: 1px solid ${ColorBasic.Night10};
	padding: 12px;
	display: flex;
	align-items: center;
	border-bottom: 0px;
	&:first-child {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	&:last-child {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		border-bottom: ${({ $isBorderless }) => ($isBorderless ? "0px;" : `1px solid ${ColorBasic.Night10}`)};
	}

	&:only-child {
	}

	${({ $isRemoveBorderTop }) => $isRemoveBorderTop && "border-top: 0px;"}
	${({ $isBorderless }) => $isBorderless && "border: 0px;"}

	${({ $isClickable }) => $isClickable
    ? `
	&:hover {
		cursor: pointer;
	}

	&:hover .details-content-card-title {
		color: ${ColorBasic.Aqua50};
	}

	&:hover .content-card-chevron path {
		fill: ${ColorBasic.Aqua50};
	}

	`
    : ""}
`;
const BodyTextContainer = styled.div `
	flex: 1;
	gap: 4px;
	display: flex;
	flex-direction: column;
`;
const ChevronIcon = styled(ChevronRightAqua) `
	& path {
		fill: ${ColorBasic.Dark50};
	}
`;
const ExternalLinkIcon = styled(ExternalLink) `
	width: 18px;
	height: 18px;
	margin-right: 3px;
	& path {
		fill: ${ColorBasic.Dark50};
	}
`;
export const NoPrescriptionTableItem = ({ body, header, onClick, borderless = false, externalLink = false }) => {
    const $isClickable = Boolean(onClick);
    const Icon = externalLink ? ExternalLinkIcon : ChevronIcon;
    return (_jsxs(OrderDetailsContentCard, Object.assign({ "$isTop": true, "$isClickable": $isClickable, "$isBorderless": borderless, onClick: onClick }, { children: [_jsxs(BodyTextContainer, { children: [_jsx(OrderDetailsContentCardHeader, { children: header }, void 0), _jsx(OrderDetailsContentCardBody, { children: body }, void 0)] }, void 0), $isClickable && _jsx(Icon, { className: "content-card-chevron" }, void 0)] }), void 0));
};
