import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import styled from "styled-components";
import qs from "query-string";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useGetWorkflowListCustomerCountQuery } from "@/src/redux/apiServices/suiteApi";
import { getUserMetaFlagIsRelationshipWithDependents } from "@/src/features/User/userHelpers";
import { useAuth } from "@/src/hooks/useAuth";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { Icons } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { routes } from "@/src/routes";
import { FooterNavButton } from "@/src/features/common/FooterNavButton";
import { WorkflowStatusCategory } from "@/src/modules/workflow/type/WorkflowStatusCategory";
import { WorkflowTypeCategory } from "@/src/modules/workflow/type/WorkflowTypeCategory";
const Root = styled.div `
	& {
		height: 84px;
		background: ${ColorBasic.Night50};
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		gap: 12px;
	}
`;
export const FooterNav = () => {
    var _a;
    const history = useHistory();
    const location = useLocation();
    const { user } = useAuth();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const userIsRelationshipWithDependents = getUserMetaFlagIsRelationshipWithDependents(user);
    /* ---------------------------- Variables ---------------------------- */
    const queryString = qs.stringify({
        statusCategory: [WorkflowStatusCategory.Triage, WorkflowStatusCategory.Progress],
        typeCategory: [WorkflowTypeCategory.Rx, WorkflowTypeCategory.Service, WorkflowTypeCategory.Transfer],
        isAddDependents: userIsRelationshipWithDependents,
        userForGuid: user === null || user === void 0 ? void 0 : user.guid,
    });
    /* ---------------------------- API Hooks ---------------------------- */
    const { data: getWorkflowListCustomerCountRequestData, refetch: refetchGetWorkflowListCustomerCount } = useGetWorkflowListCustomerCountQuery({ queryString }, { skip: !(user === null || user === void 0 ? void 0 : user.guid) });
    /* ----------------------------- More Variables ----------------------------- */
    const numberOpenWorkflows = ((_a = getWorkflowListCustomerCountRequestData === null || getWorkflowListCustomerCountRequestData === void 0 ? void 0 : getWorkflowListCustomerCountRequestData.data) === null || _a === void 0 ? void 0 : _a.workflows) || 0;
    /* ---------------------------- Helper Functions ---------------------------- */
    function handleClickHome() {
        segmentTrackEvent("select_home");
        history.push(routes.Home.toPath());
    }
    function handleClickOrders() {
        segmentTrackEvent("select_orders");
        history.push(routes.Orders.toPath());
    }
    function handleClickProfile() {
        segmentTrackEvent("select_profile");
        history.push(routes.Profile.toPath());
    }
    const handleClickServices = () => {
        history.push(routes.ServiceList.toPath());
    };
    /* ---------------------------- useEffects ---------------------------- */
    useEffect(() => {
        // refetchGetWorkflowListCustomer();
        refetchGetWorkflowListCustomerCount();
    }, [location.pathname]);
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsxs(Root, { children: [_jsx(FooterNavButton, { icon: Icons.FooterHome, isActive: location.pathname === "/", onClick: handleClickHome, title: "Home" }, void 0), _jsx(FooterNavButton, { icon: Icons.FooterServices, isActive: location.pathname === "/services", onClick: handleClickServices, title: "Services" }, void 0), _jsx(FooterNavButton, { count: numberOpenWorkflows, icon: Icons.FooterOrders, isActive: location.pathname === "/orders", onClick: handleClickOrders, title: "Orders" }, void 0), _jsx(FooterNavButton, { icon: Icons.FooterProfile, isActive: location.pathname === "/profile", onClick: handleClickProfile, title: "Profile" }, void 0)] }, void 0));
};
