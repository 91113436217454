import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useState } from "react";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import styled from "styled-components";
import { t } from "@/src/features/Localization";
import { CustomCTAButton } from "@/src/features/common/CTAButton";
function getModalStyle() {
    return {
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
        width: "327px",
        height: "240px",
    };
}
const useStyles = makeStyles((theme) => createStyles({
    paper: {
        position: "absolute",
        width: 327,
        backgroundColor: theme.palette.background.paper,
        border: "2px",
        boxShadow: theme.shadows[1],
    },
}));
const BoxStyled = styled(Box) `
	&& {
		text-align: center;
		outline: none;
		box-shadow: none;
		border-radius: 7px;
	}

	&& .MuiButtonBase-root {
		margin-top: 24px;
	}
`;
const ModalHeader = styled(Typography.Heading6Bold) `
	color: ${ColorBasic.Aqua60};
	width: 100%;
	margin-top: 45px;
	margin-bottom: 16px;
`;
const ModalContainer = styled.div `
	display: flex;
`;
const ModalText = styled(Typography.ParagraphBaseRegular) `
	white-space: pre-wrap;
	width: 100%;
`;
const ModalTextBold = styled(Typography.ParagraphBaseHeavy) `
	color: ${ColorBasic.Aqua60};
	display: inline;
`;
const ModalFooter = styled.div `
	display: flex;
	justify-content: center;
`;
export const AutoLogoutModal = ({ isShowModal, setUserActive, countdown }) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const minutes = Math.floor(countdown / 60).toString();
    const seconds = countdown % 60 === 0 ? "00" : (countdown % 60).toString();
    return (_jsx("div", { children: _jsx(Modal, Object.assign({ open: isShowModal, onClose: setUserActive, "aria-labelledby": "simple-modal-title", "aria-describedby": "simple-modal-description" }, { children: _jsxs(BoxStyled, Object.assign({ style: modalStyle, className: classes.paper, "data-cy": "auto-logout-modal" }, { children: [_jsx(ModalHeader, { children: t("common_dialog_inactivity_header") }, void 0), _jsx(ModalContainer, { children: _jsx(ModalText, { children: t("common_dialog_inactivity_content", {
                                timeLeft: (_jsx(ModalTextBold, { children: t("common_dialog_inactivity_content_timeLeft", {
                                        minutes,
                                        seconds,
                                    }) }, "modalText")),
                            }) }, void 0) }, void 0), _jsx(ModalFooter, { children: _jsx(CustomCTAButton, { onClick: setUserActive, isPrimary: true, text: t("common_dialog_inactivity_button_dismiss") }, void 0) }, void 0)] }), void 0) }), void 0) }, void 0));
};
