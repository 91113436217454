import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Icons } from "@/src/features/common";
import { EmptyStatePageContentContainer } from "@/src/features/common/EmptyStatePageContentContainer";
import { LoadingPageFiller } from "@/src/features/common/LoadingPageFiller";
import { t } from "@/src/features/Localization";
import { useAuth } from "@/src/hooks/useAuth";
import { WorkflowTypeRxFillV3 } from "@/src/modules/workflow/type/rx-fill-v3/Type";
import { usePostWorkflowAddMutation } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	margin-top: 135px;
`;
const ImageContainer = styled.div `
	display: flex;
	justify-content: center;
	margin-top: 30px;
	padding-left: 30px;
`;
export const ProfileWalletEmptyState = () => {
    const { user } = useAuth();
    const history = useHistory();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const [workflowAdd, { isLoading: workflowRequestIsLoading, isSuccess: workflowRequestIsSuccess, data: workFlowAddResponseData }] = usePostWorkflowAddMutation();
    function handleNewPrescriptionWorkflow() {
        workflowAdd({
            type: WorkflowTypeRxFillV3,
            userFor: user === null || user === void 0 ? void 0 : user.guid,
        });
        segmentTrackEvent("select_new_rx", {
            entrypoint: SegmentEntryPoint.Wallet,
        });
    }
    function handleChatWithUs() {
        history.push(routes.Chat.toPath());
        segmentTrackEvent("select_chat", {
            entrypoint: SegmentEntryPoint.Wallet,
        });
    }
    useEffect(() => {
        var _a, _b;
        if (workflowRequestIsSuccess) {
            const workflowGuid = (_b = (_a = workFlowAddResponseData === null || workFlowAddResponseData === void 0 ? void 0 : workFlowAddResponseData.data) === null || _a === void 0 ? void 0 : _a.workflow) === null || _b === void 0 ? void 0 : _b.guid;
            history.push(routes.WorkflowView.toPath({ workflowGuid }));
        }
    }, [workFlowAddResponseData, workflowRequestIsSuccess]);
    if (workflowRequestIsLoading) {
        return _jsx(LoadingPageFiller, {}, void 0);
    }
    return (_jsx(Root, { children: _jsx(EmptyStatePageContentContainer, { header: t("profile_tab_wallet_unavailable_header"), image: _jsx(ImageContainer, { children: _jsx(Icons.WalletNoOrderPayment, { style: { height: "143px", width: "163px" } }, void 0) }, void 0), footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ size: "large", variant: "round", onClick: handleNewPrescriptionWorkflow }, { children: t("profile_tab_wallet_unavailable_button_fillRx") }), void 0), _jsx(Button, Object.assign({ size: "large", variant: "borderless", onClick: handleChatWithUs, color: "secondary" }, { children: t("profile_tab_wallet_unavailable_button_chat") }), void 0)] }, void 0) }, void 0) }, void 0));
};
