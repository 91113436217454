import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { getDeepLinkFromURLSearchParams } from "@/src/features/DeepLink/DeepLinkHelpers";
import { t } from "@/src/features/Localization";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
const LogInContainer = styled(Typography.ParagraphBaseRegular) `
	margin: 32px auto 0;
	color: ${ColorBasic.Dark60};
	text-align: center;
`;
const LogInLink = styled(Link) `
	${Typography.LinkMediumStyle}
	color: ${ColorBasic.Night50};
`;
export const LoginInstead = (p) => {
    const { query } = useQuery();
    const deeplink = getDeepLinkFromURLSearchParams(query);
    return (_jsx(Root, { children: _jsxs(LogInContainer, { children: [t("signUp_email_step1_footer_haveAccount"), "\u00A0", _jsx(LogInLink, Object.assign({ "data-cy": "signup-login-link", to: routes.Login.toPath({
                        deeplink: deeplink,
                    }) }, { children: t("signUp_email_step1_footer_logIn") }), void 0)] }, void 0) }, void 0));
};
