import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import SVGEmailSentSuccess from "@/src/public/app/images/svg/email-sent-success.svg";
import { Nav } from "@/src/features/Nav/Nav";
import { Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { ColorBasic } from "@/src/enums/colors";
import { routes } from "@/src/routes";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { HyperlinkType } from "@/src/enums/hyperlink";
const ForgotPasswordLinkResendPageContainer = styled.div ``;
const ForgotPasswordLinkResendContentContainer = styled.div `
	margin-top: 150px;
`;
const Title = styled(Typography.Heading3Regular) `
	font-style: Medium;
	font-size: 30px;
	line-height: 38px;
	text-align: center;
	vertical-align: top;
	letter-spacing: 1%;
	color: ${ColorBasic.Night50};
	margin-bottom: 40px;
`;
const SVGEmailSentSuccessContainer = styled.div `
	display: flex;
	justify-content: center;
	margin-bottom: 28px;
`;
const SubheaderContainer = styled.div `
	display: flex;
	justify-content: center;
`;
const Subheader = styled(Typography.ParagraphBaseMedium) `
	font-style: Medium;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	vertical-align: top;
	letter-spacing: 1%;
	color: ${ColorBasic.Night40};
	width: 248px;
`;
export const ResendHyperlinkSuccessPage = () => {
    const { query } = useQuery();
    const hyperlinkType = query === null || query === void 0 ? void 0 : query.get("type");
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Login",
                    linkTo: routes.Login.toPath(),
                } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsx(ForgotPasswordLinkResendPageContainer, { children: _jsxs(ForgotPasswordLinkResendContentContainer, { children: [_jsx(Title, { children: t("linkResent_header") }, void 0), _jsx(SVGEmailSentSuccessContainer, { children: _jsx(SVGEmailSentSuccess, {}, void 0) }, void 0), _jsx(SubheaderContainer, { children: _jsx(Subheader, { children: getSubheader(hyperlinkType) }, void 0) }, void 0)] }, void 0) }, void 0) }, void 0) }, void 0)] }, void 0));
};
// TODO: reidenzon - Move to an Info object!
const getSubheader = (type) => {
    let subheader = t("linkResent_content_passwordReset");
    if (type == HyperlinkType.UserInviteWithoutEmailActivate) {
        subheader = t("linkResent_content_userInviteWithoutEmailActivate");
    }
    return subheader;
};
