import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { AdvisoryListItem } from "@/src/common/components/advistory/AdvisoryListItem";
const Root = styled.div `
	& {
		//background: ${ColorBasic.Corn10};
		//border-radius: 8px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		//padding: 16px 12px;
	}
`;
const RootTitle = styled(Typography.SmallCapsBold) `
	& {
		color: ${ColorBasic.Night50};
	}
`;
export const AdvisoryList = (p) => {
    return (_jsxs(Root, { children: [p.title && _jsx(RootTitle, { children: p.title }, void 0), p.items.map((item) => {
                return _jsx(AdvisoryListItem, { instruction: item }, item.title);
            })] }, void 0));
};
