import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { getWorkflowStatusInfo } from "@/src/modules/workflow/type/WorkflowStatus";
import { getWorkflowStatusColorInfo } from "@/src/modules/workflow/type/WorkflowStatusColor";
import { getWorkflowTypeInfo } from "@/src/modules/workflow/type/WorkflowType";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useHistory } from "react-router";
import styled, { keyframes } from "styled-components";
const DynamicUserActionButton = styled.button `
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
	align-items: center;
	border-radius: 4px;
	border: ${({ $workflowStatusBorderColor }) => $workflowStatusBorderColor ? `0.5px solid ${$workflowStatusBorderColor};` : `0.5px solid ${ColorBasic.Dark40};`};
	gap: 12px;
	background-color: ${ColorBasic.Blanc00};

	&:hover {
		opacity: 65%;
	}

	:focus {
		color: ${({ $workflowStatusBorderColor }) => ($workflowStatusBorderColor ? `${$workflowStatusBorderColor};` : `${ColorBasic.Dark40};`)};
		background-color: ${({ $workflowStatusBaseColor }) => ($workflowStatusBaseColor ? `${$workflowStatusBaseColor};` : `${ColorBasic.Blanc00}`)};

		border: ${({ $workflowStatusBorderColor }) => $workflowStatusBorderColor ? `0.5px solid ${$workflowStatusBorderColor};` : `0.5px solid ${ColorBasic.Dark40};`};
		outline: 0;
	}
`;
const ContentContainer = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
`;
const OrderStatusIcon = styled.div `
	background-color: ${({ $workflowStatusIconColor }) => ($workflowStatusIconColor ? `${$workflowStatusIconColor};` : `${ColorBasic.Dark40}`)};
	width: 8px;
	height: 8px;
	border-radius: 50%;
`;
const WorkflowTypeText = styled(Typography.ParagraphBaseSemiHeavy) `
	color: ${ColorBasic.Night50};
`;
const DynamicUserActionBadge = styled.div `
	background-color: ${({ $workflowStatusBadgeBaseColor }) => ($workflowStatusBadgeBaseColor ? `${$workflowStatusBadgeBaseColor};` : `${ColorBasic.Dark40}`)};
	padding: 2px 8px;
	height: 18px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const DynamicUserActionButtonText = styled(Typography.ParagraphSmallMedium) `
	color: ${({ $workflowStatusBadgeTextColor }) => ($workflowStatusBadgeTextColor ? `${$workflowStatusBadgeTextColor};` : `${ColorBasic.Dark40}`)};
`;
const animatedArrowKeyframeLeftPosition = "-4px";
const breatheAnimation = keyframes `
	0% {
		transform: translate(0, 0);
	}
	80% {
		transform: translate(0, 0);
	}
	85% {
		transform: translate(${animatedArrowKeyframeLeftPosition}, 0);
	}
	90% {
		transform: translate(0, 0);
	}
	95% {
		transform: translate(${animatedArrowKeyframeLeftPosition}, 0);
	}
	100% {
		transform: translate(0, 0);
	}
`;
const IconContainer = styled.div `import {
	t
}

	from "@/src/features/Localization";
	animation-name: ${breatheAnimation};
	animation-duration: 4s; // 4s hold, 1s move -4px (i.e. to left), 1s move right to 0, 1s move -4px, 1s move back right;
	animation-iteration-count: infinite;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const DynamicUserActionButtonArrow = styled(Icons.ArrowForwardRound) `
	width: 24px;
	height: 24px;
`;
export const DynamicActionBar = (p) => {
    var _a, _b;
    const history = useHistory();
    const segment = useSegmentAnalytics();
    const workflow = (_a = p.rxWorkflows) === null || _a === void 0 ? void 0 : _a[0];
    if (!workflow) {
        return _jsx(_Fragment, {}, void 0);
    }
    function handleWorkflowDetailsChange(workflowGuid) {
        segment.segmentTrackEvent("view_order_progress", {
            entrypoint: SegmentEntryPoint.HomePage,
            patient_id: workflow === null || workflow === void 0 ? void 0 : workflow.patient.guid,
        });
        history.push(routes.WorkflowView.toPath({ workflowGuid }));
    }
    const typeInfo = getWorkflowTypeInfo(workflow.type);
    const statusInfo = getWorkflowStatusInfo(workflow.status);
    const statusColorInfo = getWorkflowStatusColorInfo(statusInfo.Color);
    return (_jsxs(DynamicUserActionButton, Object.assign({ onClick: () => handleWorkflowDetailsChange(workflow.guid), "$workflowStatusBorderColor": statusColorInfo.Border, "$workflowStatusBaseColor": statusColorInfo.Background }, { children: [_jsxs(ContentContainer, { children: [_jsx(OrderStatusIcon, { "$workflowStatusIconColor": statusColorInfo.Icon }, void 0), _jsx(WorkflowTypeText, { children: typeInfo.TextDab }, void 0), _jsx(DynamicUserActionBadge, Object.assign({ "$workflowStatusBadgeBaseColor": statusColorInfo.Background }, { children: _jsx(DynamicUserActionButtonText, Object.assign({ "$workflowStatusBadgeTextColor": statusColorInfo.Text }, { children: (_b = statusInfo.TextDab) === null || _b === void 0 ? void 0 : _b.call(statusInfo, workflow.details) }), void 0) }), void 0)] }, void 0), _jsx(IconContainer, { children: _jsx(DynamicUserActionButtonArrow, { color: ColorBasic.Night50 }, void 0) }, void 0)] }), void 0));
};
