import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppStoreLinks } from "@/src/common/components/app-store/AppStoreLinks";
import { Config } from "@/src/common/utility/config/Config";
import { BreakpointPx, BreakpointPxForMaxWidth } from "@/src/enums/breakpoints";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageFullOverflowContainer } from "@/src/features/common/Page/PageFullOverflowContainer";
import { CssBackgroundImagesValues } from "@/src/features/cssBackgroundImages/cssBackgroundImageValues";
import { getDeepLinkFromURLSearchParams } from "@/src/features/DeepLink/DeepLinkHelpers";
import { t } from "@/src/features/Localization";
import { LoginForm } from "@/src/features/Login/LoginForm";
import { SSOSignInWithButtons } from "@/src/features/Login/SSOSignInWithButtons";
import { CenterItemContainer, NavContainer, NavContent } from "@/src/features/Nav/Nav";
import { VersionAndConditionalBuildId } from "@/src/features/VersionNumber/VersionAndConditionalBuildId";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { Link } from "react-router-dom";
import styled from "styled-components";
/* --------------------------------- Styles --------------------------------- */
const LoginPageNavContainer = styled(NavContainer) `
	& ${CenterItemContainer} {
		margin: 0 auto;
	}

	// Hide top banner when in Mobile browser width
	@media (max-width: ${BreakpointPxForMaxWidth.Desktop}) {
		display: none;
	}
`;
const MednowHeaderContainer = styled.div `
	width: 100%;
	margin-top: 20px;
	margin-bottom: 34px;
`;
const MednowLogoMobileOnly = styled(Icons.MednowLogoWithName) `
	margin-top: 30px;

	// Hide this mednowlogo when in Desktop view
	@media (min-width: ${BreakpointPx.Desktop}) {
		display: none;
	}
`;
const MednowHeader = styled(Typography.Heading3Heavy) `
	margin-top: 20px;
	color: ${ColorBasic.Aqua60};
`;
const LoginPageContainer = styled.div `
	display: flex;
	flex-direction: column;
	min-height: 100%;
	align-items: center;
	margin: 0 auto;
	width: 322px;
`;
const DontHaveAnAccountContainer = styled(Typography.ParagraphBaseRegular) `
	margin: 30px 16px 0px;
	color: ${ColorBasic.Dark60};
`;
const PreferThePhoneContainer = styled(Typography.ParagraphBaseRegular) `
	margin: 12px 16px 0px;
	color: ${ColorBasic.Dark60};
`;
const FaxContainer = styled(Typography.ParagraphBaseRegular) `
	margin: 12px 16px 0px;
	color: ${ColorBasic.Dark60};
`;
const LoginTypeDivider = styled(Typography.ParagraphBaseMedium) `
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: ${ColorBasic.Night40};
	margin-top: 20px;
	margin-bottom: 20px;
`;
const LoginDividerLine = styled.div `
	width: 70px;
	height: 1px;
	background-color: ${ColorBasic.Night10};
	margin: 0 8px;
`;
const SignUpLink = styled(Link) `
	${Typography.LinkMediumStyle}
	color: ${ColorBasic.Night50};
`;
const PreferThePhoneNumber = styled.a `
	${Typography.LinkMediumStyle}
	color: ${ColorBasic.Night50};
`;
const FaxNumber = styled.span `
	${Typography.LinkMediumStyle}
	color: ${ColorBasic.Night50};
`;
const AppStoreLinksContainer = styled.div `
	& {
		height: 42px;
		margin: 42px 0;
	}
`;
const VersionAndConditionalBuildIdStyled = styled(VersionAndConditionalBuildId) `
	position: fixed;
	top: 15px;
	right: 15px;
	z-index: 9999;

	&,
	& * {
		color: ${ColorBasic.Night50} !important;
	}

	// Desktop only styles
	@media (min-width: ${BreakpointPx.Desktop}) {
		// NOTE: show volume on/off (and ChatMessageInputExtraFooter in general)
		// on desktop width only
		display: block;

		&,
		& * {
			color: ${ColorBasic.Blanc00} !important;
		}
	}
`;
export const LoginPage = () => {
    const { query } = useQuery();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const deeplink = getDeepLinkFromURLSearchParams(query);
    const institutionCode = query.get("groupCode");
    const pharmacyGuid = query.get("ph") || undefined;
    function handleOnPhoneNumberClick() {
        segmentTrackEvent("select_contact", {
            entrypoint: SegmentEntryPoint.Login,
        });
    }
    return (_jsxs(_Fragment, { children: [Config.DebugInfoIsEnabled && _jsx(VersionAndConditionalBuildIdStyled, {}, void 0), _jsxs(PageContainer, { children: [_jsx(LoginPageNavContainer, { children: _jsx(NavContent, { children: _jsx(CenterItemContainer, { children: _jsx(Icons.MednowLogoWithWhiteName, { width: 132, height: 24 }, void 0) }, void 0) }, void 0) }, void 0), _jsx(PageFullOverflowContainer, Object.assign({ "$backgroundImages": CssBackgroundImagesValues.Page.LoginAndSignup }, { children: _jsxs(LoginPageContainer, Object.assign({ className: "LoginPageContainer" }, { children: [_jsxs(MednowHeaderContainer, { children: [_jsx(MednowLogoMobileOnly, { width: 132, height: 24 }, void 0), _jsx(MednowHeader, { children: t("login_incentive_header") }, void 0)] }, void 0), _jsx(LoginForm, {}, void 0), _jsxs(LoginTypeDivider, { children: [_jsx(LoginDividerLine, {}, void 0), t("login_alternative"), _jsx(LoginDividerLine, {}, void 0)] }, void 0), _jsx(SSOSignInWithButtons, { isLogin: true }, void 0), _jsxs(DontHaveAnAccountContainer, { children: [t("login_footer_noAccount"), "\u00A0", _jsx(SignUpLink, Object.assign({ "data-cy": "login-signup-link", to: routes.RegisterAccount.toPath({
                                                deeplink: deeplink,
                                                groupCode: institutionCode,
                                                pharmacyGuid: pharmacyGuid,
                                            }) }, { children: t("login_footer_signUp") }), void 0)] }, void 0), _jsxs(PreferThePhoneContainer, { children: [t("login_footer_contact"), "\u00A0", _jsx(PreferThePhoneNumber, Object.assign({ href: `tel:${t("login_footer_phoneNumber")}`, target: "_blank", rel: "noopener", onClick: handleOnPhoneNumberClick }, { children: t("login_footer_phoneNumber") }), void 0)] }, void 0), _jsx(FaxContainer, { children: t("login_footer_fax", {
                                        faxNumber: _jsx(FaxNumber, { children: t("login_footer_fax_faxNumber") }, void 0),
                                    }) }, void 0), _jsx(AppStoreLinksContainer, { children: _jsx(AppStoreLinks, {}, void 0) }, void 0)] }), void 0) }), void 0)] }, void 0)] }, void 0));
};
