import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Icons, Typography } from "@/src/features/common";
import { useDeleteUserPaymentMethodMutation } from "@/src/redux/apiServices/suiteApi";
import { t } from "@/src/features/Localization";
import { ColorBasic } from "@/src/enums/colors";
import { ConfirmDialog } from "@/src/features/common/ConfirmDialog";
const ProfileWalletCardContainer = styled.div ``;
const ProfileWalletCardContentContainer = styled.div `
	background-color: ${({ $isProfile }) => ($isProfile ? ColorBasic.Aqua10 : ColorBasic.Dark10)};
	max-width: 332px;
	padding: 16px;
	border-radius: 8px;
	margin-bottom: 40px;
`;
const ProfileWalletCardHeader = styled.div `
	display: flex;
	justify-content: space-between;
`;
const CardTypeImageContainer = styled.div ``;
const CardDeleteButtonContainer = styled.button `
	display: flex;
	align-items: center;
	padding-bottom: 12px;
`;
const CardDeleteButtonText = styled(Typography.ParagraphBaseMedium) `
	&& * {
		text-decoration: none;
	}
	color: ${ColorBasic.StatusError};
`;
const ProfileWalletCardBody = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
`;
const CardEndingContainer = styled(Typography.ParagraphSmallHeavy) ``;
const CardExpiryContainer = styled(Typography.ParagraphSmallHeavy) ``;
export const PaymentMethodCard = ({ paymentMethods, refetchCurrentUser, user, userPaymentMethodRefetch, isProfile }) => {
    /* --------------------------------- States --------------------------------- */
    const [isModalOpen, setIsModalOpen] = useState(false);
    /* -------------------------------- API Hooks ------------------------------- */
    const [deleteUserPaymentMethod, { isSuccess: isSuccessDeleteUserPaymentMethod }] = useDeleteUserPaymentMethodMutation();
    /* -------------------------------- Variables ------------------------------- */
    const paymentMethod = paymentMethods[0];
    const { card, id: paymentMethodId } = paymentMethod;
    const { brand, last4, exp_month: expMonth, exp_year: expYear } = card;
    const formattedExpMonth = expMonth.length === 1 ? `0${expMonth}` : expMonth;
    const formattedExpYear = expYear.toString().slice(2, 4);
    const expiry = `${formattedExpMonth}/${formattedExpYear}`;
    /* ---------------------------- Helper Functions ---------------------------- */
    function getCardIconImage({ cardBrandType }) {
        switch (cardBrandType) {
            case "visa":
                return _jsx(Icons.WalletVisa, { size: 40 }, void 0);
            case "mastercard":
                return _jsx(Icons.WalletMasterCard, { size: 40 }, void 0);
            case "amex":
                return _jsx(Icons.WalletAmericanExpress, { size: 40 }, void 0);
            case "diners":
                return _jsx(Icons.WalletDinersClub, { size: 40 }, void 0);
            case "discover":
                return _jsx(Icons.WalletDiscover, { size: 40 }, void 0);
            case "jcb":
                return _jsx(Icons.WalletJCB, { size: 40 }, void 0);
            case "unionpay":
                return _jsx(Icons.WalletUnionPay, { size: 40 }, void 0);
            default:
                return _jsx(Icons.WalletAmericanExpress, { size: 40 }, void 0);
        }
    }
    function handleSetModalClosed() {
        setIsModalOpen(false);
    }
    function handleSetModalOpen() {
        setIsModalOpen(true);
    }
    function handleCardRemove() {
        if (user && paymentMethodId) {
            deleteUserPaymentMethod({ userFor: user === null || user === void 0 ? void 0 : user.guid, paymentMethodId });
        }
        handleSetModalClosed();
    }
    /* ------------------------------- useEffects ------------------------------- */
    useEffect(() => {
        if (isSuccessDeleteUserPaymentMethod) {
            if (userPaymentMethodRefetch) {
                userPaymentMethodRefetch();
            }
            refetchCurrentUser();
        }
    }, [isSuccessDeleteUserPaymentMethod]);
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsxs(ProfileWalletCardContainer, { children: [_jsxs(ProfileWalletCardContentContainer, Object.assign({ "$isProfile": isProfile }, { children: [_jsxs(ProfileWalletCardHeader, { children: [_jsx(CardTypeImageContainer, { children: getCardIconImage({ cardBrandType: brand }) }, void 0), isProfile && (_jsx(CardDeleteButtonContainer, Object.assign({ onClick: handleSetModalOpen }, { children: _jsx(CardDeleteButtonText, { children: t("common_paymentMethodCard_button_remove") }, void 0) }), void 0))] }, void 0), _jsxs(ProfileWalletCardBody, { children: [_jsx(CardEndingContainer, { children: t("common_paymentMethodCard_last4", { last4 }) }, void 0), _jsx(CardExpiryContainer, { children: t("common_paymentMethodCard_expiry", { expiry }) }, void 0)] }, void 0)] }), void 0), _jsx(ConfirmDialog, { isOpen: isModalOpen, handleClose: handleSetModalClosed, handleSubmit: handleCardRemove, titleText: t("common_paymentMethodCard_dialog_remove_header"), informationText: t("common_paymentMethodCard_dialog_remove_content"), buttonTextConfirm: t("common_paymentMethodCard_dialog_remove_button_yes"), buttonTextCancel: t("common_paymentMethodCard_dialog_remove_button_no") }, void 0)] }, void 0));
};
export default PaymentMethodCard;
