import { jsx as _jsx } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { InvoiceRow } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRow";
import styled from "styled-components";
const RootContent = styled.div `
	& {
		${Typography.ParagraphBaseRegularStyle};
		color: ${ColorBasic.Night50};
		text-transform: uppercase;
	}
`;
export const InvoiceRowShipping = (p) => {
    return (_jsx(InvoiceRow
    //
    , { "$background": ColorBasic.Dark10, left: _jsx(RootContent, { children: "Shipping" }, void 0), right: _jsx(RootContent, { children: "Free" }, void 0) }, void 0));
};
