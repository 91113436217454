import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.section `
	& {
		border: 1px solid ${ColorBasic.Night10};
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
		justify-content: space-between;
		padding: 12px;
	}

	&:first-child {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	& + & {
		border-top: none;
	}

	&:last-child {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
`;
const RootTitle = styled.div `
	& {
		${Typography.ParagraphSmallBoldStyle};
		color: ${ColorBasic.Night50};
		white-space: pre-wrap;
	}
`;
const RootContent = styled.div `
	& {
		${Typography.ParagraphSmallRegularStyle};
		color: ${ColorBasic.Dark60};
		white-space: pre-wrap;
	}
`;
export const SummaryItem = (p) => {
    const isNone = p.isHave === false;
    return (_jsxs(Root, Object.assign({ className: "SummaryItem" }, { children: [p.title && _jsx(RootTitle, { children: p.title }, void 0), _jsxs(RootContent, { children: [isNone && "None", !isNone && p.children] }, void 0)] }), void 0));
};
