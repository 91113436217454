import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { Colors } from "@/src/enums/colors";
import { RegisterType } from "@/src/features/RegisterAccount/types";
import { t } from "@/src/features/Localization";
const RegisterOrActivateTermsOfServiceCheckboxContainer = styled.div `
	margin-top: 20px;
`;
const StyledLinks = styled.a `
	color: ${Colors.ActionSecondaryDefault};
	text-decoration: underline;
`;
export const RegisterOrActivateTermsOfServiceCheckbox = ({ registerType, formikId }) => {
    let privacyPolicyCopy;
    let termsOfUseCopy;
    if (registerType === RegisterType.SSO) {
        privacyPolicyCopy = t("ssoRegistration_agreementOne_privacyPolicy");
        termsOfUseCopy = t("ssoRegistration_agreementOne_termsOfService");
    }
    else {
        privacyPolicyCopy = t("signUp_email_step2_agreementOne_privacyPolicy");
        termsOfUseCopy = t("signUp_email_step2_agreementOne_termsOfService");
    }
    const privacyPolicy = (_jsx(StyledLinks, Object.assign({ href: "https://mednow.ca/pages/privacy-policy", target: "_blank", rel: "noreferrer" }, { children: privacyPolicyCopy }), "privacyPolicy"));
    const termsOfService = (_jsx(StyledLinks, Object.assign({ href: "https://mednow.ca/pages/terms-of-use", target: "_blank", rel: "noreferrer" }, { children: termsOfUseCopy }), "termsOfService"));
    const legalText = registerType === RegisterType.SSO
        ? t("ssoRegistration_agreementOne", {
            privacyPolicy,
            termsOfService,
        })
        : t("signUp_email_step2_agreementOne", {
            privacyPolicy,
            termsOfService,
        });
    return (_jsx(RegisterOrActivateTermsOfServiceCheckboxContainer, Object.assign({ "data-cy": "privacy-policy-checkbox" }, { children: _jsx(FormikCheckbox, { formikId: formikId, label: legalText, isSecondary: true }, void 0) }), void 0));
};
