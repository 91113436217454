import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { FieldDescription } from "@/src/common/components/field/FieldDescription";
import { getHoursMapAsScheduleShort } from "@/src/common/utility/hours/HoursMap";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
`;
const RootBlock = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`;
const RootLabel = styled.div `
	& {
		${Typography.ParagraphSmallBoldStyle};
		color: ${ColorBasic.Night50};
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
`;
const RootWeekday = styled.div `
	& {
		${Typography.ParagraphSmallRegularStyle};
		color: ${ColorBasic.Dark80};

		display: flex;
		flex-direction: column;
		gap: 2px;
	}
`;
const RootDays = styled(Typography.ParagraphBaseMedium) `
	& {
		color: ${ColorBasic.Dark60};
	}
`;
const RootHourList = styled.div `
	& {
	}
`;
export const UberAvailabilityGeneral = (p) => {
    const w = p.workflow;
    const [isExpanded, setIsExpanded] = useState();
    // const settings = w.patient.settings;
    const settings = w.group.settings;
    const settingHours = getSettingDetailsByType(SettingType.GroupHoursUber, settings);
    return (_jsx(Root, { children: _jsxs(RootBlock, { children: [_jsxs(RootLabel, { children: [_jsx("div", { children: "Uber only available on..." }, void 0), !isExpanded && (_jsx(ButtonStandard
                        //
                        , { 
                            //
                            content: "Expand", style: ButtonStyle.TextAnchor, onClick: () => setIsExpanded(true) }, void 0))] }, void 0), isExpanded &&
                    getHoursMapAsScheduleShort(settingHours.hours).map((s) => {
                        return (_jsxs(RootWeekday, { children: [_jsx(RootDays, { children: s.days }, void 0), _jsx(RootHourList, { children: s.hoursList.map((h) => {
                                        return _jsx("div", { children: h }, h);
                                    }) }, void 0)] }, s.key));
                    }), isExpanded && _jsx(FieldDescription, { children: "You will have an opportunity to tip your driver at checkout." }, void 0)] }, void 0) }, void 0));
};
