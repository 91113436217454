import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BadgeComingSoon } from "@/src/common/components/badge/BadgeComingSoon";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		border: 1px solid ${ColorBasic.Night10};
		border-radius: 5px;
		display: flex;
		flex-direction: row;
		padding: 4px 12px;
		opacity: 0.5;
	}

	&.enabled {
		cursor: pointer;
		opacity: 1;
	}
`;
const RootLeft = styled.div `
	& {
		flex: 1;
	}
`;
const RootTitle = styled(Typography.ParagraphSmallMedium) `
	& {
		color: ${ColorBasic.Night50};
	}

	${Root}.enabled:hover & {
		color: ${ColorBasic.Aqua50};
	}
`;
const RootBody = styled(Typography.ParagraphSmallRegular) `
	color: ${ColorBasic.Dark60};
	//padding-right: 10px;
`;
const RootRight = styled.div `
	& {
		align-items: center;
		display: flex;
	}
`;
const RootIcon = styled(Icons.ChevronRightAqua) `
	& path {
		fill: ${ColorBasic.Dark50};
	}

	${Root}.enabled:hover & path {
		fill: ${ColorBasic.Aqua50};
	}
`;
export const ServiceListItem = (p) => {
    const classList = [];
    p.isEnabled && classList.push("enabled");
    const handleClick = () => {
        var _a;
        if (p.isEnabled) {
            (_a = p.onClick) === null || _a === void 0 ? void 0 : _a.call(p);
        }
    };
    return (_jsxs(Root, Object.assign({ className: classList.join(" "), onClick: handleClick }, { children: [_jsxs(RootLeft, { children: [_jsx(RootTitle, { children: p.title }, void 0), p.description && _jsx(RootBody, { children: p.description }, void 0)] }, void 0), _jsxs(RootRight, { children: [p.isEnabled && _jsx(RootIcon, {}, void 0), !p.isEnabled && _jsx(BadgeComingSoon, {}, void 0)] }, void 0)] }), void 0));
};
