import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PRE_LOGGEDIN_PAGE_CONTENT_MARGIN_OR_PADDING_TOP } from "@/src/constants";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { ConfirmDialog } from "@/src/features/common/ConfirmDialog";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { CssBackgroundImagesValues } from "@/src/features/cssBackgroundImages/cssBackgroundImageValues";
import { t } from "@/src/features/Localization";
import { Nav } from "@/src/features/Nav/Nav";
import { RegisterAccountSSOForm } from "@/src/features/RegisterAccount/RegisterAccountSSO/RegisterAccountSSOForm";
import { getSSOTokenRegister } from "@/src/features/RegisterAccount/RegisterAccountSSO/registerAccountSSOSlice";
import { routes } from "@/src/routes";
import { googleSignOut } from "@/src/utils/googleServices/googleAuth";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
const HeaderContainer = styled.div `
	& > * + * {
		margin-top: 8px;
	}

	margin-bottom: 32px;
`;
const Header = styled(Typography.Heading3Heavy) `
	margin-top: ${PRE_LOGGEDIN_PAGE_CONTENT_MARGIN_OR_PADDING_TOP}px;
	color: ${ColorBasic.Aqua60};
`;
const Subheader = styled(Typography.ParagraphSmallMedium) `
	color: ${ColorBasic.Night40};
	white-space: pre-wrap;
`;
const SubheaderItem = styled.div `
	display: flex;
	gap: 10px;
`;
const SubheaderItemText = styled(Typography.ParagraphBaseRegular) `
	color: ${ColorBasic.Dark60};
`;
const RegisterAccountPageContainer = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
export const RegisterAccountSSOPage = () => {
    const [cancelRegisterModalOpen, setCancelRegisterModalOpen] = useState(false);
    const history = useHistory();
    const ssoTokenRegister = useSelector(getSSOTokenRegister);
    const hasSSOTokenRegister = Boolean(ssoTokenRegister);
    function handleModalSubmit() {
        googleSignOut();
        setCancelRegisterModalOpen(false);
        history.push(routes.Login.toPath());
    }
    function handleCancelRegister() {
        setCancelRegisterModalOpen(true);
    }
    function handleSetModalClosed() {
        setCancelRegisterModalOpen(false);
    }
    // User should only be on the register via sso page after we have successfully
    // logged them in with sso (i.e. the sso credentials have been set)
    if (!hasSSOTokenRegister) {
        return _jsx(Redirect, { to: routes.Login.toPath() }, void 0);
    }
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Cancel",
                    onClick: handleCancelRegister,
                } }, "Nav"), _jsx(PageOverflowContainer, Object.assign({ "$backgroundImages": CssBackgroundImagesValues.Page.LoginAndSignup }, { children: _jsx(PageWrapper, Object.assign({ isDisableVerticalPadding: true }, { children: _jsxs(RegisterAccountPageContainer, { children: [_jsxs(HeaderContainer, { children: [_jsx(Header, { children: t("ssoRegistration_incentive_header") }, void 0), _jsxs(Subheader, { children: [_jsxs(SubheaderItem, { children: [_jsx(Icons.Check, { color: ColorBasic.Aqua50 }, void 0), _jsx(SubheaderItemText, { children: t("signUp_email_step2_incentive_subheader_line1") }, void 0)] }, void 0), _jsxs(SubheaderItem, { children: [_jsx(Icons.Check, { color: ColorBasic.Aqua50 }, void 0), _jsx(SubheaderItemText, { children: t("signUp_email_step2_incentive_subheader_line2") }, void 0)] }, void 0), _jsxs(SubheaderItem, { children: [_jsx(Icons.Check, { color: ColorBasic.Aqua50 }, void 0), _jsx(SubheaderItemText, { children: t("signUp_email_step2_incentive_subheader_line3") }, void 0)] }, void 0)] }, void 0)] }, void 0), _jsx(RegisterAccountSSOForm, {}, void 0)] }, void 0) }), void 0) }), void 0), _jsx(ConfirmDialog, { isOpen: cancelRegisterModalOpen, handleClose: handleSetModalClosed, handleSubmit: handleModalSubmit, titleText: t("signUp_dialog_cancel_header"), informationText: t("signUp_dialog_cancel_content"), buttonTextConfirm: t("signUp_dialog_cancel_button_yes"), buttonTextCancel: t("signUp_dialog_cancel_button_no") }, void 0)] }, void 0));
};
