import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Icons } from "@/src/features/common";
import { EmptyStatePageContentContainer } from "@/src/features/common/EmptyStatePageContentContainer";
import { t } from "@/src/features/Localization";
import { useCreateThenRedirectToNewWorkflowRx } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowRx";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useHistory } from "react-router";
import styled from "styled-components";
const MedicationHistoryErrorContainer = styled.div `
	margin-top: 135px;
`;
const ImageContainer = styled.div `
	display: flex;
	justify-content: center;
	margin-top: 32px;
`;
export const MedicationHistoryError = (p) => {
    const history = useHistory();
    const segment = useSegmentAnalytics();
    const newWorkflowRxFill = useCreateThenRedirectToNewWorkflowRx();
    function handleNewPrescriptionWorkflow() {
        segment.segmentTrackEvent("select_new_rx", {
            entrypoint: SegmentEntryPoint.MedicationHistory,
        });
        newWorkflowRxFill.createThenRedirectToNewWorkflowRx(p.userForGuid);
    }
    function handleNoPrescriptionAlternative() {
        history.push(routes.PrescriptionAlternatives.toPath({
            patientGuid: p.userForGuid,
        }));
    }
    return (_jsx(MedicationHistoryErrorContainer, { children: _jsx(EmptyStatePageContentContainer, { header: t("medicationHistory_unavailable_header"), image: _jsx(ImageContainer, { children: _jsx(Icons.PrescriptionNotSubmitted, { style: { height: "125px", width: "270px" } }, void 0) }, void 0), content: t("medicationHistory_unavailable_content"), footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ size: "large", variant: "round", onClick: handleNewPrescriptionWorkflow }, { children: t("medicationHistory_unavailable_button_fillRx") }), void 0), _jsx(Button, Object.assign({ size: "large", variant: "borderless", onClick: handleNoPrescriptionAlternative, color: "secondary" }, { children: t("medicationHistory_unavailable_button_chat") }), void 0)] }, void 0) }, void 0) }, void 0));
};
