import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
/* --------------------------------- Styles --------------------------------- */
const DrawerContentContainer = styled.div `
	position: relative;
	box-sizing: border-box;
	padding-bottom: ${({ $isUseBottomPadding }) => ($isUseBottomPadding ? "32px" : "")};
	min-height: ${({ $useMaxHeight, $height }) => ($useMaxHeight ? "100%;" : $height)};
	height: ${({ $height }) => $height};
	display: ${({ $isCenterItems }) => ($isCenterItems ? "flex" : "block")};
	flex-direction: ${({ $isCenterItems }) => $isCenterItems && "column"};
`;
// This might be old code
// min-height: ${(props) =>
// 	props.useMaxHeight
// 		? "100%;"
// 		: `calc(100vh - ${DrawerConstants.DrawerContentFooterHeightPx});`};
export const DrawerContentTitle = styled(Typography.Heading3Heavy) `
	color: ${ColorBasic.Aqua20};
	margin-bottom: 32px;
`;
export const DrawerContent = ({ title, drawerHeader, drawerFooter, children, useMaxHeight, height, isCenterItems, className, }) => {
    // Do not use bottom padding if there is a `drawerFooter`
    // This is because otherwise the bottom padding when the
    // drawer is scrolled down does not exist which leads
    // to inconsistent bottom padding for the `drawerFooter`.
    const isUseBottomPadding = !drawerFooter;
    return (_jsxs(DrawerContentContainer, Object.assign({ "$useMaxHeight": useMaxHeight, "$height": height, "$isCenterItems": isCenterItems, className: className, "$isUseBottomPadding": isUseBottomPadding }, { children: [title && _jsx(DrawerContentTitle, { children: title }, void 0), drawerHeader, children, drawerFooter] }), void 0));
};
export const DrawerFormDivider = styled.hr `
	border: 0;
	border-top: 1px ${ColorBasic.Aqua70} dashed;
`;
export default DrawerContent;
