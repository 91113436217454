import { jsx as _jsx } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { InvoiceRow } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRow";
import styled from "styled-components";
const RootContent = styled.div `
	& {
		${Typography.SmallCapsMediumStyle};
		color: ${ColorBasic.Dark60};
	}
`;
export const InvoiceRowHeader = (p) => {
    return (_jsx(InvoiceRow
    //
    , { 
        //
        left: _jsx(RootContent, { children: "Item" }, void 0), right: _jsx(RootContent, { children: "Price" }, void 0) }, void 0));
};
