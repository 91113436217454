import { ColorBasic } from "@/src/enums/colors";
import { css } from "styled-components";
/** @deprecated TODO: reidenzon - Move to {@link StyleCommon} perhaps?! */
export const FieldStyle = {
    Rest: css `
		& {
			background: transparent;
			border: 1px solid ${ColorBasic.Dark50};
			box-sizing: border-box;
			color: ${ColorBasic.Dark};
			font: inherit;
			outline: none;
		}

		&[type="email"],
		&[type="number"],
		&[type="password"],
		&[type="text"],
		&[type="time"] {
			height: 1.4em;
		}
	`,
    Disabled: css `
		& {
			border-color: transparent;
			// border-color: ${ColorBasic.Dark20};
			padding: 0;
		}
	`,
    Active: css `
		& {
			//background: ${ColorBasic.Corn90};
			border: 1px solid ${ColorBasic.Aqua50};
			color: ${ColorBasic.Dark};
			outline: none;
		}
	`,
};
