import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorBasic } from "@/src/enums/colors";
import { Icons, Typography } from "@/src/features/common";
import { ListCTAItemLabel } from "@/src/features/common/ListCTA/ListCTAItemLabel";
import styled from "styled-components";
/* ------------------------- Variables and Constants ------------------------ */
// This is the affect on button when it is hovered.
const containerHoverOpacity = 0.65;
const DEFAULT_ICON_COLOR = ColorBasic.Aqua50;
export const DEFAULT_ICON_LEFT_COLOR = DEFAULT_ICON_COLOR;
export const DEFAULT_ICON_RIGHT_COLOR = DEFAULT_ICON_COLOR;
export const DEFAULT_ICON_LEFT_SIZE = 24;
export const DEFAULT_ICON_RIGHT_SIZE = 16;
const DEFAULT_TEXT_COLOR = ColorBasic.Night50;
const DEFAULT_TEXT_COLOR_FOCUSED = ColorBasic.Aqua50;
// Currently, we have not implemented disabled, therefore we can code it when required
// Since the designs may change.
// const DEFAULT_ICON_COLOR = ColorsBase.Dark40;
// const DEFAULT_TEXT_COLOR = ColorsBase.Dark40;
/* --------------------------------- Styles --------------------------------- */
const ListCTAItemLeftIcon = styled.span `
	margin-right: 12px;
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
`;
const ListCTAItemText = styled.span `
	${Typography.ParagraphBaseMediumStyle}
	color: ${DEFAULT_TEXT_COLOR};
`;
const ListCTAItemRightContainer = styled.span `
	display: flex;
	flex-direction: row;
	margin-left: auto;
`;
const ListCTAItemRightIcon = styled.span `
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 12px;
`;
const ListCTAItemContentContainer = styled.span `
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
`;
const Root = styled.button `
	display: flex;
	align-items: center;
	height: 56px;
	width: 100%;
	padding: 12px 0;
	border-bottom: 1px solid ${ColorBasic.Dark30};

	&:hover ${ListCTAItemContentContainer} {
		opacity: ${containerHoverOpacity};
	}

	&:focus ${ListCTAItemText} {
		color: ${DEFAULT_TEXT_COLOR_FOCUSED} !important;
	}
`;
export const ListCTAItem = (p) => {
    const rightIcon = p.rightIcon || _jsx(Icons.ChevronRight, { color: DEFAULT_ICON_COLOR, size: DEFAULT_ICON_RIGHT_SIZE }, void 0);
    if (!p.isShow) {
        return null;
    }
    // label
    const { text: labelText, labelColorType, isLoading: isLoadingLabel } = p.label || {};
    return (_jsx(Root, Object.assign({ type: "button", onClick: p.onClick }, { children: _jsxs(ListCTAItemContentContainer, { children: [_jsx(ListCTAItemLeftIcon, Object.assign({ className: "ListCTAItemLeftIcon" }, { children: p.leftIcon }), void 0), p.text && _jsx(ListCTAItemText, { children: p.text }, void 0), _jsxs(ListCTAItemRightContainer, { children: [_jsx(ListCTAItemLabel, { text: labelText, labelColorType: labelColorType, isLoading: isLoadingLabel }, void 0), _jsx(ListCTAItemRightIcon, { children: rightIcon }, void 0)] }, void 0)] }, void 0) }), void 0));
};
