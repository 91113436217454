var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridRow } from "@/src/common/components/grid/GridRow";
import { Spacer } from "@/src/common/components/layout/Spacer";
import { IOSSwitch } from "@/src/common/components/switch/Switch";
import { getDateStringAsLocaleDate } from "@/src/common/utility/time/DateString";
import { ColorBasic } from "@/src/enums/colors";
import { useRxReminderEditMutation } from "@/src/modules/rx-reminder/ApiCoreRxReminder";
import styled from "styled-components";
const Root = styled.div `
	& {
		//border: 1px solid ${ColorBasic.Aqua50};
		border: 1px solid ${ColorBasic.Night10};
		border-radius: 4px;
		font-size: 14px;
		padding: 4px;
	}
`;
const RootDrug = styled.div `
	& {
		color: ${ColorBasic.Aqua60};
		font-weight: 600;
	}
`;
const RootText = styled.div `
	& {
		color: ${ColorBasic.Dark60};
	}
`;
export const RefillReminder = (p) => {
    var _a;
    const reminder = p.item.reminder;
    const rx = p.item.rx;
    const isRemind = reminder === null || reminder === void 0 ? void 0 : reminder.details.isRemind;
    const [apiEdit, apiEditState] = useRxReminderEditMutation();
    const handleIsRemind = (e) => __awaiter(void 0, void 0, void 0, function* () {
        const value = e.target.checked;
        yield apiEdit({
            guid: reminder.guid,
            details: {
                isRemind: value,
            },
        }).unwrap();
        p.onEdit();
    });
    return (_jsxs(Root, { children: [_jsxs("div", { children: [_jsx("div", { children: `Rx: ${rx === null || rx === void 0 ? void 0 : rx.rxNumber}` }, void 0), _jsx(RootDrug, { children: `${rx === null || rx === void 0 ? void 0 : rx.namePrimary}, ${rx === null || rx === void 0 ? void 0 : rx.nameSecondary}` }, void 0)] }, void 0), _jsx(RootText, { children: `Depletion date: ${(_a = getDateStringAsLocaleDate(reminder.timeRefill)) !== null && _a !== void 0 ? _a : "--"}` }, void 0), _jsxs(GridRow, { children: [_jsx(RootText, { children: "Remind me" }, void 0), _jsx(Spacer, {}, void 0), _jsx(IOSSwitch
                    //
                    , { 
                        //
                        checked: isRemind, onChange: handleIsRemind }, void 0)] }, void 0)] }, void 0));
};
