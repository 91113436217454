import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { setCredentials } from "@/src/redux/slices/authSlice";
import { t } from "@/src/features/Localization";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { routes } from "@/src/routes";
import { Form } from "@/src/features/common";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import { FormId } from "@/src/enums/forms";
import { ColorBasic } from "@/src/enums/colors";
import { UserPasswordYup } from "@/src/common/utility/password/UserPassword";
import { useHyperlinkResolveSingleMutation } from "@/src/redux/apiServices/suiteApi";
import { HyperlinkResolution } from "@/src/enums/hyperlink";
import { PasswordStrengthIndicator } from "@/src/features/common/PasswordStrengthIndicator";
const NewPasswordContainer = styled.div `
	width: 500px;
	max-width: 100%;
	text-align: center;
`;
const NewPasswordButton = styled.button `
	background: ${ColorBasic.Aqua60};
	color: ${ColorBasic.Blanc00};
	position: relative;

	display: block;

	padding: 12px;
	border: 2px solid #5a7887;
	border-radius: 32px;
	overflow: hidden;

	width: 244px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 24px;
`;
const NewPasswordButtonText = styled.div `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	letter-spacing: 1%;
`;
export const NewPassword = () => {
    /* ---------------------------------- Hooks --------------------------------- */
    const { snackbar } = useCustomSnackbar();
    const history = useHistory();
    const dispatch = useDispatch();
    const [hyperlinkResolve, { isLoading, isSuccess, data: changePasswordResponseData }] = useHyperlinkResolveSingleMutation();
    const { query } = useQuery();
    /* -------------------------------- Variables ------------------------------- */
    const hyperlinkToken = (query === null || query === void 0 ? void 0 : query.get("token")) || "";
    /* ---------------------------- Helper Functions ---------------------------- */
    /**
     * Returns true if there are errors within the form
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function hasFormikFormErrors(formik) {
        return Object.keys(formik === null || formik === void 0 ? void 0 : formik.errors).length !== 0;
    }
    /* ----------------------------- Action Handlers ----------------------------- */
    function handleSubmit({ userUpdatedPassword }) {
        hyperlinkResolve({
            password: userUpdatedPassword,
            resolution: HyperlinkResolution.Submit,
            token: hyperlinkToken,
        });
    }
    /* ------------------------------- useEffects ------------------------------- */
    useEffect(() => {
        var _a, _b;
        if (isSuccess) {
            snackbar.success("Your password has been updated.");
            const authToken = (_a = changePasswordResponseData === null || changePasswordResponseData === void 0 ? void 0 : changePasswordResponseData.session) === null || _a === void 0 ? void 0 : _a.token;
            const authTokenStream = (_b = changePasswordResponseData === null || changePasswordResponseData === void 0 ? void 0 : changePasswordResponseData.session) === null || _b === void 0 ? void 0 : _b.tokenStream;
            dispatch(setCredentials({
                token: authToken,
                tokenStream: authTokenStream,
            }));
            history.push(routes.Home.toPath());
        }
    }, [isSuccess, changePasswordResponseData]);
    /* ------------------------------ Formik Setup ----------------------------- */
    const ResetPasswordValidationSchema = Yup.object().shape({
        userUpdatedPassword: UserPasswordYup(),
    });
    const formikInitialValues = {
        userUpdatedPassword: "",
    };
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: ResetPasswordValidationSchema,
        onSubmit: handleSubmit,
    };
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(NewPasswordContainer, { children: _jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.ResetPassword }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "userUpdatedPassword", type: FormikTextFieldTypes.Password, label: t("changePassword_inputLabel_newPassword") }, void 0), _jsx(PasswordStrengthIndicator, { currentPassword: formik.values.userUpdatedPassword }, void 0), _jsx(NewPasswordButton, Object.assign({ disabled: isLoading || hasFormikFormErrors(formik), type: "submit" }, { children: _jsx(NewPasswordButtonText, { children: t("common_button_confirm") }, void 0) }), void 0)] }), void 0)) }), void 0) }, void 0));
};
