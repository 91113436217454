import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * This is a strictly interval dev and qa route for
 * quickly logging in without needing to use login SSO.
 */
import { ColorBasic } from "@/src/enums/colors";
import { FormId } from "@/src/enums/forms";
import { Button, Form, Icons, Typography } from "@/src/features/common";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { getDeeplinkFullUrl, redirectAfterLogin } from "@/src/features/DeepLink/DeepLinkHelpers";
import { t } from "@/src/features/Localization";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { FormikAutoSubmit } from "@/src/features/utils/Formik/FormikAutoSubmit";
import FormikErrorFocus from "@/src/features/utils/Formik/FormikErrorFocus";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import { PermoAction } from "@/src/modules/permo/PermoAction";
import { useUserLoginBasicMutation } from "@/src/modules/user/redux/ApiCoreUser";
import { setCredentials } from "@/src/redux/slices/authSlice";
import { routes } from "@/src/routes";
import { Auth } from "@/src/utils/Auth";
import { googleSignOut } from "@/src/utils/googleServices/googleAuth";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { SegmentTrackLoginMethod, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { Formik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
/* --------------------------------- Styles --------------------------------- */
const LoginContainer = styled.div `
	width: 500px;
	max-width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
`;
const LoginButton = styled(Button) `
	background: ${ColorBasic.Aqua60};
	color: ${ColorBasic.Blanc00};
	position: relative;

	display: block;

	border-radius: 32px;
	overflow: hidden;
	padding: 12px 85px !important;

	margin-left: auto;
	margin-right: auto;
`;
const AlternativeLoginContainer = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;
const ForgotPasswordContainer = styled.div `
	margin: 16px 6px;
`;
const ForgotPasswordLinkStyled = styled(Link) `
	color: ${ColorBasic.Aqua60};
	${Typography.ParagraphBaseMediumStyle}
	text-decoration: none;
`;
const FormikCheckboxContainer = styled.div `
	display: flex;
	align-items: center;
	margin: 16px 6px;
`;
const FormikCheckboxText = styled(Typography.ParagraphBaseMedium) `
	margin-left: 8px;
	color: ${ColorBasic.Night40};
`;
const LoginButtonContentContainer = styled.div `
	display: flex;
	justify-content: center;
	gap: 8px;
`;
const LoginButtonText = styled.div `
	font-family: Cabin;
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	//letter-spacing: 1%;
`;
const LoginIconContainer = styled.div `
	display: flex;
	align-items: flex-end;
`;
export const LoginForm = () => {
    var _a;
    const { snackbar } = useCustomSnackbar();
    const { query } = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const { segmentTrackEvent } = useSegmentAnalytics();
    // NOTE: Error handling for submit is handled by
    // `src/redux/middleware/rtkQueryErrorLoggerMiddleware.ts`
    // Since it will return a passive message.
    const [apiLogin, { error, isSuccess, isLoading: isSubmittingLogin, data: loginResponseData }] = useUserLoginBasicMutation();
    function handleLogin(s) {
        if (s.isRemember) {
            Auth.setRememberMe(true);
            Auth.setRememberMeEmail(s.email);
        }
        else {
            Auth.clearRememberMe();
            Auth.clearRememberMeEmail();
        }
        apiLogin({
            email: s.email,
            password: s.password,
            deeplink: getDeeplinkFullUrl(query),
        });
    }
    /* ------------------------- Data / Variable helpers ------------------------ */
    const isDisableSubmit = isSubmittingLogin;
    const hasSubmittedLogin = error || isSuccess;
    /* ----------------------------- Url Query Data ----------------------------- */
    // These values are used to capture values from the url.
    // E.g. "/app/login?email=test@mednow.ca&password=test123&isAutoSubmit=1"
    const defaultEmail = query.get("email") || "";
    const defaultPassword = query.get("password") || "";
    const isAutoSubmit = (_a = Boolean(query.get("autosubmit"))) !== null && _a !== void 0 ? _a : false;
    /* ------------------------------ Formik Setup ----------------------------- */
    const LoginValidationSchema = Yup.object().shape({
        email: Yup.string().email(t("common_emailInput_error_invalidFormat")).required(t("common_emailInput_error_invalidFormat")),
        password: Yup.string().typeError(t("common_passwordInput_error_invalidFormat")).required(t("common_passwordInput_error_invalidFormat")),
    });
    const formikInitialValues = {
        email: defaultEmail || Auth.getRememberMeEmail() || "",
        password: defaultPassword || "",
        isRemember: Boolean(Auth.getRememberMe()) || false,
    };
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: LoginValidationSchema,
        onSubmit: (s) => {
            handleLogin(s);
        },
    };
    useEffect(() => {
        var _a, _b, _c;
        if (isSuccess) {
            const user = (_a = loginResponseData === null || loginResponseData === void 0 ? void 0 : loginResponseData.data) === null || _a === void 0 ? void 0 : _a.user;
            const userStatus = user === null || user === void 0 ? void 0 : user.status;
            const actions = (user === null || user === void 0 ? void 0 : user.actions) || [];
            const token = (_b = loginResponseData === null || loginResponseData === void 0 ? void 0 : loginResponseData.session) === null || _b === void 0 ? void 0 : _b.token;
            const tokenStream = (_c = loginResponseData === null || loginResponseData === void 0 ? void 0 : loginResponseData.session) === null || _c === void 0 ? void 0 : _c.tokenStream;
            // When the user status is "invited" we want to allow them to login so
            // the backend can send them a hyperlink. In the mean while,
            // we redirect them to a page letting them know they'll be able to
            // "activate" when the hyperlink arrives
            if (userStatus === "invited") {
                history.push(routes.LoginUserActivate.toPath({
                    email: user === null || user === void 0 ? void 0 : user.email,
                }));
            }
            else if (
            // Ensure the user should have the action permission to login in.
            // and if so set the token credentials.
            token &&
                actions.includes(PermoAction.AppWebCustomerUserOwnLogin)) {
                // Send segment track event for login basic
                segmentTrackEvent("login", {
                    guid: user === null || user === void 0 ? void 0 : user.guid,
                    email: user === null || user === void 0 ? void 0 : user.email,
                    method: SegmentTrackLoginMethod.Basic,
                });
                dispatch(setCredentials({ token, tokenStream }));
                redirectAfterLogin(history, query);
            }
            else {
                // Ensure user is not still signed into google with their account.
                googleSignOut();
                // Display warning message.
                snackbar.error("Your account does not have permission to log in. Please contact customer support.");
            }
        }
    }, [isSuccess, loginResponseData]);
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(LoginContainer, { children: _jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.Login }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "email", label: t("login_inputLabel_email"), inputProps: {
                            autoComplete: "username",
                        } }, void 0), _jsx(FormikTextField
                    // Allow user to submit the form on pressing "Enter"
                    , { 
                        // Allow user to submit the form on pressing "Enter"
                        isSubmitOnKeyPressEnter: true, fullWidth: true, autoComplete: "current-password", id: "current-password", formikId: "password", type: FormikTextFieldTypes.Password, label: t("login_inputLabel_password"), inputProps: {
                            autoComplete: "current-password",
                        } }, void 0), _jsxs(AlternativeLoginContainer, { children: [_jsxs(FormikCheckboxContainer, { children: [_jsx(FormikCheckbox, { formikId: "isRemember" }, void 0), _jsx(FormikCheckboxText, { children: t("login_description_rememberMe") }, void 0)] }, void 0), _jsx(ForgotPasswordContainer, { children: _jsx(ForgotPasswordLinkStyled, Object.assign({ to: routes.ForgotPassword.toPath() }, { children: t("login_button_forgotPassword") }), void 0) }, void 0)] }, void 0), _jsx(LoginButton, Object.assign({ "data-cy": "log-in-button", type: "submit", disabled: isDisableSubmit }, { children: _jsxs(LoginButtonContentContainer, { children: [_jsx(LoginButtonText, { children: t("common_button_logIn") }, void 0), _jsx(LoginIconContainer, { children: _jsx(Icons.ArrowForward, { color: ColorBasic.Blanc00 }, void 0) }, void 0)] }, void 0) }), void 0), _jsx(FormikAutoSubmit, { isAutoSubmit: isAutoSubmit, isSubmitting: isSubmittingLogin, hasSubmitted: hasSubmittedLogin }, void 0), _jsx(FormikErrorFocus, {}, void 0)] }), void 0)) }), void 0) }, void 0));
};
