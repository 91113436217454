import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { FeatureCardCTA } from "@/src/features/FeatureCards/FeatureCardCTAs/FeatureCardCTA";
import { ColorBasic } from "@/src/enums/colors";
import { Typography } from "@/src/features/common";
import { Elevations } from "@/src/enums/elevations";
import { LoaderSpinnerCentered } from "@/src/features/common/LoaderSpinnerCentered";
const FEATURE_CARD_MIN_HEIGHT_PX = "176px";
const FeatureCardContainer = styled.div `
	display: flex;
	flex-direction: column;
	background-color: ${ColorBasic.Blanc00};
	border-radius: 4px;
	padding: 24px;
	min-height: ${FEATURE_CARD_MIN_HEIGHT_PX};
	box-sizing: border-box;
	box-shadow: ${Elevations.FeatureCard};
`;
const FeatureCardTitle = styled(Typography.Heading6SemiBold) `
	color: ${ColorBasic.Night50};
`;
const FeatureCardBody = styled.div `
	${Typography.ParagraphSmallRegularStyle}
	color: ${ColorBasic.Dark60};

	* + & {
		margin-top: 8px;
	}
`;
const FeatureCardCTAsContainer = styled.div `
	display: flex;
	gap: 32px;

	* + & {
		padding-top: 24px;
		margin-top: auto;
	}
`;
// NOTE: This isn't used yet, but was part of the original
// possible Feature Cards (i.e. having Apple and Android store links
// to download app).
const FeatureCardFooter = styled.div ``;
export const FeatureCard = ({ children, ctas = [], footer, isLoading, title }) => {
    const ctaButtons = ctas.map((cta, i) => {
        return _jsx(FeatureCardCTA, Object.assign({}, cta), `FeatureCardCTA-${i}`);
    });
    const hasCTAs = (ctas === null || ctas === void 0 ? void 0 : ctas.length) > 0;
    if (isLoading) {
        return (_jsx(FeatureCardContainer, { children: _jsx(LoaderSpinnerCentered, {}, void 0) }, void 0));
    }
    return (_jsxs(FeatureCardContainer, { children: [title && _jsx(FeatureCardTitle, { children: title }, void 0), children && _jsx(FeatureCardBody, { children: children }, void 0), hasCTAs && _jsx(FeatureCardCTAsContainer, { children: ctaButtons }, void 0), footer && _jsx(FeatureCardFooter, { children: footer }, void 0)] }, void 0));
};
