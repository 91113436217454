import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
const BoxStyled = styled(Box) `
	position: absolute;
	width: 327px;
	height: 240px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${ColorBasic.Light};
	border-radius: 7px;
`;
const LoadingSpinnerContainer = styled.div `
	width: 100%;
	text-align: center;
`;
const Title = styled(Typography.Heading6Bold) `
	color: ${ColorBasic.Night50};
	text-align: center;
`;
const TitleContainer = styled.div `
	margin-top: 54px;
	margin-left: 43px;
	margin-right: 43px;
	margin-bottom: 24px;
`;
export const LoaderSpinnerModal = (p) => {
    return (_jsx(Modal, Object.assign({ open: p.isOpen, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsxs(BoxStyled, { children: [_jsx(TitleContainer, { children: _jsx(Title, { children: p.title }, void 0) }, void 0), _jsx(LoadingSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0)] }, void 0) }), void 0));
};
