import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Typography } from "@/src/features/common";
import { ColorBasic } from "@/src/enums/colors";
const Root = styled.div `
	& {
		display: flex;
	}
`;
const RootText = styled(Typography.ParagraphXSmallHeavy) `
	color: ${ColorBasic.Night50};
`;
export const Badge = (p) => {
    return (_jsx(Root, { children: _jsx(RootText, { children: p.children }, void 0) }, void 0));
};
