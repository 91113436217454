import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Nav } from "@/src/features/Nav/Nav";
import { Typography } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { routes } from "@/src/routes";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { NewPassword } from "@/src/features/RegisterOrPasswordOrActivation/NewPassword";
const Title = styled(Typography.Heading3Regular) `
	margin-bottom: 8px;
`;
const TextContainer = styled.div `
	width: 225px;
	margin-bottom: 52px;
`;
const NewPasswordPageContainer = styled.div `
	margin-top: 65px;
`;
export const NewPasswordPage = () => {
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Login",
                    linkTo: routes.Login.toPath(),
                } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(NewPasswordPageContainer, { children: [_jsx(TextContainer, { children: _jsx(Title, { children: t("changePassword_header") }, void 0) }, void 0), _jsx(NewPassword, {}, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
